import axios from 'axios'

export async function getTokens(login, password) {
    const session_url = process.env.VUE_APP_API_URL + 'auth/admin_auth'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            username: login,
            password: password
        }
    }

    const response = await axios(config)

    if (response.status == 200) {
        sessionStorage.setItem('access_token', response.data.access_token)
        sessionStorage.setItem('refresh_token', response.data.refresh_token)
        return true
    } else {
        return false
    }
}

async function rewriteTokensRequest() {
    const session_url = process.env.VUE_APP_API_URL + 'auth/admin_refresh'
    
    const config = {
        method: 'post',
        url: session_url,
        headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('refresh_token') }
    }

    let response = {}
    try {
        response = await axios(config)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('refresh_token')
        window.location.reload();
    } else if (response.status == 200) {
        sessionStorage.setItem('access_token', response.data.access_token)
        sessionStorage.setItem('refresh_token', response.data.refresh_token)
    }
}

async function sendApiRequest(axiosConfig) {
    axiosConfig.headers = {
        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token') 
    }
    let response = {}
    try {
        response = await axios(axiosConfig)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        await rewriteTokensRequest()
        response = await axios(axiosConfig)
        return response
    } else if (response.status == 200) {
        return response
    }
}

async function sendApiRequestReturnData(axiosConfig) {
    const response = await sendApiRequest(axiosConfig)
    return response.data
}

function formatDate(date) {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

export const getAllLocations = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'location/get_all_locations'

    const config = {
        method: 'get',
        url: session_url,
    }

    return await sendApiRequest(config)
}

export const getAllGroups = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_all_groups'

    const config = {
      method: 'get',
      url: session_url,
    }
    
    return await sendApiRequest(config)
}

export const addNewTraining = async (newTraining) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_new_training'

    // newTraining.date = newTraining.date.toISOString()
    
    const formData = new FormData()
    formData.append('training', JSON.stringify(newTraining))

    const config = {
        method: 'post',
        url: session_url,
        data: newTraining
    }
    
    return await sendApiRequest(config)
}

export const getAllTrainings = async (startDate = null, endDate = null) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_all_trainings'

    console.log('startDate ------- ', startDate)
    console.log('endDate ------- ', endDate)

    const config = {
        method: 'get',
        url: session_url,
        params: {
            start_date: startDate,
            end_date: endDate
        }
    }
    return await sendApiRequest(config)
}

export const updateTraining = async (updatedTraining) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_training_by_id'
    
    const formData = new FormData()
    formData.append('training', JSON.stringify(updatedTraining))

    const config = {
        method: 'put',
        url: session_url,
        data: updatedTraining,
        params: {
            training_id: updatedTraining.training_id
        }
    }
    
    return await sendApiRequest(config)
}

export const getLocationById = async (locationId) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/get_location_by_id'
    const config = {
        method: 'get',
        url: session_url + '?location_id=' + locationId,
        params: {
            location_id: locationId
        }
    }
    return await sendApiRequest(config)
}

export const getGroupById = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_group_by_id'
    const config = {
        method: 'get',
        url: session_url + '?group_id=' + groupId,
        params: {
            group_id: groupId
        }
    }
    return await sendApiRequest(config)
}

export const deleteTrainingById = async (trainingId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/delete_training_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_id: trainingId
        }
    }

    return await sendApiRequest(config)
}

export const getAllTrainers = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_all_trainers'
    const config = {
      method: 'get',
      url: session_url
    }
    return await sendApiRequest(config)
}

export const addNewGroup = async (groupName, groupYearRange, trainerId, paymentStatus) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/add_new_group'
    const config = {
        method: 'post',
        url: session_url,
        data: {
            name: groupName,
            year_range: groupYearRange,
            trainer_id: trainerId,
            payment_status: paymentStatus
        }
    }
    return await sendApiRequest(config)
}

export const getTrainerById = async (trainerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_by_id'
    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainerId
        }
    }
    return await sendApiRequest(config)
}

export const getAllStudents = async (count = 100, skip = 0, balance_sort = null, query = null, startAgeRange = null, endAgeRange = null, status = ['active']) => {
    console.log('status -------- ', status)
    const session_url = process.env.VUE_APP_API_URL + 'student/get_all_students'
    const config = {
      method: 'post',
      url: session_url,

      data: {
            count: count,
            skip: skip,
            balance_sort: balance_sort,
            query: query,
            age_from: startAgeRange,
            age_to: endAgeRange,
            status: status
      }
    }
    return await sendApiRequest(config)
}

export const addGroupToStudents = async (studentsIds, groupId, groupName) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/add_list_of_students_to_group'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            group_id: groupId,
            group_name: groupName
        },
        data: studentsIds
    }

    return await sendApiRequest(config)
}

export const getStudentsCountByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_number_of_students_by_group_id'
    
    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const updateGroupRequest = async (groupId, groupName, groupYearRange, trainerId, paymentStatus, currentAssistants) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/update_group'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            group_id: groupId,
        },
        data: {
            name: groupName,
            year_range: groupYearRange,
            trainer_id: trainerId,
            payment_status: paymentStatus,
            assistants: currentAssistants
        }
    }

    return await sendApiRequest(config)
}

export const deleteGroupRequest = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/delete_group'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            group_id: groupId,
        }
    }

    return await sendApiRequest(config)
}


export const addNewLocation = async (locationName, smallLocationName, locationAddress, locationDescription, geoUrl, locationPhoto) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/add_new_location'

    const formData = new FormData()
    formData.append('location', JSON.stringify({
        name: locationName,
        address: locationAddress,
        description: locationDescription,
        small_name: smallLocationName,
        geo_url: geoUrl
    }))
    formData.append('location_picture', locationPhoto)

    const config = {
        method: 'post',
        url: session_url,
        data: formData,
    }

    return await sendApiRequest(config)
}

export const updateLocation = async (locationId, locationName, smallLocationName, locationAddress, locationDescription, geoUrl, locationPhoto) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/update_location_by_id'

    const formData = new FormData()
    formData.append('location', JSON.stringify({
        name: locationName,
        address: locationAddress,
        description: locationDescription,
        small_name: smallLocationName,
        geo_url: geoUrl
    }))
    formData.append('location_picture', locationPhoto)

    const config = {
        method: 'put',
        url: session_url + '?location_id=' + locationId,
        data: formData
    }
    return await sendApiRequest(config)
}

export const deleteCurrentLocation = async (locationId) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/delete_location_by_id'

    const config = {
        method: 'delete',
        url: session_url + '?location_id=' + locationId
    }

    return await sendApiRequest(config)
}

export const getAdmin = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'admin/get_admin'
    const config = {
      method: 'get',
      url: session_url
    }
    return await sendApiRequest(config)
}

function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
  
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
}
  
export const addNewStudent = async (new_student_obj, photo) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/add_new_student'
    new_student_obj.birthday = new_student_obj.birthday.length == 0 ? new Date().toISOString() : new Date(new_student_obj.birthday).toISOString()
    new_student_obj.start_training = new_student_obj.start_training.length == 0 ? new Date().toISOString() : new Date(new_student_obj.start_training).toISOString()
    new_student_obj.passport_end_date = new_student_obj.passport_end_date.length == 0 ? new Date().toISOString() : new Date(new_student_obj.passport_end_date).toISOString()

    const formData = new FormData()
    
    if (photo !== null) {
        formData.append('student_picture', dataURItoBlob(photo))
    } else {
        formData.append('student_picture', null)
    }

    new_student_obj.id_photos?.forEach((id_photo, index) => {
        formData.append(`id_photos`, id_photo)
    })

    new_student_obj.passport_photos?.forEach((passport_photo, index) => {
        formData.append(`passport_photos`, passport_photo)
    })

    new_student_obj.attorney_files?.forEach((attorney_file, index) => {
        formData.append(`attorney_files`, attorney_file)
    })

    new_student_obj.photo_for_passport?.forEach((photo_for_passport_file, index) => {
        formData.append(`photo_for_passport`, photo_for_passport_file)
    })

    delete new_student_obj.id_photos
    delete new_student_obj.passport_photos
    delete new_student_obj.attorney_files
    delete new_student_obj.photo_for_passport
    
    formData.append('new_student', JSON.stringify(new_student_obj))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }
    return await sendApiRequest(config)
}

export const deleteStudent = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/delete_student'
    const config = {
        method: 'delete',
        url: session_url,
        params: {
            student_id: student_id
        }
    }
    return await sendApiRequest(config)
}

export const updateStudent = async (student_obj, photo, idPhotos, passportPhotos, attorneyFiles, photoForPassport) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student'

    student_obj.birthday = new Date(student_obj.birthday).toISOString()
    student_obj.start_training = new Date(student_obj.start_training).toISOString()
    student_obj.passport_end_date = new Date(student_obj.passport_end_date).toISOString()

    const formData = new FormData()
    if (photo !== null && photo.startsWith('data')) {
        formData.append('student_picture', dataURItoBlob(photo))
    } else {
        formData.append('student_picture', null)
    }

    idPhotos.forEach((id_photo, index) => {
        formData.append(`id_photos`, id_photo)
    })

    passportPhotos.forEach((passport_photo, index) => {
        formData.append(`passport_photos`, passport_photo)
    })

    attorneyFiles.forEach((attorney_file, index) => {
        formData.append(`attorney_files`, attorney_file)
    })

    photoForPassport.forEach((photo_for_passport_file, index) => {
        formData.append(`photo_for_passport`, photo_for_passport_file)
    })

    formData.append('updated_student', JSON.stringify(student_obj))

    const config = {
        method: 'put',
        url: session_url,
        data: formData,
        params: {
            student_id: student_obj.id
        }
    }

    return await sendApiRequest(config)
}

export const getStudentById = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_student_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequest(config)
}

export const sendPhotoToRemoveBg = async (blob) => {
    const formData = new FormData();
    formData.append('file', blob);
    
    const response = await axios(
        {
            url: 'https://api.carpediem.pp.ua/subservice/removebg',
            method: 'POST',
            responseType: 'blob', 
            data: formData,
        }
    )

    return new Blob([response.data])
}

export const getBalanceState = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_balance_state',
        params: {
            balance_id: balance_id
        }
    }
    const response = await sendApiRequestReturnData(config)
    return response.balance_state
}

export const getAllIncomes = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_incomes_by_balance_id',
        params: {
            balance_id: balance_id,
            year: new Date().getFullYear()
        }
    }
    return await sendApiRequestReturnData(config)
}

export const getAllOutlays = async (balance_id, year) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_outlay_by_balance_id',
        params: {
            balance_id: balance_id,
            year: year
        }
    }
    const response = await sendApiRequestReturnData(config)
    return response
}

export const deleteTrainerRequest = async (trainer_id) => {
  const session_url = process.env.VUE_APP_API_URL + 'trainer/delete_trainer_by_id'

  const config = {
      method: 'delete',
      url: session_url,
      params: {
          trainer_id: trainer_id
      }
  }

  return await sendApiRequest(config)
}

export const addNewTrainer = async (new_trainer_obj, photo) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/add_new_trainer'

    new_trainer_obj.birthday = isNaN(new Date(new_trainer_obj.birthday)) ? null : new Date(new_trainer_obj.birthday).toISOString() 
    new_trainer_obj.club_start_date = isNaN(new Date(new_trainer_obj.club_start_date)) ? null : new Date(new_trainer_obj.club_start_date).toISOString() 
    new_trainer_obj.passport_end_date = isNaN(new Date(new_trainer_obj.passport_end_date)) ? null : new Date(new_trainer_obj.passport_end_date).toISOString()
    new_trainer_obj.issuing_date = isNaN(new Date(new_trainer_obj.issuing_date)) ? null : new Date(new_trainer_obj.issuing_date).toISOString()

    const formData = new FormData()
    
    if (photo !== null) {
        formData.append('trainer_picture', dataURItoBlob(photo))
    } else {
        formData.append('trainer_picture', null)
    }

    new_trainer_obj.id_photos.forEach((id_photo, index) => {
        formData.append('id_photos', id_photo)
    })

    new_trainer_obj.passport_photos.forEach((passport_photo, index) => {
        formData.append('passport_photos', passport_photo)
    })

    new_trainer_obj.ukrainian_passport_files.forEach((ukrainian_passport, index) => {
        formData.append('ukrainian_passport_files', ukrainian_passport)
    })

    delete new_trainer_obj.id_photos
    delete new_trainer_obj.passport_photos
    delete new_trainer_obj.ukrainian_passport_files
    
    formData.append('new_trainer', JSON.stringify(new_trainer_obj))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }
    return await sendApiRequest(config)
}

export const updateTrainer = async (updated_trainer_obj, trainer_photo, passportPhotos, idCodePhotos, ukrainianPassportPhotos) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/update_trainer'

    updated_trainer_obj.birthday = new Date(updated_trainer_obj.birthday).toISOString()
    updated_trainer_obj.club_start_date = new Date(updated_trainer_obj.club_start_date).toISOString()
    updated_trainer_obj.passport_end_date = new Date(updated_trainer_obj.passport_end_date).toISOString()
    updated_trainer_obj.issuing_date = new Date(updated_trainer_obj.issuing_date).toISOString()

    const formData = new FormData()

    if (trainer_photo !== null && trainer_photo.startsWith('data:image')) {
        formData.append('trainer_picture', dataURItoBlob(trainer_photo))
    }

    idCodePhotos.forEach((id_photo, index) => {
        formData.append('id_photos', id_photo)
    })

    passportPhotos.forEach((passport_photo, index) => {
        formData.append('passport_photos', passport_photo)
    })

    ukrainianPassportPhotos.forEach((ukrainian_passport, index) => {
        formData.append('ukrainian_passport_files', ukrainian_passport)
    })

    formData.append('updated_trainer', JSON.stringify(updated_trainer_obj))


    delete updated_trainer_obj.id_photos
    delete updated_trainer_obj.passport_photos
    delete updated_trainer_obj.ukrainian_passport_files

    const config = {
        method: 'put',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getGroupsByTrainerId = async (trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_groups_by_trainer_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getActiveStudentsInGroup = async (group_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_active_students_in_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: group_id
        }
    }

    return await sendApiRequest(config)
}

export const getPlanedTrainingsByGroup = async (group_id) => {
    const currentDate = new Date()

    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'trainings/get_planed_trainings_by_group',
        params: {
            group_id: group_id,
            current_date: currentDate.toISOString()
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getServiceBalanceByLabel = async (label) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_service_balance_by_label',
        params: {
            service_label: label
        }
    }
    return await sendApiRequestReturnData(config)
}

export const getCertainNumberOfIncomes = async (number_of_transactions, skip = 0, filters = null) => {
    filters = filters === null ? {
        receiver_type: 'all',
        transaction_type: 'all',
        amount_from: null,
        amount_to: null,
        date_from: null,
        date_to: null,
        current_balance_id: null,
        current_transaction_id: null
    } : filters


    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_certain_number_of_incomes',
        params: {
            count: number_of_transactions,
            skip: skip,
            filters: JSON.stringify(filters)
        },
    }

    return await sendApiRequestReturnData(config)
}

export const getBalanceObject = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_balance_object_by_id',
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getVirtualIncomeById = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_virtual_income_by_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getUniversalTransactionById = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_universal_transaction_by_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getUniversalTransactionByBalanceId = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_universal_transactions_by_balance_id',
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getRealParentTransactionByVirtualTransactionId = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_real_parent_transaction_by_virtual_transaction_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllVirtualBalances = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_all_virtual_balances'
    }

    return await sendApiRequestReturnData(config)
}

export const addVirtualIncomeRequest = async (balanceId, amount, label) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_virtual_income'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: balanceId,
        amount: amount,
        label: label
    }
    formData.append('virtual_income', JSON.stringify(txConfig))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const addRealIncomeRequest = async (balanceId, amount, label, virtualTxId, images, incomeType = null, managerId = null, managerLabel = null) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_income'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: balanceId,
        amount: amount,
        label: label,
        embed_virtual_income: virtualTxId,
        income_type: incomeType,
        manager_id: managerId,
        manager_label: managerLabel
    }

    formData.append('real_income', JSON.stringify(txConfig))

    images.forEach((image) => {
        formData.append('additional_files', image)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getServiceBalanceObjectByLabel = async (label) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_service_balance_object_by_label',
        params: {
            service_label: label
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainerStudentBalanceState = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_all_trainers_student_balance_states'
    }

    return await sendApiRequestReturnData(config)
}

export const getCertainNumberOfOutlays = async (number_of_transactions, skip = 0, filters = null) => {
    filters = filters === null ? {
        sender_type: 'all',
        transaction_type: 'all',
        amount_from: null,
        amount_to: null,
        date_from: null,
        date_to: null,
        current_balance_id: null,
        current_transaction_id: null
    } : filters

    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_certain_number_of_outlays',
        params: {
            count: number_of_transactions,
            skip: skip,
            filters: JSON.stringify(filters)
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getIncomeTransactionById = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_income_transaction_by_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getIncomeTransactionByBalanceId = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_income_transactions_by_balance_id',
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getOutlayTransactionById = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_outlay_transaction_by_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getOutlayTransactionByBalanceId = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_outlay_transactions_by_balance_id',
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getSumOfAllVirtualOutlays = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_sum_of_all_virtual_outlays'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllVirtualOutlaysCount = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_outlays_count'
    }

    return await sendApiRequestReturnData(config)
}

export const getMeanRealIncomeFromStudent = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_mean_real_income_from_student'
    }

    return await sendApiRequestReturnData(config)
}

export const getYearIncomesBySources = async (year) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_sources_by_year',
        params: {
            year: year
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getIncomesBySourcesByMonth = async (year, month) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_sources_by_month_and_year',
        params: {
            year: year,
            month: month
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getIncomesByGroups = async (year, month) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_groups',
        params: {
            year: year,
            month: month
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllCompletedTrainingsByStudentId = async (student_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'trainings/get_students_completed_trainings',
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllStudentsListInGroup = async (group_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'groups/get_all_students_list_in_group',
        params: {
            group_id: group_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getStudentsCount = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'student/get_students_count'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTransactions = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_transactions'
    }

    return await sendApiRequestReturnData(config)
}

export const getBalanceByQuery = async (query) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_balance_by_query',
        params: {
            query: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addRealTransactionRequest = async (senderBalanceId, receiverBalanceId, amount, label, images) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_transaction'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: receiverBalanceId,
        sender_balance_id: senderBalanceId,
        amount: amount,
        label: label
    }
    console.log('txConfig ------- ', txConfig)
    formData.append('real_transaction', JSON.stringify(txConfig))

    images.forEach((image) => {
        formData.append('additional_files', image)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getAllAdmins = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'admin/get_all_admins'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllCashFromAdmins = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_all_cash_from_admins'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllInvestFromAdmins = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_all_invest_from_admins'
    }

    return await sendApiRequestReturnData(config)
}

export const getCountOfIncomes = async (filters) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_count_of_incomes',
        body: filters
    }

    return await sendApiRequestReturnData(config)
}

export const addOutlayCategory = async (name, id) => {
    console.log('name ------- ', name)

    const formData = new FormData()

    formData.append('name', name)

    const config = {
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'outlay_category/add_outlay_category',
        data: {
            name : name,
            id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addOutlaySubcategory = async (name, outlayCategoryId, id) => {
    const config = {
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'outlay_category/add_subcategory',
        data: {
            name: name,
            embed_category_id: outlayCategoryId,
            id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllOutlayCategoriesWithSubcategories = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'outlay_category/get_all_categories_with_subcategories'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllOutlayCategories = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'outlay_category/get_all_categories'
    }

    return await sendApiRequestReturnData(config)
}

export const getSubcategoriesByCategoryId = async (id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'outlay_category/get_subcategories_by_category_id',
        params: {
            category_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addRealOutlay = async (
        amount, 
        date, 
        label, 
        name, 
        senderBalanceId, 
        senderBalanceName, 
        categoryId, 
        categoryName, 
        subcategoryId, 
        subcategoryName, 
        creatorId, 
        creatorName, 
        images) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_outlay'

    const formData = new FormData()

    const txConfig = {
        amount: amount,
        date: date,
        label: label,
        name: name,
        sender_balance_id: senderBalanceId,
        sender_balance_name: senderBalanceName,
        category_id: categoryId,
        category_name: categoryName,
        subcategory_id: subcategoryId,
        subcategory_name: subcategoryName,
        creator_id: creatorId,
        creator_name: creatorName
    }

    formData.append('real_outlay', JSON.stringify(txConfig))

    images.forEach((image) => {
        formData.append('additional_files', image)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getRealOutlays = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_real_outlays',
    }

    return await sendApiRequestReturnData(config)
}

export const deleteCategoryById = async (id) => {
    const config = {
        method: 'delete',
        url: process.env.VUE_APP_API_URL + 'outlay_category/delete_category_by_id',
        params: {
            category_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteSubcategoryById = async (id) => {
    const config = {
        method: 'delete',
        url: process.env.VUE_APP_API_URL + 'outlay_category/delete_subcategory_by_id',
        params: {
            subcategory_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateCategoryById = async (id, name) => {
    const config = {
        method: 'put',
        url: process.env.VUE_APP_API_URL + 'outlay_category/update_category_by_id',
        params: {
            category_id: id,
            category_name: name
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateSubcategoryById = async (id, name) => {
    const config = {
        method: 'put',
        url: process.env.VUE_APP_API_URL + 'outlay_category/update_subcategory_by_id',
        params: {
            subcategory_id: id,
            subcategory_name: name
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getRealOutlaysByFilters = async (count, skip, filters) => {
    console.log('filters ------- ', filters)
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + `transaction/get_certain_number_of_club_outlays`,
        params: {
            count: count,
            skip: skip,
            filters: JSON.stringify(filters)
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getCountOfClubOutlays = async (filters) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_count_of_club_outlays',
        params: {
            filters: JSON.stringify(filters)
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteClubOutlay = async (id) => {
    const config = {
        method: 'delete',
        url: process.env.VUE_APP_API_URL + 'transaction/delete_club_outlay',
        params: {
            transaction_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateClubOutlay = async (tx_id, new_outlay, additional_files) => {
    const formData = new FormData()

    formData.append('real_outlay', JSON.stringify(new_outlay))

    additional_files.forEach((file) => {
        formData.append('additional_files', file)
    })

    const config = {
        method: 'put',
        url: process.env.VUE_APP_API_URL + 'transaction/update_club_outlay',
        params: {
            transaction_id: tx_id
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const getStatisticsClubOutlaysByYear = async (year) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'statistic/get_club_outlays_by_year',
        params: {
            year: year
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getGroupsAttendanceByYearAndMonth = async (year, month) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_groups_attendance_by_month_and_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            month: month
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainerCompletedTrainingsByYear = async (year, trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_trainer_completed_trainings_by_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getAllIncomesByGroupInYear = async (year) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_groups_by_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year
        }
    }

    return await sendApiRequest(config)
}

export const getActiveStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_active_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getDebtStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_debt_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getArchiveStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_archive_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const addStudentToArchive = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/add_student_to_archive'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const removeStudentFromArchive = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/remove_student_from_archive'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getRealIncomesByBalanceIdRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_all_real_incomes_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getTransactionsByBalanceIdRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_transactions_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getAllSettings = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'settings/get_settings'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const updateSettingsRequest = async (settings) => {
    const session_url = process.env.VUE_APP_API_URL + 'settings/update_settings'

    const config = {
        method: 'post',
        url: session_url,
        data: settings
    }

    return await sendApiRequestReturnData(config)
}

export const addTariffRoleRequest = async (tariffRole) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/add_new_tariff_role'

    const config = {
        method: 'post',
        url: session_url,
        data: tariffRole
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTariffRolesRequest = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_all_tariff_roles'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const updateTariffRoleRequest = async (tariffRole) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/update_tariff_role_by_id'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            tariff_role_id: tariffRole.id
        },
        data: tariffRole
    }

    return await sendApiRequestReturnData(config)
}

export const deleteTariffRoleRequest = async (tariffRoleId) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/delete_tariff_role_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            tariff_role_id: tariffRoleId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addTariffRequest = async (tariff) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/add_new_tariff'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            id: tariff.id,
            name: tariff.name,
            price: tariff.price,
            role_name: tariff.role_name,
            role_id: tariff.role_id,
            month: tariff.month,
            week: tariff.week,
            day: tariff.day
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTariffsRequest = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_all_tariffs'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getTariffsByRoleId = async (roleId) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_tariffs_by_role_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            role_id: roleId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addTariffIncome = async (amount, label, receiver_balance_id, student_id, student_name, tariff_id, tariff_name, income_type, additional_files, manager_label, manager_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_income_tariff'

    const data = {
        transactions_purpose: "tariff_payment",
        amount: amount,
        label: label,
        receiver_balance_id: receiver_balance_id,
        student_id: student_id,
        student_name: student_name,
        tariff_id: tariff_id,
        tariff_name: tariff_name,
        income_type: income_type,
        manager_label: manager_label,
        manager_id: manager_id
    }

    const formData = new FormData()

    formData.append('real_income_tariff', JSON.stringify(data))

    additional_files.forEach((file) => {
        formData.append('additional_files', file)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const getAllRealIncomeTariffs = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_all_real_income_tariffs'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getPercentTrainingsWithAdditionalFilesInCurrentMonth = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_percent_trainings_with_additional_files_in_current_month'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getCountStudentsWithOverdueTariffs = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_count_students_with_overdue_tariffs'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const updateAdminCard = async (adminCard) => {
    const session_url = process.env.VUE_APP_API_URL + 'admin/update_admin'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            admin_id: adminCard.admin_id
        },
        data: adminCard
    }

    console.log('adminCard -------- ', adminCard)

    return await sendApiRequestReturnData(config)
}

export const addNewAdmin = async (adminCard) => {
    const session_url = process.env.VUE_APP_API_URL + 'admin/add_new_admin_for_admin'

    const config = {
        method: 'post',
        url: session_url,
        data: adminCard
    }

    return await sendApiRequestReturnData(config)
}

export const deleteAdminRequest = async (adminId) => {
    const session_url = process.env.VUE_APP_API_URL + 'admin/delete_admin'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            admin_id: adminId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getStudentsWhichAttendedTraining = async (trainingDate, groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_students_by_attended_training'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_date: trainingDate,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsWhichMissedTraining = async (trainingDate, groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_students_by_missed_training'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_date: trainingDate,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const addNewTariff = async (tariff) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/add_new_tariff'

    const config = {
        method: 'post',
        url: session_url,
        data: tariff
    }

    return await sendApiRequest(config)
}

export const getTariffById = async (tariffId) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_tariff_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            tariff_id: tariffId
        }
    }

    return await sendApiRequest(config)
}

export const getAllTariffs = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_all_tariffs'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequest(config)
}

export const updateTariffRequest = async (tariff) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/update_tariff_by_id'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            tariff_id: tariff.tariff_id
        },
        data: tariff
    }

    return await sendApiRequest(config)
}

export const deleteTariffRequest = async (tariffId) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/delete_tariff_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            tariff_id: tariffId
        }
    }

    return await sendApiRequest(config)
}

export const addTariffGroup = async (tariffGroup) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/add_new_tariff_group'

    const config = {
        method: 'post',
        url: session_url,
        data: tariffGroup
    }

    return await sendApiRequest(config)
}

export const getAllTariffGroups = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_all_tariff_groups'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequest(config)
}

export const getTariffGroupById = async (tariffGroupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_all_tariff_groups'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            tariff_group_id: tariffGroupId
        }
    }

    return await sendApiRequest(config)
}

export const updateTariffGroup = async (tariffGroup) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/update_tariff_group_by_id'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            tariff_group_id: tariffGroup.group_id
        },
        data: tariffGroup
    }

    return await sendApiRequest(config)
}

export const deleteTariffGroup = async (tariffGroupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/delete_tariff_group_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            tariff_group_id: tariffGroupId
        }
    }

    return await sendApiRequest(config)
}

export const getAllTariffsAndGroups = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_all_group_and_its_tariffs'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequest(config)
}

export const getAllClientTariffsByClientId = async (clientId) => {
    const session_url = process.env.VUE_APP_API_URL + 'client_tariffs/get_all_client_tariffs_by_client_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            client_id: clientId
        }
    }

    return await sendApiRequest(config)
}

export const countClientsTrainingById = async (trainingId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/count_clients_training_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_id: trainingId
        }
    }

    return await sendApiRequest(config)
}

export const getClientsListByTrainingId = async (trainingId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_clients_list_by_training_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_id: trainingId
        }
    }

    return await sendApiRequest(config)
}

export const addClientTrainingBook = async (clientTrainingBook) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_client_training_book'

    const config = {
        method: 'post',
        url: session_url,
        data: clientTrainingBook
    }

    return await sendApiRequest(config)
}

export const updateClientBookStatus = async (training_id, client_id, status) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_client_book_status'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            training_id: training_id,
            client_id: client_id,
            status: status
        }
    }

    return await sendApiRequest(config)
}

export const getClientsTrainingByClientId = async (clientId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_clients_training_by_client_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            client_id: clientId
        }
    }

    return await sendApiRequest(config)
}


export const getTrainingsByTariffGroupId = async (tariffGroupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_trainings_by_tariff_group_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            tariff_group_id: tariffGroupId
        }
    }

    return await sendApiRequest(config)
}

export const deleteClientTrainingById = async (trainingId, clientId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/delete_client_training_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_id: trainingId,
            client_id: clientId
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerCompletedTrainingsByMonthes = async (year, trainerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_completed_trainings_by_monthes'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            trainer_id: trainerId
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerClientsByMonthes = async (year, trainerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_clients_by_monthes'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            trainer_id: trainerId
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerEarnedMoneyByMonthes = async (year, trainerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_earned_money_by_monthes'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            trainer_id: trainerId
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerStatisticsByTarriffGroups = async (year, month, trainerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_statistics_by_tarrif_groups'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            month: month,
            trainer_id: trainerId
        }
    }

    return await sendApiRequest(config)
}

export const getTerminalTransactionsByDayAndManagerId = async (date, managerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_terminal_transactions_by_day_and_manager_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            date: date,
            manager_id: managerId
        }
    }

    return await sendApiRequest(config)
}

export const getIncomeOnInvestBalanceManager = async (investBalanceId, date) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_income_on_invest_balance_manager'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            invest_balance_id: investBalanceId,
            date: date
        }
    }

    return await sendApiRequest(config)
}

export const deleteClientTariffById = async (clientTariffId) => {
    const session_url = process.env.VUE_APP_API_URL + 'client_tariffs/delete_client_tariff_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            client_tariff_id: clientTariffId
        }
    }

    return await sendApiRequest(config)
}

export const deleteIncomeTransaction = async (transactionId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/delete_income_transaction'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            transaction_id: transactionId
        }
    }

    return await sendApiRequest(config)
}

export const copyTrainingsToWeek = async (dateTemplateStart, dateTemplateEnd, dateCopyStart) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/copy_trainings_to_week'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            start_template_week: dateTemplateStart,
            end_template_week: dateTemplateEnd,
            start_copy_week: dateCopyStart
        }
    }

    return await sendApiRequest(config)
}

export const changeDateInClientTariff = async (clientTariffId, newDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'client_tariffs/change_date_in_client_tariff'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            client_tariff_id: clientTariffId,
            new_date: newDate
        }
    }

    return await sendApiRequest(config)
}
<template>
    <div class="border-[1px] border-[#2B2A29] w-full px-[21px] py-[17px] rounded-[10px] mt-[27px]">
        <div class="uppercase bg-green-emb py-[4px] text-[13px] font-bold text-center rounded-[4px]">
            ДОКУМЕНТИ
        </div>

        <div class="flex w-full justify-between">
            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Звіти про управління ФК «Минай» 
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Звіти про рух грошових коштів
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Звіти про власний капітал
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Звіти про фінансовий стан (баланс)
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
            </div>

            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Звіти про фінансові результати 
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Управлінський звіт
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Звіт за результатами аудиту фінансової звітності
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
            </div>

            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Логотип
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
                <div class="w-[100%] mb-[20px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Ліцензія
                    </div>
                    <div class="mt-[3px] flex justify-between border-b-[1px] pb-[15px] border-[#2B2A29]">
                        <div></div>
                        <div><img src="@/assets/icons/upload_icon_light.svg" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'documents',
        
    }
</script>

<style lang="scss" scoped>

</style>
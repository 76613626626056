<template>
    <div class="bg-[#1D1D1D] w-full rounded-[10px] pt-[10px] h-[533px] ">
        <div class="w-full">
            <div class="w-full text-center ">
                <div class="flex text-center items-center py-[1px] bg-[#ABD3EC] w-[95%] rounded-[10px] mx-auto">
                    <div class="block-title text-[13px] font-medium text-[#1D1D1D] text-center mx-auto w-[60%]">СТАТИСТИКА</div>
                </div>
            </div>
        </div>
        <carousel :items-to-show="1" :wrapAround="true">
            <slide :key="1">
                <div>
                    <div class="flex mt-[12px] mb-[12px] justify-around">
                        <div class="flex mx-auto justify-around">
                            <div class="h-[110px]">
                                <div>
                                    <circle-progress :fillColor="'#ABD3EC'" :emptyColor="'rgba(235, 235, 235, 0.1)'" :size="110" :percent="percentTrainingsAttended" :viewport="false" :borderWidth="12" :borderBgWidth="12" />
                                </div>
                                <div class="relative top-[-71px] left-[2px]">
                                    <div class="text-[#ABD3EC] text-2xl font-bold"> {{percentTrainingsAttended}}%</div>
                                </div>
                            </div>
                            <div class="w-[140px] mx-5 mt-[10px]">
                                <div class="flex items-center w-full justify-between">
                                    <div class="text-[#ABD3EC]">тренувань</div>
                                    <div class="bg-[#ABD3EC] p-2 w-[5ch] text-center rounded-lg font-bold">{{ trainingsAttendedList.length + trainingsMissedList.length }}</div>
                                </div>
                                <div class="flex items-center w-full justify-between mt-3">
                                    <div class="text-[#ABD3EC] mr-2">відвідувань</div>
                                    <div class="bg-[#990D35] text-white p-2 w-[5ch] text-center rounded-lg font-bold">{{ trainingsAttendedList.length }}</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </slide>

            <slide :key="2">
                <div>
                    <!-- <div class="text-white">
                        <div class="text-[10px] font-bold ">
                            СІЧЕНЬ 2022
                        </div>
                        <div class="text-[6px] ">
                            (відвідування)
                        </div>
                    </div> -->
                    <div class="w-[300px]">
                        <training-calendar />
                    </div>
                </div>
            </slide>

            <template #addons>
                <navigation />
            </template>
        </carousel>
        <div class="w-full">
            <div class="w-full text-center ">
                <div class="flex text-center items-center py-[1px] bg-[#ABD3EC] w-[95%] rounded-[10px] mx-auto">
                    <div class="block-title text-[11px] font-bold text-[#1D1D1D] text-center mx-auto w-[60%]">ФІНАНСИ</div>
                </div>
            </div>
        </div>
        <div class="mt-[10px]">
            <div class="flex flex-col items-center">
                <div class="text-[#990D35] text-[20px] font-bold block-title">
                    {{ balanceState.toFixed(2) }}<span class="font-sans">₴</span>
                </div>
                <div class="text-gray-300 text-[8px] font-light">
                    (поточний баланс)
                </div>
            </div>
        </div>

        <outlay-accounting 
            :studentOutlays="allOutlays"
        />
        
        <income-accounting 
            :studentIncomes="allIncomes"
        />
    </div>
</template>

<script>
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import { ref, onMounted, onBeforeMount } from 'vue'
    import { getBalanceState, getAllIncomes, getAllOutlays } from '@/services/apiRequests'
    import CircleProgress from "vue3-circle-progress"
    import OutlayAccounting from '../ui/OutlayAccounting.vue'
    import IncomeAccounting from '../ui/IncomeAccounting.vue'
    import TrainingCalendar from '../ui/TrainingCalendar/TrainingCalendar.vue'
    export default {
        name: 'stats-and-finance-card',
        props: {
            trainingsAttendedList: {
                type: Array,
                default: () => []
            },
            trainingsMissedList: {
                type: Array,
                default: () => []
            },
            balanceId: {
                type: String,
                default: ''
            },
        },

        setup(props) {
            const percentTrainingsAttended = ref(0)
            const balanceState = ref(0)
            const allIncomes = ref([])
            const allOutlays = ref([])
        
            onMounted(async () => {
                percentTrainingsAttended.value = props.trainingsAttendedList.length * 100 / (props.trainingsAttendedList.length + props.trainingsMissedList.length)
                percentTrainingsAttended.value = percentTrainingsAttended.value ? percentTrainingsAttended.value : 0

                allIncomes.value = (await getAllIncomes(props.balanceId)).virtual_incomes
                allOutlays.value = (await getAllOutlays(props.balanceId)).virtual_outlay

                const balanceResponse = await getBalanceState(props.balanceId)
                balanceState.value = balanceResponse.balance_state
            })

            return {
                percentTrainingsAttended,
                balanceState,
                allIncomes,
                allOutlays
            }
        },

        components: {
            CircleProgress,
            Carousel,
            Slide,
            Pagination,
            Navigation,
            OutlayAccounting,
            IncomeAccounting,
            TrainingCalendar
        },
    }
</script>

<style lang="scss" >
    .block-title { 
        font-family: 'Avenir Cyrillic Bold';
    }

    svg.carousel__icon {
        color: #ABD3EC;
    }
</style>
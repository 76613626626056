<template>
    <div class="search-block w-[90%] mx-auto">
        <div class="flex justify-between mx-2 mt-2">
            <div class="search-bar flex items-center bg-[#525A60] border-[1px] border-[#525A60] bg-opacity-30 px-[5px] rounded-[6px]">
                <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input class="text-[#525A60] text-[14px] bg-transparent rounded-md px-1 py-2 placeholder:text-[#525A60] outline-none w-[250px]" placeholder="Пошук..." type="text">
                <!-- <button class="bg-slate-500 hover:bg-slate-600 text-slate-200 px-2 py-1 rounded-md ml-2">Фільтр</button> -->
            </div>
            <div class="filter">
                <button @click="$emit('addLocation')" class="bg-green-emb hover:bg-[#6BA292d6] w-[228px] text-white px-4 py-[6px] text-[14px] rounded-md">Додати</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'menu-bar'
    }
</script>

<template>
    <div class="bg-[#FFFFFF] border-[1px] border-[#C9ADA7] rounded-[9px]">
        <div class="text-center px-[33px] mt-[16px] text-[15px] text-gray-emb font-semibold">
            {{ alertText }}
        </div>
        <div class="text-center mt-[11px] px-[33px] block text-[13px] text-gray-emb font-normal">
            Ваші зміни будуть втрачені якщо ви не збережете їх.
        </div>
        <div class="flex flex-col justify-center items-center mt-[17px]">
            <button @click="$emit('doSave')" class="bg-green-emb text-[#FFF] hover:bg-opacity-90 font-semibold rounded-[5px] text-center w-[228px] py-[4px] mb-[6px]">Зберегти</button>
            <button @click="$emit('dontSave')" class="bg-[#990D35] text-[#FFF] hover:bg-opacity-90 font-semibold rounded-[5px] text-center w-[228px] py-[4px]">Не зберігати</button>
        </div>
        <div class="flex flex-col justify-center items-center mt-[16px] mb-[16px]">
            <button @click="$emit('returnToCurrent')" class="bg-gray-emb text-[#FFF] hover:bg-opacity-90 font-semibold rounded-[5px] text-center w-[228px] py-[4px]">Відмінити</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'exit-confirm-form',

        props: {
            alertText: {
                type: String,
                default: 'test'
            }
        }
    }
</script>

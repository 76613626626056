<template>
    <div class="search-block w-[90%] mx-auto">
        <div class="flex justify-between mx-2 mt-2">
            <div class="search-bar flex items-center bg-[#fff] border-[1px] border-[#fff] bg-opacity-30 px-[5px] rounded-[6px]">
                <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input v-model="searchQuery" class="text-[#fff] text-[14px] bg-transparent rounded-md px-1 py-2 placeholder:text-[#fff] outline-none w-[250px] " placeholder="Пошук..." type="text">
                <!-- <button class="bg-slate-500 hover:bg-slate-600 text-slate-200 px-2 py-1 rounded-md ml-2">Фільтр</button> -->
            </div>
            <div class="filter">
                <button class="bg-green-emb hover:bg-[#42a82d] w-[228px] text-white px-4 py-[6px] text-[14px] rounded-md" @click="$emit('addGroup')">Додати</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'

    export default {
        name: 'menu-bar',

        setup(props, { emit }) {
            const searchQuery = ref('')

            watch(searchQuery, (newValue) => {
                emit('search', newValue)
            })

            return {
                searchQuery
            }
        }
    }
</script>

<template>
    <div @click="$emit('close')" class="w-[100%] ml-[-10px] backdrop-blur-sm h-full absolute z-20"></div>
    <div v-if="loadingSpinnerFlag" class="z-[150] fixed w-full h-full top-[50%] left-[50%] ml-[115px]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#990D35] border-t-transparent "></div>
    </div>
    <div v-else class="bg-white shadow-md w-[414px] absolute top-[75px] border-[1px] border-[#525A60] border-opacity-20 left-[50%] ml-[128px] translate-x-[-50%]  z-30 px-[18px] pt-[6px] pb-[8px] rounded-[22px]">
        <!-- 
            border-[#1D1D1D]
            <div class="absolute top-[10px] right-[10px]">
            <button class="float-right relative" @click="$emit('close')">
                <img src="@/assets/icons/cross_icon.svg" class="w-3 h-3">
            </button>
        </div> -->
        <div class="text-[#525A60] font-semibold text-center mb-[-5px] pb-[3px] pt-[3px]">
            <div class="relative top-[1px]">
                {{ `${day.dayName[0].toUpperCase() + day.dayName[1]}.  ${date}` }}
            </div>
            
        </div>

        <div class="w-full border-t-[1px] border-[#525A60] mt-[12px] relative">
            <div class="scrolltab h-[80vh]  mb-2  pt-[7px] overflow-auto">
                
                <div v-for="training in allTrainings">
                    <!-- <div @click="$emit('setTrainingToView', training)" class="bg-[#9A8C98] cursor-pointer mt-[12px] bg-opacity-30 flex items-center justify-between  border-[#9A8C98] border-[1px] rounded-[20px] w-full px-[15px] py-[17px]">
                        <div class="flex">
                            <div class="flex items-center">
                                <div class="w-[35px] h-[35px] rounded-full overflow-hidden border-[1px] border-[#525A60]">
                                    <img v-if="training?.trainer_photo" :src="apiURL + 'static/' + training?.trainer_photo">
                                    <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ training?.trainer_label.split(' ')[0][0] + training?.trainer_label.split(' ')[1][0] }}</div>
                                </div>
                                <div>
                                    <div class="ml-[10px] text-[#525A60] font-bold">{{ training?.trainer_label }}</div>
                                    <div class="flex ml-[10px]">
                                        <div>{{training?.start_time}}</div>
                                        <div>></div>
                                        <div>{{training?.end_time}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-[14px]">
                                <div class="text-[#525A60] lowercase font-bold truncate">{{ training?.superposition ? 'PERSONAL/SPLIT' : training?.tariff_group_name }}</div>
                                <div class="text-[#525A60]">{{ parseTrainingType(training?.type) }}</div>
                            </div>
                            <div class="ml-[14px]">
                                <div class="text-[#525A60] mt-[26px]">{{ training?.location_label }}</div>
                            </div>
                        </div>
                        <div class="flex">
                            <div :class="[training.clients_count >= training?.max_clients ? 'text-[#990D35]' : 'text-[#6BA292]']">
                                {{training.clients_count}}/{{training?.max_clients}}
                            </div>
                            <div>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11" cy="11" r="11" :fill="training.clients_count >= training?.max_clients ? '#990D35' : '#6BA292'"/>
                                </svg>
                            </div>
                        </div>
                    </div> -->

                    <SquircleComponent
                        @click="$emit('setTrainingToView', training)"
                        class="mt-[8px] relative   mx-auto cursor-pointer"
                        :class="{
                            'bg-violet-emb' : training.status == 'planed',
                            'bg-green-emb' : training.status == 'completed',
                        }"
                        :smooth="110" 
                        :radius="20" 
                        :width="354" 
                        :height="78" 
                        :outlineWidth="0"
                    >
                            <div class="flex text-[#4A4E69] relative top-[15px] items-center ml-[10px] mr-[16px] ">
                                <div class="w-full pt-[3px] flex items-center">
                                    <div class="w-[52.1px] h-[44px] mr-[10px] relative bottom-[5px] rounded-full overflow-hidden border-[2px] border-[#525A60] ">
                                        <img v-if="training?.trainer_photo" class="w-full h-full bg-white " :src="apiURL + 'static/' + training?.trainer_photo">
                                        <div v-else-if="training?.trainer_label.split(' ') >= 2" class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ training?.trainer_label?.split(' ')[0][0].toUpperCase() + training?.trainer_label?.split(' ')[1][0].toUpperCase() }}</div>
                                        <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ training?.trainer_label[0].toUpperCase() }}</div>
                                    </div>
                                    <div class="w-full mt-[px]">
                                        <div class="w-full border-opacity-20 pb-[10px]">
                                            <div class="flex w-full">
                                                <div class="font-bold relative truncate text-[16px] text-gray-emb bottom-[5px] w-[37%]">{{ training.trainer_label.split(' ')[0] + ' ' + training.trainer_label.split(' ')[1][0] + '.'  }}</div>
                                                <div class="font-bold text-[13px] relative text-gray-emb bottom-[1px]">{{ training.superposition ? 'per/split' : training.tariff_group_name }}</div>
                                            </div>
                                            <div class="flex justify-start w-full">
                                                <div class="flex w-[37%] relative top-[6px]">
                                                    <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.start_time }}</div>
                                                    <div class="mx-[3px] text-[11px] text-gray-emb">
                                                        <svg class="scale-50 origin-center relative top-[2px]" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 11L5.29289 6.70711C5.62623 6.37377 5.79289 6.20711 5.79289 6C5.79289 5.79289 5.62623 5.62623 5.29289 5.29289L1 1" stroke="#525A60" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </div>
                                                    <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.end_time }}</div>
                                                </div>
                                                <div class="text-start text-[11px] text-gray-emb relative top-[6px] font-bold">{{ parseTrainingType(training.type) }}</div>
                                                <div class="flex ml-auto mt-[-13px]">
                                                    <div class="text-[11px] mr-[px] mt-[4px] text-gray-emb">
                                                        {{training.location_label}}
                                                    </div>
                                                    <div 
                                                        class="text-[#6BA292] w-[34px] text-end font-bold"
                                                        :class="{
                                                            'text-[#6BA292]' : training.clients_count < training.max_clients,
                                                            'text-[#BF637D]' : training.clients_count >= training.max_clients
                                                        }"    
                                                    >{{ training.clients_count }}/{{ training.max_clients }}</div>
                                                    <div 
                                                        class="h-[22px] w-[22px] border-[2px] ml-[5px] rounded-full"
                                                        :class="{
                                                            'bg-[#6BA292] border-[#6BA292]' : training.clients_count < training.max_clients,
                                                            'bg-[#BF637D] border-[#BF637D]' : training.clients_count >= training.max_clients
                                                        }"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </SquircleComponent>
                </div>
            </div>
        </div>

        <!-- <div class="mt-5 w-full">
            <button @click="$emit('addTraining', day.dateObj)" class="text-[#142552] bg-[#ABD3EC] text-center w-full rounded-md py-1 hover:bg-opacity-90">Додати тренування</button>
        </div> -->

        <div @click="$emit('addTraining', day.dateObj)" class="w-[100%] flex justify-center mx-auto relative bottom-[16px] items-center rounded-[9px] mt-[20px] cursor-pointer font-bold h-[44px] text-[13px] bg-green-emb text-gray-emb">
            <div>Додати тренування</div>
        </div>

    </div>
</template>

<script>
    import { ref, computed, watch } from 'vue'
    import { deleteTrainingById } from '@/services/apiRequests'
    import SquircleComponent from './SquircleComponent.vue'

    export default {
        name: 'full-day-view',

        props: {
            day: {
                type: Date,
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.day.dateObj)
            const settedYear = settedDate.getFullYear() >= 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 >= 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() >= 10 ? settedDate.getDate() - 1 : `0${settedDate.getDate() -1 }`
            const date = ref(`${settedDay}.${settedMonth}.${settedYear}`)

            const loadingSpinnerFlag = ref(false)

            const apiURL = process.env.VUE_APP_API_URL

            const allTrainings = computed(() => {
                return props.day.trainings.sort((a, b) => {
                    return a.start_time.localeCompare(b.start_time)
                })
            })

            const deleteTraining = async (id) => {
                loadingSpinnerFlag.value = true
                const response = await deleteTrainingById(id)
                if (response.status === 200) {
                    emit('deleteTraining', id)
                }
                loadingSpinnerFlag.value = false
            }

            const parseTrainingType = (type) => {
                switch (type) {
                    case 'yoga':
                        return 'йога'
                    case 'barre':
                        return 'барре'
                    case 'pilates_ref':
                        return 'пілатес(реф)'
                    case 'pilates_mat':
                        return 'пілатес(мат)'
                    case 'straching':
                        return 'стретчінг'
                }
            }

            return {
                date,
                deleteTraining,
                allTrainings,
                loadingSpinnerFlag,
                parseTrainingType,
                apiURL
            }
        },

        components: {
            SquircleComponent
        }
    }
</script>

<style>

    .scrolltab::-webkit-scrollbar {
        width: 3px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #a6a4a4;
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }

</style>

<template>
    <div @click="$emit('close')" class="backdrop-blur-[7px] fixed w-full h-[130%] top-[-70px]"></div>
    <div class="absolute w-[470px] h-[473px] bg-white2 rounded-[22px] top-[50%] px-[44px] translate-y-[-50%] left-[50%] translate-x-[-30%] z-[999] ml-[20px]">
        <div class="mt-[41px] bg-violet-emb rounded-[20px] bg-opacity-30 border-violet-emb border-[2px] flex justify-between w-[382px] h-[76px] items-center px-[23px]">
            <div class="flex items-center">
                <div class="mr-[12px]">
                    <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1555 28.1137C14.5774 28.6822 13.8045 29 13.0003 29C12.196 29 11.4232 28.6822 10.845 28.1137C5.55023 22.8764 -1.54539 17.0257 1.91493 8.53153C3.78589 3.93882 8.27705 1 13.0003 1C17.7235 1 22.2146 3.93882 24.0856 8.53153C27.5415 17.015 20.4634 22.8944 15.1555 28.1137Z" stroke="#22223B"/>
                        <path d="M18.333 13.1501C18.333 15.9116 16.0944 18.1501 13.333 18.1501C10.5716 18.1501 8.33301 15.9116 8.33301 13.1501C8.33301 10.3887 10.5716 8.15015 13.333 8.15015C16.0944 8.15015 18.333 10.3887 18.333 13.1501Z" stroke="#22223B"/>
                    </svg>
                </div>
                <div class="text-[14px] font-bold">
                    <div>{{locationName ? locationName : 'Не задано' }}</div>
                </div>
            </div>
            <div class="text-[12px]">{{locationAddress ? locationAddress : 'Не задано'}}</div>
        </div>

        <div class="mt-[36px]">
            <div class="mb-[17px]">
                <input v-model="locationName" class="w-full h-[34px] bg-[#9A8C98] border-[#9A8C98] bg-opacity-30 border-[1px] rounded-[10px] px-[14px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="Вкажіть назву залу" />
            </div>
            <div class="mb-[17px]">
                <input v-model="locationSmallName" class="w-full h-[34px] bg-[#9A8C98] border-[#9A8C98] bg-opacity-30 border-[1px] rounded-[10px] px-[14px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="Скорочена назва (для календаря)" />
            </div>
            <div class="mb-[17px]">
                <input v-model="locationAddress" class="w-full h-[34px] bg-[#9A8C98] border-[#9A8C98] bg-opacity-30 border-[1px] rounded-[10px] px-[14px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="Адреса локації" />
            </div>
            <div>
                <input v-model="locationGeoUrl" class="w-full h-[34px] bg-[#9A8C98] border-[#9A8C98] bg-opacity-30 border-[1px] rounded-[10px] px-[14px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="Додати посилання на google-карту" />
            </div>
        </div>
        
        <button @click="addNewLocationEvent" class="w-full bg-green-emb text-gray-emb h-[44px] rounded-[9px] mt-[36px] font-bold">
            Зберегти
        </button>

    </div>
</template>

<script>
    import { ref } from 'vue'
    import { addNewLocation } from '@/services/apiRequests'
    import UrlComponent from './UrlComponent.vue'

    export default {
        name: 'new-location-form',

        emits: ['close', 'updateLocations'],

        setup(props, { emit }) {
            const locationName = ref('')
            const locationSmallName = ref('')
            const locationAddress = ref('')
            const locationMapMark = ref('')
            const locationDescription = ref('')
            const locationGeoUrl = ref('')

            const locationPhoto = ref(null)
            const locationPhotoDataUrl = ref(null)

            const selectLocationPhoto = () => {
                const input = document.querySelector('input[type="file"]')
                input.click()
                input.addEventListener('change', () => {
                    locationPhoto.value = input.files[0]
                    locationPhotoDataUrl.value = URL.createObjectURL(input.files[0])
                })
            }

            const deleteLocationPhoto = () => {
                locationPhoto.value = null
                locationPhotoDataUrl.value = null
            }

            const sendToMap = () => {
                const mapQuery = locationAddress.value.split(' ').join('%20')
                window.open(`https://www.openstreetmap.org/search?query=${mapQuery}`, '_blank')
            }

            const addNewLocationEvent = () => {
                addNewLocation(
                    locationName.value, 
                    locationSmallName.value,
                    locationAddress.value, 
                    locationDescription.value,
                    locationGeoUrl.value,
                    locationPhoto.value
                ).then(res => {
                    emit('close')
                    emit('updateLocations') 
                })
            }

            return {
                locationName,
                locationAddress,
                locationMapMark,
                locationDescription,
                locationPhoto,
                locationPhotoDataUrl,
                selectLocationPhoto,
                deleteLocationPhoto,
                sendToMap,
                addNewLocationEvent,
                locationGeoUrl,
                locationSmallName
            }
        },

        components: {
            UrlComponent
        }
    }
</script>
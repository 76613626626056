<template>
    <div class="w-[250px] h-[500px] bg-white2 rounded-[10px] border-[1px] border-rose-emb">
        <div>
            <div>
                <img 
                    @click="uploadPhoto"
                    src="@/assets/icons/upload_icon_light.svg" 
                    class="absolute left-[210px] top-[20px] cursor-pointer z-40"
                >
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="$emit('loadPhoto', $event)"
                />
            </div>
            <div class="relative flex justify-center items-end h-[162px] w-full">
                <carousel 
                    class="w-full h-[162px] relative top-[17px]"
                >
                    <slide 
                        :key="2"
                    >
                        <div class="h-full">
                            <img v-if="wallpaperPhoto" :src="apiURL + 'static/' + wallpaperPhoto" class="relative right-[10px]  h-[140px] top-[20px]">
                            <img v-if="!wallpaperPhoto" :src="require('@/assets/student_photo_placeholder.svg')" class="relative right-[10px] left-[50%] translate-x-[-50%] h-[140px] top-[20px]">
                            <div v-if="!wallpaperPhoto" class="absolute bottom-[0] left-[50%] translate-x-[-50%] text-[13px] text-gray-emb">додати фото</div>
                        </div>
                    </slide>
                    <slide 
                        :key="1"
                    >
                        <div class="h-full">
                            <img v-if="!addPhotoFlag" @click="addPhotoFlag = true" :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="relative w-[375px] h-[155px] top-[15px]">
                            <!-- <img v-if="!addPhotoFlag" @click="addPhotoFlag = true" :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="relative w-[375px] h-[140px] top-[15px]"> -->
                            <div v-if="!addPhotoFlag" @click="addPhotoFlag = true" class="absolute bottom-[0] left-[50%] translate-x-[-50%] text-[13px] text-gray-emb">додати фото</div>
                            <camera v-if="addPhotoFlag" :resolution="{ width: 375, height: 212 }" ref="camera" autoplay></camera>
                        </div>
                    </slide>

                    <!-- <template #addons>
                        <pagination ref="paginationSliderRef" :navigate-to="2" />
                    </template> -->
                </carousel>
            </div>
        </div>

        <div class="bg-rose-emb w-full h-[74px] relative top-[10px]">
            <div class="text-[30px] h-full flex justify-center items-center text-gray-emb font-semibold text-center">
                <div class="h-[45px]" v-if="name?.length > 0 || surname?.length > 0">
                    <marquee v-if="name?.length + surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
                        <span v-for="id in 100" class="mx-3">{{ name + ' ' + surname }}</span>
                    </marquee>
                    <span v-else>{{ name + ' ' + surname }}</span>
                </div>
                <span v-else class="opacity-25">Юля Сенюк</span>
            </div>
        </div>

        <div class="mx-[14px] relative  top-[27px]">
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="surname" @change="$emit('update:surname', $event.target.value)" class="text-gray-emb font-semibold text-[14px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Прізвище" type="text">
                </div>
                <div class="font-normal text-gray-emb opacity-90 text-[12px]">
                    прізвище
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="name" @change="$emit('update:name', $event.target.value)" class="text-gray-emb font-semibold text-[14px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Ім'я" type="text">
                </div>
                <div class="font-normal text-gray-emb opacity-90 text-[12px]">
                    ім'я
                </div>
            </div>
            <div class="flex justify-between items-center align-middle relative right-[3px] mb-[8px]">
                <div>
                    <input :value="formattedBirthDate" @change="formattedBirthDate = $event.target.value" class="text-gray-emb p-0 font-semibold text-[14px] outline-none bg-transparent border-none w-[98%] truncate placeholder:opacity-60"  type="date">
                </div>
                <div class="font-normal text-gray-emb opacity-90 text-[12px]">
                    дата народження
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div class="w-[50%]">
                    <select class="text-gray-emb relative left-[-2px] font-semibold text-[14px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60">
                        <option>жін.</option>
                        <option>чол.</option>
                    </select>    
                </div>
                <div class="font-normal text-gray-emb opacity-90 text-[12px]">
                    стать
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%] flex">
                    <span class="text-gray-emb mr-[5px] font-semibold text-[13px] relative">+380</span><input maxlength="9" :value="phoneNumber.replace('380', '')" @change="$emit('update:phoneNumber', '380' + $event.target.value)" class="text-gray-emb font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="000000000">
                </div>
                <div class="text-gray-emb font-normal mt-[4px] opacity-90 text-[12px]">
                    номер
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input :value="password" @change="$emit('update:password', $event.target.value)" class="text-gray-emb font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="password">
                </div>
                <div @click="randomPassword">
                    <img src="@/assets/icons/flash_icon.svg" class="cursor-pointer w-[20px]">
                </div>
                <div class="text-gray-emb mt-[4px] font-normal opacity-90 text-[12px]">
                    пароль
                </div>
            </div>

            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <div @click="snapshot" class="w-[100px] h-[22px] flex justify-center items-center bg-green-emb rounded-[4px] cursor-pointer">
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3333 3.66669C12.897 3.66669 13.3455 3.66669 13.8841 4.04554C14.0228 4.14307 14.1469 4.25685 14.2533 4.38396C14.6666 4.87769 14.6666 5.59439 14.6666 7.0278V9.7778C14.6666 11.5063 14.6666 12.3705 14.0808 12.9075C13.495 13.4445 12.5522 13.4445 10.6666 13.4445H5.33325C3.44763 13.4445 2.50482 13.4445 1.91904 12.9075C1.33325 12.3705 1.33325 11.5063 1.33325 9.7778V7.0278C1.33325 5.59439 1.33325 4.87769 1.74655 4.38396C1.85295 4.25685 1.97706 4.14307 2.11573 4.04554C2.65434 3.66669 3.10287 3.66669 4.66659 3.66669" stroke="#424242" stroke-linecap="round"/>
                        <path d="M11.3332 4.27778L10.7426 2.92444C10.4878 2.34054 10.2661 1.67818 9.61093 1.38084C9.26144 1.22223 8.8409 1.22223 7.99984 1.22223C7.15877 1.22223 6.73824 1.22223 6.38874 1.38084C5.73357 1.67818 5.51185 2.34054 5.25706 2.92444L4.6665 4.27778" stroke="#424242" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.3332 8.75002C10.3332 10.0387 9.2885 11.0834 7.99984 11.0834C6.71117 11.0834 5.6665 10.0387 5.6665 8.75002C5.6665 7.46136 6.71117 6.41669 7.99984 6.41669C9.2885 6.41669 10.3332 7.46136 10.3332 8.75002Z" stroke="#424242"/>
                        <path d="M7.99987 3.66669H8.00586" stroke="#424242" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <button @click="snapshot" class="font-normal text-gray-emb opacity-90 text-[12px]">
                    зробити фото
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import DataInput from '../ui/DataInput.vue'
    import Camera from "simple-vue-camera"

    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'new-info-card',

        props: {
            surname: {
                type: String,
                default: '',
                required: true
            },
            name: {
                type: String,
                default: '',
                required: true
            },
            patronymic: {
                type: String,
                default: '',
                required: true
            },
            birthDate: {
                type: String,
                default: '',
                required: true
            },
            phoneNumber: {
                type: String,
                default: '',
                required: true
            },
            email: {
                type: String,
                default: '',
                required: true
            },
            group: {
                type: String,
                default: '',
                required: true
            },
            studentPhoto: {
                type: [String, null],
                default: null,
                required: true
            },
            nationality: {
                type: String,
                default: '',
                required: true
            },
            studentNumber: {
                type: String,
                default: '',
                required: true
            },
            studentAmplua: {
                type: String,
                default: '',
                required: true
            },
            password: {
                type: String,
                default: '',
                required: true
            },
            wallpaperPhoto: {
                type: String,
                default: '',
                required: true
            }
        },

        setup(props, { emit }) {
            const fileInput = ref(null)
            const inputValue = ref(null)
            const photo = ref(props.studentPhoto)
            const wallpaperPhoto = ref(props.wallpaperPhoto)

            const apiURL = process.env.VUE_APP_API_URL

            const addPhotoFlag = ref(false)

            const formattedBirthDate = computed({
                get() {
                    if (!props.birthDate.includes('T'))
                        return props.birthDate
                    return formateDate(props.birthDate)
                },
                set(value) {
                    emit('update:birthDate', value)
                }
            })

            const age = computed({
                get() {
                    return new Date(props.birthDate) < new Date() ? new Date().getFullYear() - new Date(props.birthDate).getFullYear() : '—'
                }
            }) 
            
            const uploadPhoto = () => {
                fileInput.value.click()
            }

            const randomPassword = () => {
                const randomString = Math.random().toString(36).slice(-8)
                emit('update:password', randomString)
            }

            onMounted(() => {
                console.log(typeof photo.value)
                if(photo.value === null || photo.value === 'null') {
                    photo.value = require('@/assets/student_photo_placeholder.svg')
                }
            })

            watch(() => props.studentPhoto, (first, second) => {
                photo.value = first
                inputValue.value = null
            })

            const camera = ref(Camera);
            const snapshot = async () => {
                const blob = await camera.value?.snapshot();
                const url = URL.createObjectURL(blob);
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    emit('processedImageFromWebcam', base64data)
                    addPhotoFlag.value = false
                }
            }

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue,
                age,
                formattedBirthDate,
                addPhotoFlag,
                camera,
                snapshot,
                wallpaperPhoto,
                apiURL,
                randomPassword
            }
        },

        components: {
            DataInput,
            Carousel,
            Slide,
            Pagination,
            Navigation
        },
    }
</script>

<style scoped>

    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%234A4E69" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>
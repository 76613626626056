<template>
    <div class="">
        <AdminDashboard v-if="currentAdmin.role == 'admin'" /> 
        <CashierDashboard v-else /> 
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { getAdmin } from '@/services/apiRequests'
    import AdminDashboard from './AdminDashboard.vue'
    import CashierDashboard from './CashierDashboard.vue'
    import SquircleComponent from '@/components/SquircleComponent.vue'

    export default {
        name: 'Finance', 

        setup() {
            const currentAdmin = ref({})

            onMounted(async () => {
                currentAdmin.value = (await getAdmin()).data
            })
            

            return {
                currentAdmin
            }
        },

        components: {
            AdminDashboard,
            CashierDashboard,
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>


</style>
<template>
    <div class="flex justify-between">
        <div class="w-[100%] px-[21px] py-[17px] rounded-[10px] mt-[27px]">
            <div class="uppercase bg-[#C9ADA7] text-[#525A60] text-[13px] py-[4px] font-normal text-center rounded-[4px]">
                Абонементи
            </div>

            <div class="w-full">
                <table class="w-full mt-[15px]">
                    <thead>
                        <tr class="text-[#525A60]">
                            <th class="text-[14px] text-[#990D35] w-[11%] text-start lowercase">Тип абонемента</th>
                            <th class="text-[14px] text-[#990D35] w-[16%] text-center lowercase">НАЗВА АБОНЕМЕНТА</th>
                            <th class="text-[14px] text-[#990D35] w-[11%] text-center lowercase">к-сть тренУВАНЬ</th>
                            <th class="text-[14px] text-[#990D35] w-[11%] text-center lowercase">термін дії (днів)</th>
                            <th class="text-[14px] text-[#990D35] w-[11%] text-center lowercase">вартість (грн)</th>
                            <th class="text-[14px] text-[#990D35] w-[11%] text-center lowercase">короткий Опис</th>
                            <th class="text-[14px] text-[#990D35] w-[11%] text-center lowercase">обрати колір</th>
                            <th class="text-[14px] text-[#990D35] w-[11%] text-center lowercase">дизайн</th>
                            <th class="text-[14px] text-[#990D35] w-[6%]">видалити</th>
                        </tr>
                    </thead>
                    <tbody class="">

                        <SubscriptionGroupRow 
                            v-for="group in tariffGroups" 
                            v-model:groupName="group.group_name" 
                            v-model:maxClients="group.group_max_clients" 
                            :tariffs="group.tariffs" 
                            :groupId="group.group_id"
                            @addNewTariff="tariff => group.tariffs.push(tariff)"  
                            
                            @changeTariffName="e => updateTariff({tariff_id: e.tariff_id, value: e.value, type: 'name'})"
                            @changeTariffTrainingCount="e => updateTariff({tariff_id: e.tariff_id, value: e.value, type: 'training_count'})"
                            @changeTariffDays="e => updateTariff({tariff_id: e.tariff_id, value: e.value, type: 'days'})"
                            @changeTariffPrice="e => updateTariff({tariff_id: e.tariff_id, value: e.value, type: 'price'})"
                            @changeTariffDescription="e => updateTariff({tariff_id: e.tariff_id, value: e.value, type: 'description'})"
                            @changeColor="e => updateTariff({tariff_id: e.tariff_id, value: e.value, type: 'color'})"

                            @selectTariffForView="selectTariffForView"
                            @deleteTariff="deleteTariff(group.group_id, $event)"
                        />

                        <!-- <SubscriptionItemRow /> -->

                        <tr class="text-[#525A60] w-full h-[50px]">
                            <td class="w-[11%] uppercase px-[2px] text-start py-1 text-[0.9rem]">
                                <input :value="newGroupName" @change="e => newGroupName = e.target.value" class="w-full text-start text-[0.9rem] bg-transparent text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="додати тип абонементу">
                            </td>
                            <td class="border-b-[1px] border-[#E1DAD8]" colspan="8"></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="mt-[30px] flex justify-center">
        <button @click="saveTariffs" class="w-[200px] h-[40px] rounded-[6px] bg-green-emb text-[#000] font-medium text-[14px]">
            Зберегти абонементи
        </button>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import SubscriptionGroupRow from './SubscriptionGroupRow.vue'
    import SubscriptionItemRow from './SubscriptionItemRow.vue'
    import { addNewTariff, getTariffById, getAllTariffs, updateTariffRequest, deleteTariffRequest, addTariffGroup, getAllTariffGroups, getTariffGroupById, updateTariffGroup, deleteTariffGroup, getAllTariffsAndGroups } from '@/services/apiRequests'

    export default {
        name: 'tariff-constructor',
        setup(props, { emit }) {
            const tariffGroups = ref([])
            const oldTariffGroupsObjects = ref([])

            const newGroupName = ref('')

            watch(newGroupName, (val) => {
                if (!val) return
                const newGroup = {
                    group_id: uuid.v4(),
                    group_name: val,
                    group_max_clients: 0,
                    tariffs: []
                }
                tariffGroups.value.push(newGroup)
                newGroupName.value = ''
            })

            const updateTariff = async (data) => {
                tariffGroups.value = tariffGroups.value.map(group => {
                    group.tariffs = group.tariffs.map(tariff => {
                        if (tariff.tariff_id === data.tariff_id) {
                            if (data.type === 'name') {
                                tariff.tariff_name = data.value
                            } else if (data.type === 'training_count') {
                                tariff.tariff_training_count = data.value
                            } else if (data.type === 'days') {
                                tariff.tariff_days = data.value
                            } else if (data.type === 'price') {
                                tariff.tariff_price = data.value
                            } else if (data.type === 'description') {
                                tariff.tariff_description = data.value
                            } else if (data.type === 'color') {
                                tariff.tariff_color = data.value
                            }
                        }
                        return tariff
                    })
                    return group
                })    
            }

            const selectTariffForView = (tariff_id) => {
                const tariff_object = tariffGroups.value.map(group => {
                    return group.tariffs.find(tariff => tariff.tariff_id === tariff_id)
                }).filter(tariff => tariff !== undefined)[0]
                emit('selectTariffForView', tariff_object)
            }

            const saveTariffs = async () => {

                const groups = tariffGroups.value.map(group => {
                    return {
                        group_id: group.group_id,
                        group_name: group.group_name,
                        group_max_clients: group.group_max_clients
                    }
                })

                let tariffs = []

                tariffGroups.value.forEach(group => {
                    tariffs = tariffs.concat(group.tariffs.map(tariff => {
                        return {
                            tariff_id: tariff.tariff_id,
                            tariff_name: tariff.tariff_name,
                            tariff_training_count: tariff.tariff_training_count,
                            tariff_days: tariff.tariff_days,
                            tariff_price: tariff.tariff_price,
                            tariff_description: tariff.tariff_description,
                            tariff_color: tariff.tariff_color,
                            tarrif_group_id: group.group_id,
                            tarrif_group_name: group.group_name
                        }
                    }))
                })

                const newGroups = groups.length == 0 ? groups : groups.filter(group => {
                    console.log('group ------ ', group)
                    return !oldTariffGroupsVC.value.some(oldGroup => oldGroup.group_id === group.group_id)
                })

                const newTariffs = tariffs.length == 0 ? tariffs : tariffs.filter(tariff => {
                    return !oldTariffsVC.value.some(oldTariff => oldTariff.tariff_id === tariff.tariff_id)
                })

                const updatedGroups = groups.filter(group => {
                    return oldTariffGroupsVC.value.some(oldGroup => oldGroup.group_id === group.group_id)
                })

                const updatedTariffs = tariffs.filter(tariff => {
                    return oldTariffsVC.value.some(oldTariff => oldTariff.tariff_id === tariff.tariff_id)
                })

                const deletedGroups = oldTariffGroupsVC.value.filter(oldGroup => {
                    return !groups.some(group => group.group_id === oldGroup.group_id)
                })

                const deletedTariffs = oldTariffsVC.value.filter(oldTariff => {
                    return !tariffs.some(tariff => tariff.tariff_id === oldTariff.tariff_id)
                })


                for (let i = 0; i < newGroups.length; i++) {
                    await addTariffGroup(newGroups[i])
                }

                for (let i = 0; i < newTariffs.length; i++) {
                    await addNewTariff(newTariffs[i])
                }

                for (let i = 0; i < updatedGroups.length; i++) {
                    await updateTariffGroup(updatedGroups[i])
                }

                for (let i = 0; i < updatedTariffs.length; i++) {
                    console.log('updatedTariffs[i] ------ ', updatedTariffs[i])
                    await updateTariffRequest(updatedTariffs[i])
                }

                for (let i = 0; i < deletedGroups.length; i++) {
                    await deleteTariffGroup(deletedGroups[i].group_id)
                }

                for (let i = 0; i < deletedTariffs.length; i++) {
                    await deleteTariffRequest(deletedTariffs[i].tariff_id)
                }

                const data = await getAllTariffsAndGroups()

                oldTariffGroupsObjects.value = data.data.map(group => {
                    return {
                        group_id: group.group_id,
                        group_name: group.group_name,
                        group_max_clients: group.group_max_clients,
                        tariffs: group?.tariffs?.map(tariff => {
                            return {
                                tariff_id: tariff.tariff_id,
                                tariff_name: tariff.tariff_name,
                                tariff_training_count: tariff.tariff_training_count,
                                tariff_days: tariff.tariff_days,
                                tariff_price: tariff.tariff_price,
                                tariff_description: tariff.tariff_description,
                                tariff_color: tariff.tariff_color,
                                tarrif_group_name: group.group_name
                            }
                        }) ?? []
                    }
                })

                oldTariffGroupsVC.value = oldTariffGroupsObjects.value.map(group => {
                    return {
                        group_id: group.group_id,
                        group_name: group.group_name,
                        group_max_clients: group.group_max_clients
                    }
                })

                oldTariffsVC.value = []
                oldTariffGroupsObjects.value.forEach(group => {
                    oldTariffsVC.value = oldTariffsVC.value.concat(group.tariffs.map(tariff => {
                        return {
                            tariff_id: tariff.tariff_id,
                            tariff_name: tariff.tariff_name,
                            tariff_training_count: tariff.tariff_training_count,
                            tariff_days: tariff.tariff_days,
                            tariff_price: tariff.tariff_price,
                            tariff_description: tariff.tariff_description,
                            tariff_color: tariff.tariff_color,
                            tarrif_group_name: group.group_name
                        }
                    })
                    )

                })

                tariffGroups.value = Object.assign([], oldTariffGroupsObjects.value)

                alert('Зміни збережено')
            }

            const deleteTariff = (group_id, tariff_id) => {
                tariffGroups.value = tariffGroups.value.map(group => {
                    if (group.group_id === group_id) {
                        group.tariffs = group.tariffs.filter(tariff => tariff.tariff_id !== tariff_id)
                    }
                    return group
                })
            }

            const groupsVC = ref([])
            const tariffsVC = ref([])
            const oldTariffGroupsVC = ref([])
            const oldTariffsVC = ref([])

            onMounted(async () => {
                const data = await getAllTariffsAndGroups()
                
                console.log('group?.tariffs ------ ', data.data)
                oldTariffGroupsObjects.value = data.data.map(group => {
                    return {
                        group_id: group.group_id,
                        group_name: group.group_name,
                        group_max_clients: group.group_max_clients,
                        tariffs: group?.tariffs?.map(tariff => {
                            return {
                                tariff_id: tariff.tariff_id,
                                tariff_name: tariff.tariff_name,
                                tariff_training_count: tariff.tariff_training_count,
                                tariff_days: tariff.tariff_days,
                                tariff_price: tariff.tariff_price,
                                tariff_description: tariff.tariff_description,
                                tariff_color: tariff.tariff_color,
                                tarrif_group_name: group.group_name
                            }
                        }) ?? []
                    }
                })

                oldTariffGroupsVC.value = oldTariffGroupsObjects.value.map(group => {
                    return {
                        group_id: group.group_id,
                        group_name: group.group_name,
                        group_max_clients: group.group_max_clients
                    }
                })
                oldTariffsVC.value = []
                oldTariffGroupsObjects.value.forEach(group => {
                    oldTariffsVC.value = oldTariffsVC.value.concat(group.tariffs.map(tariff => {
                        return {
                            tariff_id: tariff.tariff_id,
                            tariff_name: tariff.tariff_name,
                            tariff_training_count: tariff.tariff_training_count,
                            tariff_days: tariff.tariff_days,
                            tariff_price: tariff.tariff_price,
                            tariff_description: tariff.tariff_description,
                            tariff_color: tariff.tariff_color,
                            tarrif_group_name: group.group_name
                        }
                    })
                    )
                })

                tariffGroups.value = Object.assign([], oldTariffGroupsObjects.value)
            })

            return {
                tariffGroups,
                newGroupName,
                updateTariff,
                selectTariffForView,
                saveTariffs,
                oldTariffGroupsObjects,
                deleteTariff
            }
        },

        components: {
            SubscriptionGroupRow,
            SubscriptionItemRow
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div class="bg-[#FFFFFF] z-30 h-[250px] rounded-[10px] p-3 absolute">
        <div v-if="loader" class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-gray-300 w-[230px] h-[230px] opacity-25 z-60"></div>
        <div v-if="loader" class="w-5 h-5 rounded-full animate-spin border-solid border-4 border-[#990D35] border-t-transparent absolute top-[50%] left-[50%] -mt-5 -ml-[10px] z-50"></div>
		<cropper
            class="w-[226px] h-[200px] -z-30"
			ref="cropper"
			:src="image.src"
            :stencil-props="{
                aspectRatio: 1/1
            }"
		/>
        <button 
            class="px-2 py-1 bg-[#990D35] hover:bg-[#bf2c5f] text-white text-xs rounded-md w-full"
            @click="crop"
        >Зберегти</button>
	</div>
</template>

<script>
    import { ref } from 'vue'
    import { sendPhotoToRemoveBg } from '@/services/apiRequests'

    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css'

    export default {

        props: {
            image: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props, { emit }) {
            const cropper = ref(null)
            const loader = ref(false)

            const crop = () =>{
                loader.value = true
                const { canvas } = cropper.value.getResult();
                canvas.toBlob((blob) => {
                    sendPhotoToRemoveBg(blob).then(res => {
                        const blob_file = new Blob([res], {type: "image/png"})
                        
                        var reader = new FileReader()
                        reader.readAsDataURL(blob_file)
                        
                        reader.onloadend = () => {  
                            emit('processedImage', reader.result)
                            loader.value = false
                        }
                    })
                }, props.image.type);
            }

            return {
                crop,
                cropper,
                loader
            }
        },

        components: {
            Cropper,
        },

    }
</script>

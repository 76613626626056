<template>
    <div class="pr-[20px] w-[1200px] mx-auto">
        <div class="flex justify-end pr-[12px]">
            <div v-if="!loading" class="rounded-[5px] flex justify-between bg-green-emb font-semibold py-[2px] px-[20px] w-[228px]">
                <div class="text-[16px]">
                    Всі зміни збережено
                </div>
                <div>
                    ✓
                </div>
            </div>
            <div v-if="loading" class="rounded-[5px] flex justify-between bg-red-atten font-semibold py-[2px] px-[20px] w-[228px]">
                <div class="text-[16px]">
                    Збереження...
                </div>
            </div>  
        </div>
        <table class=" ml-[30px]">
            <thead>
                <tr class="text-gray-emb">
                    <th class="text-[14px] w-[4%] text-start">#</th>
                    <th class="text-[14px] w-[25%] text-start">Категорія</th>
                    <th class="text-[14px] w-[20%] text-start">Підкатегорія</th>
                    <th class="text-[14px] w-[15%] text-end">Сума за місяць</th>
                    <th class="text-[14px] w-[36%]"></th>
                </tr>
            </thead>
            <tbody>

                <CategoryBlock 
                    v-for="(category, index) in categories" 
                    :key="getNewKey()"
                    :category="category"
                    :listNumber="index + 1"
                    :subcategories="category.subcategories"

                    @add-subcategory="subcategoryName => addSubcategoryToCategory(category.id, subcategoryName)"
                    @delete-subcategory="subcategory => deleteSubcategoryFromCategory(category.id, subcategory)"
                    @update-subcategory="newSubcategory => updateSubcategoryName(newSubcategory.categoryId, newSubcategory.subcategoryId, newSubcategory.newName)"

                    @delete-category="deleteCategory(category.id)"
                    @updateCategoryName="newCategoryName => updateCategoryName(category.id, newCategoryName.newName)"

                    @start-loading="loading = true"
                    @end-loading="loading = false"
                />
                
                <tr class="text-gray-emb py-4 border-b-[1px] border-[#E1DAD8]">
                    <td class="w-[4%] text-start">{{ categories.length + 1 }}.</td>
                    <td class="w-[25%] py-2 text-center text-green-500 flex justify-start h-full items-center">
                        <input v-model="newCategory" type="text" class="text-[14px] bg-transparent text-gray-emb placeholder:text-gray-emb placeholder:opacity-40 relative outline-none" placeholder="Нова категорія...">
                    </td>
                    <td class="w-[20%]"></td>
                    <td class="w-[15%] text-end text-[#F10265]"></td>
                    <td class="w-[36%] h-full">
                        <div v-if="newCategory.length > 0" class="flex justify-end">
                            <img @click="addNewCategory" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                            <img @click="newCategory = ''" src="@/assets/icons/trash_icon.svg" class="cursor-pointer mr-[10px]">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { uuid } from 'vue3-uuid'
    import { ref, onMounted, computed } from 'vue'
    import { getAllOutlayCategoriesWithSubcategories, addOutlayCategory, deleteCategoryById, addOutlaySubcategory, deleteSubcategoryById, updateCategoryById, updateSubcategoryById } from '@/services/apiRequests'
    import CategoryElement from './components/CategoryElement.vue'
    import SubcategoryElement from './components/SubcategoryElement.vue'
    import CategoryBlock from './components/CategoryBlock.vue'

    export default {
        name: 'outlays-categories',

        setup() {
            const categories = ref([]) 
            const loading = ref(false)
            const newCategory = ref('')
            const newCategoryEdit = ref(false)

            const addSubcategoryToCategory = async (categoryId, subcategoryName) => {
                const category = categories.value.find(category => category.id === categoryId)
                const newSubcategoryId = uuid.v4()
                category.subcategories.push({
                    name: subcategoryName,
                    monthSum: 0,
                    id: newSubcategoryId
                })

                loading.value = true
                await addOutlaySubcategory(subcategoryName, categoryId, newSubcategoryId)
                loading.value = false
            }

            const deleteSubcategoryFromCategory = async (categoryId, subcategoryId) => {
                console.log(categoryId, subcategoryId)
                const category = categories.value.find(category => category.id == categoryId)
                const subcategoryIndex = category.subcategories.findIndex(sub => sub.id == subcategoryId)
                console.log(subcategoryIndex)
                category.subcategories.splice(subcategoryIndex, 1)

                loading.value = true
                await deleteSubcategoryById(subcategoryId)
                loading.value = false
            }

            const updateCategoryName = async (categoryId, newName) => {
                const category = categories.value.find(category => category.id == categoryId)
                category.name = newName

                loading.value = true
                await updateCategoryById(categoryId, newName)
                loading.value = false
            }

            const updateSubcategoryName = async (categoryId, subcategoryId, newSubcategory) => {
                const category = categories.value.find(category => category.id == categoryId)
                const subcategory = category.subcategories.find(sub => sub.id == subcategoryId)
                console.log(subcategoryId)
                subcategory.name = newSubcategory

                loading.value = true
                await updateSubcategoryById(subcategoryId, newSubcategory)
                loading.value = false
            }

            const addNewCategory = async () => {
                const newCategoryName = newCategory.value 
                const newCategoryId = uuid.v4()
                categories.value.push({
                    name: newCategory.value,
                    monthSum: 0,
                    id: newCategoryId,
                    subcategories: []
                })

                newCategory.value = ''

                loading.value = true
                await addOutlayCategory(newCategoryName, newCategoryId)
                loading.value = false
            }

            const deleteCategory = async (categoryId) => {
                const categoryIndex = categories.value.findIndex(category => category.id == categoryId)
                categories.value.splice(categoryIndex, 1)

                loading.value = true
                await deleteCategoryById(categoryId)
                loading.value = false
            }

            const getNewKey = () => {
                return uuid.v4()
            }

            const parseCategories = (allCategories) => {
                allCategories.forEach(category => {
                    let newCat = {
                        name: category.category.name,
                        monthSum: 0,
                        id: category.category.id,
                        subcategories: []
                    } 

                    category.subcategories.forEach(subcategory => {
                        newCat.subcategories.push({
                            name: subcategory.name,
                            monthSum: 0,
                            id: subcategory.id
                        })
                    })
                    categories.value.push(newCat)
                })
            }

            onMounted(async () => {
                const categoriesFromServer = await getAllOutlayCategoriesWithSubcategories()
                console.log(categoriesFromServer)
                parseCategories(categoriesFromServer)
            })

            return {
                categories,
                addSubcategoryToCategory,
                deleteSubcategoryFromCategory,
                newCategory,
                newCategoryEdit,
                addNewCategory,
                deleteCategory,
                loading,
                getNewKey,
                updateCategoryName,
                updateSubcategoryName
            }
        },

        components: {
            CategoryElement,
            SubcategoryElement,
            CategoryBlock
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <tr class="text-[#525A60] w-full h-[50px]">
        <td class="w-[11%] px-[2px] text-start py-1 text-[0.9rem]">
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] w-[16%] text-start py-1 text-[0.9rem]">
            <input :value="subscriptionName" @change="e => $emit('changeSubscriptionName', e.target.value)" class="w-full text-start text-[0.9rem] bg-transparent text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="назва абонементу">
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] w-[11%] px-[2px] text-center py-1 text-[0.9rem]">
            <input :value="subscriptionTrainingCount" @change="e => $emit('changeSubscriptionTrainingCount', e.target.value)" class="w-full text-center text-[0.9rem] bg-transparent text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] w-[11%] px-[15px] text-start py-1 text-[0.9rem]">
            <input :value="subscriptionDays" @change="e => $emit('changeSubscriptionDays', e.target.value)" class="w-full text-center text-[0.9rem] bg-transparent text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] w-[11%] px-[15px] text-start py-1 text-[0.9rem]">
            <input :value="subscriptionPrice" @change="e => $emit('changeSubscriptionPrice', e.target.value)" class="w-full text-center text-[0.9rem] bg-transparent text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] w-[11%] px-[15px] text-start py-1 text-[0.9rem]">
            <textarea :value="subscriptionDescription" @change="e => $emit('changeSubscriptionDescription', e.target.value)" class="w-full text-start text-[10px] bg-transparent text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="опис">
            </textarea>
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] w-[11%] h-full">
            <div class="flex justify-evenly">
                <div :class="{'bg-[#E6CCCC]' : subscriptionColor == '#E6CCCC'}" @click="$emit('changeColor', '#E6CCCC')" class="w-[16px] h-[16px] border-[5px] border-[#E6CCCC] rounded-full cursor-pointer"></div>
                <div :class="{'bg-[#C3DDDC]' : subscriptionColor == '#C3DDDC'}" @click="$emit('changeColor', '#C3DDDC')" class="w-[16px] h-[16px] border-[5px] border-[#C3DDDC] rounded-full cursor-pointer"></div>
                <div :class="{'bg-[#E6DDF0]' : subscriptionColor == '#E6DDF0'}" @click="$emit('changeColor', '#E6DDF0')" class="w-[16px] h-[16px] border-[5px] border-[#E6DDF0] rounded-full cursor-pointer"></div>
                <div :class="{'bg-[#F1EDEA]' : subscriptionColor == '#F1EDEA'}" @click="$emit('changeColor', '#F1EDEA')" class="w-[16px] h-[16px] border-[5px] border-[#F1EDEA] rounded-full cursor-pointer"></div>
            </div>
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] w-[11%] h-full">
            <div @click="$emit('selectTariffForView')" class="w-[24px] mx-auto cursor-pointer">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="#9A8C98" stroke-width="1.5"/>
                    <circle cx="16.5" cy="7.5" r="1.5" stroke="#9A8C98" stroke-width="1.5"/>
                    <path d="M2 14.1354C2.66663 14.0455 3.3406 14.0011 4.01569 14.0027C6.87163 13.9466 9.65761 14.7729 11.8765 16.3342C13.9345 17.7821 15.3805 19.7749 16 22" stroke="#9A8C98" stroke-width="1.5" stroke-linejoin="round"/>
                    <path d="M13 18C14.7015 16.6733 16.5345 15.9928 18.3862 16.0001C19.4362 15.999 20.4812 16.2216 21.5 16.6617" stroke="#9A8C98" stroke-width="1.5" stroke-linejoin="round"/>
                </svg>
            </div>
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] w-[6%] h-full ">
            <div @click="$emit('deleteTariff')" class="w-[24px] mx-auto cursor-pointer">
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5H3H19" stroke="#990D35" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M17 5V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5H17Z" stroke="#990D35" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8 10V16" stroke="#990D35" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 10V16" stroke="#990D35" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'SubscriptionItemRow',

        props: {
            subscriptionName: {
                type: String,
                default: ''
            },
            subscriptionTrainingCount: {
                type: Number,
                default: null
            },
            subscriptionDays: {
                type: Number,
                default: null
            },
            subscriptionPrice: {
                type: Number,
                default: null
            },
            subscriptionDescription: {
                type: String,
                default: null
            },
            subscriptionColor: {
                type: String,
                default: null
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <div>
            <div></div>
            <div></div>
        </div>

        <div class="flex ml-[150px]">
            <div class="w-[50%]">
                <div class="w-full flex justify-end px-[16px] mt-[20px]">
                    <div>
                        <select v-model="currentYear" class="bg-[#FFFFFF] border-[1px] border-[#525A60] rounded-[4px]">
                            <option v-for="year in yearsStatistic" :value="year">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div v-if="dataLoaded">
                    <div>
                        <div class="w-full text-end px-[22px] relative top-[20px] text-[9px] text-[#990D35]">тренувань</div>
                        <div class="w-full justify-center">
                            <MinLineChart :color="'#990D35'" :data="completed_trainings_count" />
                        </div>
                    </div>
                    <div>
                        <div class="w-full text-end px-[22px] relative top-[20px] text-[9px] text-green-emb">дохід</div>
                        <div class="w-full justify-center">
                            <MinBarChart class="mt-[20px] ml-[15px]" :data="earned_money" />
                        </div>
                    </div>
                    <div>
                        <div class="w-full text-end px-[22px] relative top-[20px] text-[9px] text-gray-emb">відвідуваність</div>
                        <div class="w-full justify-center">
                            <MinLineChart :color="'#4A4E69'" :data="clients_count" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-[6px]">
                <div class="w-full mt-[12px] flex justify-end px-[16px]">
                    <div>
                        <select v-model="currentMonthValue" class="bg-[#FFFFFF] border-[1px] border-[#525A60] rounded-[4px]">
                            <option v-for="(month, id) in monthesOptions" :value="id + 1">{{ month }}</option>
                        </select>
                    </div>
                </div>

                <div v-if="dataLoaded" class="flex justify-between w-full h-[95%]">
                    <div v-for="group in tarriffGroupsStatistics" class="flex mr-[10px] flex-col justify-between items-center">
                        <div>{{ group.group_name }}</div>
                        <PerCircle displayText="тренувань" :value="group.trainings_completed" :percent="countPercent(group.trainings_completed, sumTrainerTrainings)" progressBarColor="#990D35" />
                        <PerCircle  displayText="грн" :value="group.money_earned" :percent="countPercent(group.money_earned, sumTrainerMoney)" progressBarColor="#6BA292" />
                        <PerCircle displayText="клієнтів" :value="group.clients_count" :percent="countPercent(group.clients_count, sumTrainerClients)" progressBarColor="#4A4E69" />
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import PerCircle from './PerCircle.vue'
    import MinBarChart from './MinBarChart'
    import MinLineChart from './MinLineChart'

    import { ref, onMounted, watch } from 'vue'
    import { useRoute } from 'vue-router'

    import { getTrainerCompletedTrainingsByMonthes, getTrainerClientsByMonthes, getTrainerEarnedMoneyByMonthes, getTrainerStatisticsByTarriffGroups } from '@/services/apiRequests'

    export default {
        name: 'TrainerDashboard',

        setup() {
            const completed_trainings_count = ref([])
            const earned_money = ref([])
            const clients_count = ref([])

            const route = useRoute()
            const trainerId = route.params.trainer_id

            const dataLoaded = ref(false)

            const yearsStatistic = ref([])
            const currentYear = ref(new Date().getFullYear())
            const tarriffGroupsStatistics = ref([])

            const currentMonthValue = ref(new Date().getMonth() + 1)

            watch(currentYear, async (newValue) => {
                console.log(newValue)
                await updateChartStatistic(newValue)
            })

            watch(currentMonthValue, async (newValue) => {
                await updateDiagram(currentYear.value)
            })

            const generateMonthesOptions = () => {
                const monthes = ['Січ.', 'Лют.', 'Бер.', 'Кві.', 'Тра.', 'Чер.', 'Лип.', 'Сер.', 'Вер.', 'Жов.', 'Лис.', 'Гру.']
                const year = new Date().getFullYear()
                const currentMonth = new Date().getMonth()

                const monthesOptions = []

                for (let i = 0; i <= currentMonth; i++) {
                    monthesOptions.push(`${monthes[i]} ${year}`)
                }

                return monthesOptions
            }

            const monthesOptions = ref(generateMonthesOptions())

            const sumTrainerTrainings = ref(0)
            const sumTrainerMoney = ref(0)
            const sumTrainerClients = ref(0)

            const countPercent = (value, sum) => {
                return (value / sum) * 100
            }

            const updateDiagram = async (year) => {
                dataLoaded.value = false
                tarriffGroupsStatistics.value = (await getTrainerStatisticsByTarriffGroups(year, currentMonthValue.value, trainerId)).data

                sumTrainerClients.value = 0
                sumTrainerMoney.value = 0
                sumTrainerTrainings.value = 0

                sumTrainerClients.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.clients_count, 0)
                sumTrainerMoney.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.money_earned, 0)
                sumTrainerTrainings.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.trainings_completed, 0)

                dataLoaded.value = true
            }
            

            const updateChartStatistic = async (year) => {
                dataLoaded.value = false
                completed_trainings_count.value = (await getTrainerCompletedTrainingsByMonthes(year, trainerId)).data.map(item => item.trainings)
                earned_money.value = (await getTrainerEarnedMoneyByMonthes(year, trainerId)).data.map(item => item.money)
                clients_count.value = (await getTrainerClientsByMonthes(year, trainerId)).data.map(item => item.clients)

                dataLoaded.value = true
            }

            onMounted(async () => {
                completed_trainings_count.value = (await getTrainerCompletedTrainingsByMonthes(new Date().getFullYear(), trainerId)).data.map(item => item.trainings)
                earned_money.value = (await getTrainerEarnedMoneyByMonthes(new Date().getFullYear(), trainerId)).data.map(item => item.money)
                clients_count.value = (await getTrainerClientsByMonthes(new Date().getFullYear(), trainerId)).data.map(item => item.clients)

                tarriffGroupsStatistics.value = (await getTrainerStatisticsByTarriffGroups(currentYear.value, currentMonthValue.value, trainerId)).data

                sumTrainerClients.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.clients_count, 0)
                sumTrainerMoney.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.money_earned, 0)
                sumTrainerTrainings.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.trainings_completed, 0)

                for (let i = 0; i < 5; i++) {
                    yearsStatistic.value.push(currentYear.value - i)
                }

                dataLoaded.value = true
            })

            return {
                completed_trainings_count,
                earned_money,
                clients_count,
                dataLoaded,
                yearsStatistic,
                updateChartStatistic,
                currentYear,
                tarriffGroupsStatistics,
                sumTrainerClients,
                sumTrainerMoney,
                sumTrainerTrainings,
                countPercent,
                generateMonthesOptions,
                monthesOptions,
                currentMonthValue,
                updateDiagram
            }
        
        },

        components: {
            PerCircle,
            MinBarChart,
            MinLineChart
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div class="px-[30px]">
        <full-training-view 
            v-if="showFullTrainingFlag" 
            @updateTrainings="updateTrainings" 
            @close="showFullTrainingFlag = false; fullDayViewFlag = true"
            @deleteTraining="deleteTraining($event)" 
            :training="trainingToView"
        />
        <full-training-view 
            v-if="currentTraining" 
            @updateTrainings="updateTrainings" 
            @close="currentTraining = null"
            @deleteTraining="deleteTraining($event)" 
            :training="currentTraining"
        />
        <new-training-form 
            v-if="newTrainingFormFlag" 
            @updateTrainings="updateTrainings" 
            @close="newTrainingFormFlag = false" 
            :date="currentNewTrainingDate"
        />
        <full-day-view 
            v-if="fullDayViewFlag" 
            @setTrainingToView="training => { trainingToView = training;  showFullTrainingFlag = true; fullDayViewFlag = false}" 
            @addTraining="$event => {newTrainingFormFlag = true; currentNewTrainingDate = $event; updateTrainings()}" 
            @deleteTraining="$event => {fullDayViewDay.trainings = fullDayViewDay.trainings.filter(t => t.training_id !== $event); updateTrainings()}" 
            @close="fullDayViewFlag = false" :day="fullDayViewDay"
        />
        <div class="mt-[-22px] z-[100] absolute left-[50%] translate-x-[-50%] ml-[130px] top-[70px]">
            <div class="flex justify-center items-center select-none">
                <div>
                    <img @click="prevMonth" src="@/assets/icons/left_arrow.svg" class="cursor-pointer h-[14px]">
                </div>
                <div class="mx-2 w-[150px] text-center font-semibold text-[14px] text-[#525A60] relative top-[2px]">
                    {{ currentMonthParsed.toUpperCase() }} {{ currentYear }}
                </div>
                <div>
                    <img @click="nextMonth" src="@/assets/icons/right_arrow.svg" class="cursor-pointer h-[14px]">
                </div>
            </div>
        </div>

        <div v-if="copyTrainingFlag" @click="copyTrainingFlag = false" class="fixed w-full h-full backdrop-blur-md z-[9998]"></div>
        <div v-if="copyTrainingFlag" class="bg-white border-[1px] rounded-[10px] px-[10px] py-[20px] fixed top-[50%] left-[50%] translate-x-[-20%] translate-y-[-50%] z-[9999]">
            <div>Шаблонний діапазон:</div>
            <div>
                <input type="date" v-model="dateTemplateFrom" class="border border-gray-300 rounded-md px-2 py-1">
                <span> - </span>
                <input type="date" v-model="dateTemplateTo" class="border border-gray-300 rounded-md px-2 py-1">
            </div>
            <div class="mt-[20px]">Дата з якої копіювати діапазон:</div>
            <div>
                <input type="date" v-model="dateCopyStart" class="border border-gray-300 rounded-md px-2 py-1">
            </div>
            <div class="flex justify-center mt-[20px]">
                <button @click="processCopyTraining" class="bg-green-emb w-[228px] text-[#525A60] px-4 py-[6px] text-[14px] rounded-md">Копіювати тренування</button>
            </div>
        </div>
        
        <div 
            class="h-full overflow-x-auto"
            :class="{
                'w-full' : screenWidth > 1410,
                'w-[80vw]' : screenWidth <= 1410 && screenWidth > 1300,
                'w-[90vw]' : screenWidth <= 1300,
            }"    
        >
            <div class="w-[1190px] mx-auto h-[87vh] flex justify-center">
                <CalenarComponent 
                    v-if="isCalendarLoaded"
                    :days = "calendarParsedDays"
                    :unparsedDays = "calendarUnparsedDays"
                    @viewFull="viewFull($event)"
                    @addTraining="addTraining($event)"
                    class="w-[1190px] overflow-x-auto"
                />
                <div class="h-full relative">
                    <div v-if="!isCalendarLoaded" class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[9999]">
                        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-emb"></div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-[20px] pr-[70px]">
                <button @click="copyTrainingFlag = true" class="bg-green-emb w-[228px] text-[#525A60] px-4 py-[6px] text-[14px] rounded-md">Копіювати тренування</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {ref, onBeforeMount, watch } from 'vue'
    import { useRoute } from 'vue-router'
    import { getAllTrainings, countClientsTrainingById, deleteTrainingById, copyTrainingsToWeek } from '@/services/apiRequests'
    import CalenarComponent from './components/CalenarComponent.vue'
    import NewTrainingForm from './components/NewTrainingForm.vue'
    import FullDayView from './components/FullDayView.vue'
    import FullTrainingView from './components/FullTrainingView.vue'

    export default {
        name: 'calendar',

        setup() {
            const currentDate = new Date()
            const currentYear = ref(currentDate.getFullYear())
            const currentMonth = ref(currentDate.getMonth())
            const currentMonthParsed = ref(currentDate.toLocaleString('uk-UA', { month: 'long' }))
            const calendarParsedDays = ref([])
            const calendarUnparsedDays = ref([])
            const newTrainingFormFlag = ref(false)
            const currentNewTrainingDate = ref(null)
            const allTrainings = ref([])
            const trainingToView = ref({})
            const showFullTrainingFlag = ref(false)

            const route = useRoute()
            const currentTraining = ref(null)

            const fullDayViewFlag = ref(false)
            const fullDayViewDay = ref(null)

            const copyTrainingFlag = ref(false)

            const dateTemplateFrom = ref(null)
            const dateTemplateTo = ref(null)
            const dateCopyStart = ref(null)

            const isCalendarLoaded = ref(false)

            const processCopyTraining = async () => {
                const response = await copyTrainingsToWeek(
                    new Date(dateTemplateFrom.value), 
                    new Date(dateTemplateTo.value), 
                    new Date(dateCopyStart.value))

                if (response.status === 200) {
                    await updateTrainings()
                }
                copyTrainingFlag.value = false
            }

            const viewFull = (day) => {
                fullDayViewFlag.value = true
                fullDayViewDay.value = day
            }

            const deleteTraining = async (training) => {
                const response = await deleteTrainingById(training.training_id)
                if (response.status === 200) {
                    await updateTrainings()
                }
                showFullTrainingFlag.value = false
            }

            const getWeekDayId = (dayName) => {
                switch (dayName) {
                    case 'пн':
                        return 1
                    case 'вт':
                        return 2
                    case 'ср':
                        return 3
                    case 'чт':
                        return 4
                    case 'пт':
                        return 5
                    case 'сб':
                        return 6
                    case 'нд':
                        return 7
                }
            }

            const getDays = (allTrainings) => {
                const lastDayInMonth = new Date(currentYear.value, currentMonth.value + 1, 0).getDate()
                
                const days = []

                for (let i = 1; i <= lastDayInMonth; i++) {
                    const currentDayDate = new Date(currentYear.value, currentMonth.value, i)
                    const dayName = currentDayDate.toLocaleString('uk-UA', { weekday: 'short' })

                    // console.log(allTrainings[0].date.split('T')[0])
                    // console.log(new Date(currentDayDate.setDate(currentDayDate.getDate() + 1)).toISOString().split('T')[0])
                    currentDayDate.setDate(currentDayDate.getDate() + 1)
                    days.push({
                        dayName: dayName,
                        weekDayId: getWeekDayId(dayName),
                        dayNumber: i,
                        dateObj: currentDayDate,
                        trainings: allTrainings?.filter(training => training.date.split('T')[0] === currentDayDate.toISOString().split('T')[0])
                    })
                }

                return days
            }

            const parseWeeksToCalendar = (allTrainings) => {
                const calendarDaysFull = new Array(42).fill(null)
                const currentMonthDays = getDays(allTrainings)
                let startMonthDay = 30 - (currentMonthDays[0].weekDayId - 2) 
                for (let i = 1; i <= 6; i++) {
                    for (let j = 1; j <= 7; j++) {
                        const currentDay = currentMonthDays[0]
                        if (currentDay?.weekDayId == j) {
                            calendarDaysFull[(i - 1) * 7 + j - 1] = currentDay
                            if (currentDay.dayNumber == new Date().getDate() && currentDay.dateObj.getMonth() == new Date().getMonth() && currentDay.dateObj.getFullYear() == new Date().getFullYear()) {
                                calendarDaysFull[(i - 1) * 7 + j - 1].is_current = true
                            } else {
                                calendarDaysFull[(i - 1) * 7 + j - 1].is_current = false
                            }
                            currentMonthDays.shift()
                            startMonthDay = 1
                        } else {
                            calendarDaysFull[(i - 1) * 7 + j - 1] = {
                                type: 'empty',
                                month_day: startMonthDay
                            }
                            startMonthDay++
                        }
                    }
                }

                return calendarDaysFull
            }

            const nextMonth = () => {
                if (currentMonth.value == 11) {
                    currentMonth.value = 0
                    currentYear.value++
                } else {
                    currentMonth.value++
                }
                currentMonthParsed.value = new Date(currentYear.value, currentMonth.value, 1).toLocaleString('uk-UA', { month: 'long' })
                calendarParsedDays.value = parseWeeksToCalendar(allTrainings.value)
                calendarUnparsedDays.value = getDays(allTrainings.value)
            }

            const prevMonth = () => {
                if (currentMonth.value == 0) {
                    currentMonth.value = 11
                    currentYear.value--
                } else {
                    currentMonth.value--
                }
                currentMonthParsed.value = new Date(currentYear.value, currentMonth.value, 1).toLocaleString('uk-UA', { month: 'long' })
                calendarParsedDays.value = parseWeeksToCalendar(allTrainings.value)
                calendarUnparsedDays.value = getDays(allTrainings.value)
            }

            const addTraining = (date) => {
                newTrainingFormFlag.value = true
                currentNewTrainingDate.value = date.dateObj
            }

            const updateTrainings = async () => {
                isCalendarLoaded.value = false
                const firstDayInCurrentMonth = new Date(currentYear.value, currentMonth.value, 0).toISOString()
                const lastDayInCurrentMonth = new Date(currentYear.value, currentMonth.value + 1, 2).toISOString()
                const trainingsRes = await getAllTrainings(firstDayInCurrentMonth, lastDayInCurrentMonth)
                allTrainings.value = []

                console.log('trainingsRes.data ---- ', trainingsRes.data)

                for (let i = 0; i < trainingsRes.data.length; i++) {
                    const training = trainingsRes.data[i]
                    // training.clients_count = (await countClientsTrainingById(training.training_id)).data
                    allTrainings.value.push(training)
                }

                calendarParsedDays.value = parseWeeksToCalendar(allTrainings.value)
                calendarUnparsedDays.value = getDays(allTrainings.value)

                fullDayViewDay.value?.trainings.forEach((training, id) => {
                    const currentTraining = trainingsRes.data.find(t => t.training_id === training.training_id)
                    if (currentTraining) {
                        fullDayViewDay.value.trainings[id] = currentTraining
                    }
                })

                trainingsRes.data.forEach((training, id) => {
                    if (training.date.split('T')[0] === fullDayViewDay.value?.dateObj.toISOString().split('T')[0] && !fullDayViewDay.value.trainings.find(t => t.training_id === training.training_id)) {
                        fullDayViewDay.value.trainings.push(training)
                    }
                })
                isCalendarLoaded.value = true
            }

            onBeforeMount(async () => {
                await updateTrainings()

                if (route.params.training_id) {
                    currentTraining.value = allTrainings.value.find(training => training.training_id === route.params.training_id)
                }
            })

            watch(currentMonth, async () => {
                console.log('currentMonth.value ---- ', currentMonth.value)
                await updateTrainings()
            })

            return {
                calendarParsedDays,
                currentMonthParsed,
                currentYear,
                nextMonth,
                prevMonth,
                calendarUnparsedDays,
                addTraining,
                newTrainingFormFlag,
                currentNewTrainingDate,
                updateTrainings,
                fullDayViewFlag,
                fullDayViewDay,
                viewFull,
                trainingToView,
                showFullTrainingFlag,
                deleteTraining,
                currentTraining,
                copyTrainingFlag,
                dateTemplateFrom,
                dateTemplateTo,
                dateCopyStart,
                processCopyTraining,
                isCalendarLoaded
            }
        },

        components: {
            CalenarComponent,
            NewTrainingForm,
            FullDayView,
            FullTrainingView
        }
    }
</script>

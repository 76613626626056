<template>
    <div v-if="isOptionsShow" @click="isOptionsShow = false" class="absolute w-full h-full top-0 left-0"></div>
    <div class="flex justify-between border-[1px] border-[#8C8C8C] py-1 px-2 rounded-[6px]">
        <div class="text-[14px] text-[#525A60]">Повторюваність тренування</div>
        <input @click="changeRepeate" type="checkbox" class="cursor-pointer">
    </div>
    <div 
        :class="{
            'opacity-70 cursor-not-allowed': isDisabled,
            'cursor-pointer': !isDisabled
        }" 
        class="border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 flex justify-between w-full mt-5 px-2 py-1.5 rounded-[6px] items-center"
        @click="isOptionsShow = !isOptionsShow"
    >
        <div class="text-[14px] text-[#525A60]">
            Додати повторення
        </div>
        <div class="mt-[5px]">
            <svg width="9" height="7.5" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 11L0.937822 0.5L13.0622 0.5L7 11Z" fill="#8C8C8C"/>
            </svg>
        </div>
    </div>
    <div v-if="isOptionsShow && !isDisabled" class="bg-[#FFF] z-[150] absolute w-[212px] ml-[4px]">
        <div v-for="day in everyCurrentDayOptions" class="flex text-[#000] justify-between px-2 py-1">
            {{ day.text }}
            <input type="checkbox" @change="updateOption(day)" :checked="day.selected" class="cursor-pointer">
        </div>
    </div>

</template>

<script>
    import { ref } from 'vue'

    export default {
        name: 'multi-select',

        setup(props, { emit }) {
            const isDisabled = ref(true)
            const isOptionsShow = ref(false)

            const everyCurrentDayOptions = ref([
                { value: 1, text: 'Кожен понеділок', selected: false },
                { value: 2, text: 'Кожен вівторок', selected: false },
                { value: 3, text: 'Кожну середу', selected: false },
                { value: 4, text: 'Кожен четвер', selected: false },
                { value: 5, text: 'Кожну п\'ятницю', selected: false },
                { value: 6, text: 'Кожну суботу', selected: false },
                { value: 7, text: 'Кожну неділю', selected: false }
            ])

            const updateOption = (dayOption) => {
                everyCurrentDayOptions.value.find((day) => {
                    return day.value === dayOption.value
                }).selected = !dayOption.selected
                emit('update', everyCurrentDayOptions.value)
            }

            const changeRepeate = () => {
                if(!isDisabled.value) {
                    emit('enable')
                } else {
                    emit('disable')
                }
                isDisabled.value = !isDisabled.value
            }

            return {
                isDisabled,
                everyCurrentDayOptions,
                isOptionsShow,
                updateOption,
                changeRepeate
            }
        }
    }
</script>

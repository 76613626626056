<template>
    <div class="search-block w-[90%] mx-auto">
        <div class="flex justify-between  mt-2">
            
            <div class="search-bar flex items-center h-[38px] w-[228px] pl-[10px]  border-[1px] border-rose-emb bg-opacity-30 px-[5px] rounded-[6px]">
                <img src="@/assets/icons/magnify_icon.svg" class="w-[16px] h-[16px] ml-[6px]">
                <input v-model="search" class="text-gray-emb bg-transparent bg-opacity-30 ml-[6px] text-[13px] rounded-[6px] px-1 placeholder:text-rose-emb outline-none w-[250px]" placeholder="пошук..." type="text">
            </div>
            <div class="filter">
                <button class="bg-green-emb hover:bg-[#6BA292d6] w-[228px] text-white px-4 py-[6px] text-[14px] rounded-md" @click="$emit('addTrainer')">Додати</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'

    export default {
        name: 'menu-bar',

        setup(props, { emit }) {
            const searchQuery = ref('')

            watch(searchQuery, (newValue) => {
                emit('search', newValue)
            })

            return {
                searchQuery
            }
        }
    }
</script>

<template>
    <div @click="$emit('close')" class="backdrop-blur-[7px] fixed w-full h-[100vh]"></div>
    <div class="absolute w-[470px] h-[585px] bg-white2 rounded-[22px] top-[50%] px-[44px] translate-y-[-50%] left-[50%] translate-x-[-50%] z-[999] ml-[20px]">
        <div class="text-[#525A60] border-b-[1px] border-[#525A60] text-center text-[20px] font-[800] pb-[9px] mt-[31px]">
            Пн. 28.02.2024
        </div>

        <div class="bg-[#9A8C98] mt-[28px] bg-opacity-30 flex items-center justify-between border-[#9A8C98] border-[1px] rounded-[20px] w-full px-[15px] py-[17px]">
            <div class="flex items-center">
                <div class="w-[35px] h-[35px] rounded-full overflow-hidden border-[1px] border-[#525A60]">
                    <img v-if="user.photo" :src="apiURL + 'static/' + user.photo">
                    <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ user.name[0] + user.surname[0] }}</div>
                </div>
                <div class="ml-[10px] text-[#525A60] font-bold">{{ user.name + ' ' + user.surname }}</div>
            </div>
            <div class="text-[#525A60] font-bold">{{ user.phone }}</div>
        </div>

        <div class="mt-[43px]">
            <div class="flex justify-between">
                <div class="w-[45%]">
                    <select v-model="selectedGroup" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option value="" disabled>виберіть групу тарифу</option>
                        <option v-for="group in allGroups" :value="group.group_id" >{{ group.group_name }}</option>
                    </select>
                </div>
                <div class="w-[45%]">
                    <select v-model="selectedTariffId" :key="tariffsKey" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option v-for="tariff in showTariffs" :value="tariff.tariff_id">{{ tariff.tariff_name }} ({{ tariff.tariff_training_count }} тренувань)</option>
                    </select>
                </div>
            </div>
            <div class="flex justify-between mt-[18px]">
                <div class="w-[45%]">
                    <select v-model="paymentType" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option value="cash">готівка</option>
                        <option value="terminal">термінал</option>
                    </select>
                </div>
                <div class="w-[45%]">
                    <input v-model="clientSumm" class="bg-[#9A8C98] px-[14px] py-[10px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full" placeholder="сума, яку дав клієнт" />
                </div>
            </div>
        </div>

        <div class="flex justify-center mt-[26px]">
            <TariffCard class="scale-75" :tariff="selectedTariff"/>
        </div>

        <div v-if="clientSumm - selectedTariff.tariff_price > 0" class="text-[16px] text-[#990D35] font-bold text-center ">
            видати здачу {{ clientSumm - selectedTariff.tariff_price }} грн
        </div>
        <div v-if="selectedTariff.tariff_price - clientSumm > 0" class="text-[16px] text-[#990D35] font-bold text-center ">
            не вистачає {{ selectedTariff.tariff_price - clientSumm }} грн
        </div>

        <div @click="receivePayment" class="w-[228px] flex justify-center mx-auto items-center rounded-[5px] mt-[10px] cursor-pointer font-bold h-[38px] bg-green-emb text-white">
            <div>Прийняти оплату</div>
        </div>
    </div>
</template>

<script>
    import { getAllTariffsAndGroups, addTariffIncome, getAdmin, getBalanceByQuery } from '@/services/apiRequests'
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import TariffCard from './TariffCard.vue'

    export default {
        name: 'GetPayment',

        props: {
            user: {
                type: Object,
                default: () => {}
            }
        },

        setup(props, { emit }) {
            const allGroups = ref([])
            const selectedGroup = ref(null)
            const showTariffs = ref([])
            const tariffsKey = ref(uuid.v4())
            const selectedTariff = ref({
                tariff_id: '1',
                tariff_name: 'Не вибрано',
                tariff_training_count: '0',
                tariff_days: '30',
                tariff_price: '0',
                tariff_description: 'Виберіть тариф',
                tariff_color: '#989898',
                tarrif_group_id: ''
            })
            const selectedTariffId = ref('')
            const paymentType = ref('cash')

            const apiURL = process.env.VUE_APP_API_URL

            const clientSumm = ref(null)

            watch(selectedGroup, (val) => {
                if (!val) return
                showTariffs.value = allGroups.value.find(group => group.group_id === val).tariffs
                selectedTariff.value = showTariffs.value[0]
                selectedTariffId.value = showTariffs.value[0].tariff_id
                tariffsKey.value = uuid.v4()
            })

            watch(selectedTariffId, (val) => {
                if (!val) return
                selectedTariff.value = showTariffs.value.find(tariff => tariff.tariff_id === val)
            })

            const receivePayment = async () => {
                const adminData = await getAdmin()
                
                const adminCashBalance = adminData.data.cash_balance_id
                const bankBalance = (await getBalanceByQuery('Bank')).balances[0].balance_id

                let currentBalance = ''

                if (paymentType.value === 'cash') {
                    currentBalance = adminCashBalance
                } else {
                    currentBalance = bankBalance
                }
                
                await addTariffIncome(
                        selectedTariff.value.tariff_price, 
                        'Оплата тарифу для клієнта ' + props.user.name + ' ' + props.user.surname + ' через ' + paymentType.value, 
                        currentBalance, 
                        props.user.student_id, 
                        props.user.name + ' ' + props.user.surname, 
                        selectedTariff.value.tariff_id, 
                        selectedTariff.value.tarrif_group_name + ` - ${selectedTariff.value.tariff_training_count}`, 
                        paymentType.value,
                        [],
                        adminData.data.admin_name + ' ' + adminData.data.admin_surname,
                        adminData.data.admin_id)
                emit('close')

                console.log(adminData.data)
            }

            onMounted(async () => {
                const data = await getAllTariffsAndGroups()

                allGroups.value = data.data.map(group => {
                    return {
                        group_id: group.group_id,
                        group_name: group.group_name,
                        group_max_clients: group.group_max_clients,
                        tariffs: group?.tariffs?.map(tariff => {
                            return {
                                tariff_id: tariff.tariff_id,
                                tariff_name: tariff.tariff_name,
                                tariff_training_count: tariff.tariff_training_count,
                                tariff_days: tariff.tariff_days,
                                tariff_price: tariff.tariff_price,
                                tariff_description: tariff.tariff_description,
                                tariff_color: tariff.tariff_color,
                                tarrif_group_id: tariff.tarrif_group_id,
                                tarrif_group_name: tariff.tarrif_group_name
                            }
                        }) ?? []
                    }
                })

                selectedGroup.value = allGroups.value[0].group_id
            })

            return {
                allGroups,
                selectedGroup,
                showTariffs,
                tariffsKey,
                selectedTariff,
                selectedTariffId,
                clientSumm,
                receivePayment,
                paymentType,
                apiURL
            }
        },

        components: {
            TariffCard
        }
    }
</script>

<style lang="scss" scoped>

</style>
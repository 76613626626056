<template>
    <!-- <div class="w-[100%] h-full bg-gray-300 opacity-30 absolute z-20"></div> -->
    <div @click="$emit('close')" class="w-[100%]  backdrop-blur-[7px] ml-[-10px] top-0 h-full absolute z-20"></div>
    <AddClientView v-if="addClientFlag" :training="training" @close="addClientFlag = false" @addClient="addBook" class="z-[999]"/>
    <FileView 
        class="absolute z-[150] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[130px]"
        :fileUrl="currentImage"
        @close="showImageViewer = false"
        @delete="additionalFiles.splice(additionalFiles.indexOf(currentImage), 1); showImageViewer = false"
        v-if="showImageViewer"
    />
    <TrainingAttendingView 
        v-if="showAttendingView"
        :trainingDate="training.date"
        :groupId="group.group_id"
        :startTime="training.start_time"
        @close="showAttendingView=false"
    />

    <div v-if="loadingSpinnerFlag" class="z-[150] fixed w-full h-full top-[50%] left-[50%] ml-[115px]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#990D35] border-t-transparent "></div>
    </div>
    <ExitConfirmForm 
        v-else-if="exitConfirmForm && !loadingSpinnerFlag"
        @doSave="sendData"
        @dontSave="$emit('close')"
        @returnToCurrent="exitConfirmForm = false"
        :alertText="'Бажаєте зберігти зміни в тренуванні?'"
        class="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[130px] z-50 border-[1px] border-[#252526] w-[254px]"
    />
    <!-- v-else-if="!exitConfirmForm && !loadingSpinnerFlag && !showAttendingView" -->
    <div v-if="!addClientFlag" class="absolute ml-[128px] w-[470px] pb-[30px] bg-white2 rounded-[22px] top-[50%] px-[44px] translate-y-[-50%] left-[50%] translate-x-[-50%] z-[1200]">
        <div class="text-[#525A60] border-b-[1px] border-[#525A60] text-center text-[20px] font-[800] pb-[9px] mt-[31px]">
            {{training.date.split('T')[0].split('-').reverse().join('.')}}
        </div>

        <div class="bg-[#9A8C98] mt-[28px] bg-opacity-30 flex items-center justify-between  border-[#9A8C98] border-[1px] rounded-[20px] w-full px-[15px] py-[17px]">
            <div class="flex">
                <div class="flex items-center">
                    <div class="w-[35px] h-[35px] rounded-full overflow-hidden border-[1px] border-[#525A60]">
                        <img v-if="selectedTrainer?.photo" :src="apiURL + 'static/' + selectedTrainer?.photo">
                        <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ selectedTrainer ? selectedTrainer?.name[0] + selectedTrainer?.surname[0] : '' }}</div>
                    </div>
                    <div>
                        <div class="ml-[10px] text-[#525A60] font-bold">{{ selectedTrainer ? selectedTrainer?.name + ' ' + selectedTrainer?.surname[0] + '.' : 'Не задано' }}</div>
                        <div class="flex ml-[10px]" v-if="trainingTime.start && trainingTime.end">
                            <div>{{trainingTime.start}}</div>
                            <div>></div>
                            <div>{{trainingTime.end}}</div>
                        </div>
                    </div>
                </div>
                <div class="ml-[14px]">
                    <div v-if="selectedGroup?.group_name" class="text-[#525A60] uppercase font-bold truncate">{{ training.superposition ? 'per/split' : selectedGroup?.group_name }}</div>
                    <div v-if="trainingType" class="text-[#525A60]">{{ parseTrainingType(trainingType) }}</div>
                </div>
                <div class="ml-[14px]" v-if="location?.name">
                    <div class="text-[#525A60] mt-[26px]">{{ location?.name }}</div>
                </div>
            </div>
            <div class="flex">
                <div v-if="selectedGroup?.group_max_clients" :class="[clientsOnTraining == selectedGroup?.group_max_clients ? 'text-[#990D35]' : 'text-[#6BA292]']">
                    {{clientsOnTraining}}/{{training.max_clients}}
                </div>
                <div>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="11" :fill="clientsOnTraining >= selectedGroup?.group_max_clients ? '#990D35' : '#6BA292'"/>
                    </svg>
                </div>
            </div>
        </div>
        <div v-if="(!changeFlag && clientsOnTraining < maxClientsValue) && training.status == 'planed'" @click="addClientFlag = true" class="w-[100%] flex justify-center mx-auto items-center rounded-[9px] mt-[20px] cursor-pointer font-bold h-[44px] bg-[#6BA292] text-white">
            <div>Додати клієнта</div>
        </div>

        <div v-if="!changeFlag" class="mt-[36px] h-[250px] overflow-y-auto whitespace-nowrap">
            <div v-if="clientsOnTrainingList.length" v-for="client in clientsOnTrainingList" class="flex mb-2">
                <div class="w-[70%] bg-[#9A8C984D] flex justify-between pr-[20px] text-[18px] py-[10px] pl-2 rounded-l-[10px] font-semibold">
                    <div>
                        {{ client.name + ' ' + client.surname }}
                    </div>
                    <!-- <div v-if="checkBirthday(student.birthday)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 11V15C4 18.2998 4 19.9497 5.02513 20.9749C6.05025 22 7.70017 22 11 22H13C16.2998 22 17.9497 22 18.9749 20.9749C20 19.9497 20 18.2998 20 15V11" stroke="#F9245A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 9C3 8.25231 3 7.87846 3.20096 7.6C3.33261 7.41758 3.52197 7.26609 3.75 7.16077C4.09808 7 4.56538 7 5.5 7H18.5C19.4346 7 19.9019 7 20.25 7.16077C20.478 7.26609 20.6674 7.41758 20.799 7.6C21 7.87846 21 8.25231 21 9C21 9.74769 21 10.1215 20.799 10.4C20.6674 10.5824 20.478 10.7339 20.25 10.8392C19.9019 11 19.4346 11 18.5 11H5.5C4.56538 11 4.09808 11 3.75 10.8392C3.52197 10.7339 3.33261 10.5824 3.20096 10.4C3 10.1215 3 9.74769 3 9Z" stroke="#F9245A" stroke-width="1.5" stroke-linejoin="round"/>
                            <path d="M6 3.78571C6 2.79949 6.79949 2 7.78571 2H8.14286C10.2731 2 12 3.7269 12 5.85714V7H9.21429C7.43908 7 6 5.56091 6 3.78571Z" stroke="#F9245A" stroke-width="1.5" stroke-linejoin="round"/>
                            <path d="M18 3.78571C18 2.79949 17.2005 2 16.2143 2H15.8571C13.7269 2 12 3.7269 12 5.85714V7H14.7857C16.5609 7 18 5.56091 18 3.78571Z" stroke="#F9245A" stroke-width="1.5" stroke-linejoin="round"/>
                            <path d="M12 11L12 22" stroke="#F9245A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div> -->
                </div>
                <div class="flex items-center bg-[#9A8C984D] pr-[20px]">
                    <img src="../../../assets/icons/trash_icon.svg" class="cursor-pointer" @click="deleteClientBook(client)" />
                </div>
                <div v-if="client.status == 'present'" @click="changeClientStatus(client)" class="w-[30%] cursor-pointer text-white font-semibold text-center align-middle flex justify-center items-center rounded-r-[10px] bg-[#6BA292]">
                    Присутній
                </div>
                <div v-else-if="client.status == 'missing' || client.status == 'planed'" @click="changeClientStatus(client)" class="w-[30%] cursor-pointer text-white font-semibold text-center align-middle flex justify-center items-center rounded-r-[10px] bg-[#990D35]">
                    Відсутній
                </div>
            </div>        

            <div v-else class="text-center text-gray-500">
                <div>Ще немає клієнтів</div>
            </div>
        </div>

        <div v-if="changeFlag" class="mt-[43px]">
            <div class="flex justify-between">
                <div class="w-[45%]">
                    <select :value="trainerId" @change="changeTrainer" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option value="" disabled>виберіть тренера</option>
                        <option v-for="trainer in allTrainers" :value="trainer.trainer_id">{{ trainer.name + ' ' + trainer.surname }}</option>
                    </select>
                </div>
                <div class="w-[50%] flex items-center justify-between">
                    <input v-model="trainingTime.start" type="time" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-[45%]" placeholder="11.00" />
                    <div>
                        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 11L4.50024 6.6247C4.73987 6.32516 4.85969 6.17539 4.85969 6C4.85969 5.82461 4.73987 5.67484 4.50024 5.37531L0.999999 1" stroke="#9A8C98" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                    </div>
                    <input v-model="trainingTime.end" type="time" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-[45%]" placeholder="12.00" />

                </div>
            </div>
            <div class="flex justify-between mt-[18px]">
                <div class="w-[45%]">
                    <select @change="e => setLocation(e)" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option value="" disabled>виберіть зал</option>
                        <option v-for="locationOption in locationVisibleOptions" :value="locationOption.location_id">{{ location.name }}</option>
                    </select>
                </div>
                <div class="w-[50%]">
                    <select v-model="trainingType" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option value="" disabled>вид тренування</option>
                        <option value="yoga">йога</option>
                        <option value="barre">барре</option>
                        <option value="pilates">пілатес</option>
                        <option value="straching">стречінг</option>
                    </select>
                </div>
            </div>
            <div class="flex justify-between mt-[18px]">
                <div class="w-[45%]">
                    <select v-model="selectedGroupId" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option value="" disabled>виберіть групу тарифу</option>
                        <option v-for="group in allGroups" :value="group.group_id" >{{ group.group_name }}</option>
                    </select>
                </div>
                <div class="w-[50%]">
                    <!-- <select v-model="selectedTariffId" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option v-for="tariff in showTariffs" :value="tariff.tariff_id">{{ tariff.tariff_name }} ({{ tariff.tariff_training_count }} тренувань)</option>
                    </select> -->
                    
                    <select v-model="maxClientsValue" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-full">
                        <option :value="0" disabled>макс. к-сть клієнтів</option>
                        <option v-for="val in 7" :value="val">{{ val }}</option>
                    </select>
                </div>
            </div>
        </div>


        <div v-if="!changeFlag" @click="changeFlag = true" class="w-[100%] flex justify-center mx-auto items-center rounded-[9px] mt-[20px] cursor-pointer font-bold h-[44px] bg-gray-emb text-white">
            <div>Редагувати</div>
        </div>
        <div v-if="changeFlag" @click="changeFlag = false" class="w-[100%] flex justify-center mx-auto items-center rounded-[9px] mt-[20px] cursor-pointer font-bold h-[44px] bg-gray-emb text-white">
            <div>Назад</div>
        </div>
        <div v-if="changeFlag" @click="sendData" class="w-[100%] flex justify-center mx-auto items-center rounded-[9px] mt-[20px] cursor-pointer font-bold h-[44px] bg-green-emb text-white">
            <div>Зберегти</div>
        </div>
        <div v-if="!changeFlag" @click="$emit('deleteTraining', training)" class="w-[100%] flex justify-center mx-auto items-center rounded-[9px] mt-[10px] cursor-pointer font-bold h-[44px] bg-[#990D35] text-white">
            <div>Видалити</div>
        </div>
    </div>
</template>

<script>
    import { ref, computed, reactive, watch, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, updateTraining, getGroupById, getLocationById, updateClientBookStatus, getClientsListByTrainingId, getAllTrainers, getAllTariffsAndGroups, addClientTrainingBook, deleteClientTrainingById } from '@/services/apiRequests'
    import ExitConfirmForm from '../ui/ExitConfirmForm.vue'
    import FileView from '../ui/FileView.vue'
    import TrainingAttendingView from './TrainingAttendingView.vue'
    import AddClientView from './AddClientView.vue'

    async function compressImageToBlobAsync(file, maxWidth, maxHeight, quality) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function () {
            const img = new Image();
            img.src = reader.result;
            img.onload = function () {
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
                }

                if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
                }

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Получаем Blob сжатого изображения
                canvas.toBlob(
                function (blob) {
                    if (blob) {
                    // Создаем новый объект File на основе Blob
                    const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
                    resolve(compressedFile);
                    } else {
                    reject(new Error('Ошибка при создании Blob'));
                    }
                },
                'image/jpeg',
                quality / 100
                );
            };

            img.onerror = function () {
                reject(new Error('Ошибка при загрузке изображения'));
            };
            };

            reader.onerror = function () {
            reject(new Error('Ошибка при чтении файла'));
            };

            reader.readAsDataURL(file);
        });
    }

    export default {
        name: 'full-training-view',

        props: {
            training: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.training.date)
            const settedYear = settedDate.getFullYear() > 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 > 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() - 1 > 10 ? settedDate.getDate() - 1 : `0${settedDate.getDate() - 1}`
            const changeDate = ref(`${settedYear}-${settedMonth}-${settedDay}`)

            const exitConfirmForm = ref(false)

            const group = ref(null)
            const groupText = ref(props.training.group_label)
            
            const location = ref(null)
            const locationText = ref(props.training.location_label)

            const groupOptions = ref([])
            const locationOptions = ref([])

            const trainingType = ref('none')
            
            const trainerId = ref('')
            const trainerLabel = ref('')
            const allTrainers = ref([])

            const apiURL = process.env.VUE_APP_API_URL

            const maxClientsValue = ref(0)

            const deleteClientBook = async (client) => {
                await deleteClientTrainingById(props.training.training_id, client.student_id)
                clientsOnTrainingList.value = clientsOnTrainingList.value.filter(cl => cl.student_id !== client.student_id)
                emit('updateTrainings')
                clientsOnTraining.value = clientsOnTrainingList.value.length
            }

            const selectedTrainer = computed(() => {
                return allTrainers.value.find(trainer => trainer.trainer_id === trainerId.value)
            })

            const changeClientStatus = async (client) => {
                if (client.status == 'present') {
                    client.status = 'missing'
                } else if (client.status == 'missing' || client.status == 'planed'){
                    client.status = 'present'
                }
                await updateClientBookStatus(
                    props.training.training_id,
                    client.student_id,
                    client.status
                )
            }

            const additionalFiles = ref([])

            const fileInput = ref(null)
            const newAdditionalFiles = ref([])

            const showImageViewer = ref(false)
            const showNewImageViewer = ref(false)

            const currentImage = ref(null)
            const newCurrentImage = ref(null)

            const description = ref('')

            const loadingSpinnerFlag = ref(false)

            const selectedGroupId = ref('')
            const selectedTariffId = ref('')
            const selectedGroup = ref(null)
            const selectedTariff = ref(null)

            const allGroups = ref([])
            const showTariffs = ref([])

            const clientsOnTraining = ref(0)
            const clientsOnTrainingList = ref([])

            const changeFlag = ref(false)
            const addClientFlag = ref(false)

            const bufferedTraining = reactive({
                group: null,
                location: null,
                trainingTime: {
                    start: null,
                    end: null
                },
                changeDate: null,
                trainingType: null,
                description: null,
                trainerId: null,
                trainerLabel: null,
                additionalFilesLength: null
            })

            const showAttendingView = ref(false)

            const openUrl = (url) => {
                window.open(url, '_blank')
            }
            
            const parseTrainingType = (type) => {
                switch (type) {
                    case 'yoga':
                        return 'йога'
                    case 'barre':
                        return 'барре'
                    case 'pilates_ref':
                        return 'пілатес(реф)'
                    case 'pilates_mat':
                        return 'пілатес(мат)'
                    case 'straching':
                        return 'стретчінг'
                }
            }

            const addBook = async (newBookObj) => {
                const newBook = {
                    training_id: props.training.training_id,
                    client_id: newBookObj.client.student_id,
                    status: 'planed',
                    date: props.training.date,
                    start_time: trainingTime.value.start,
                    end_time: trainingTime.value.end,

                    tariff_id: newBookObj.tariffs[0].tariff_id,
                    tariff_price: newBookObj.tariffs[0].tariff_price,
                    tariff_training_count: newBookObj.tariffs[0].tariff_training_count,

                    client_label: newBookObj.client.name + ' ' + newBookObj.client.surname,
                    client_photo: newBookObj.client.photo,
                    client_phone: newBookObj.client.phone
                } 

                await addClientTrainingBook(newBook)
                clientsOnTrainingList.value.push({
                    ...newBookObj.client,
                    status: 'planed'
                })
                emit('updateTrainings')
                clientsOnTraining.value = clientsOnTrainingList.value.length
                addClientFlag.value = false
            }

            watch(selectedGroupId, (newVal) => {
                if (props.training.superposition) {
                    selectedGroup.value = {
                        group_max_clients: 1,
                        group_name: 'per/split',
                    }
                    showTariffs.value = []
                    return
                }
                selectedGroup.value = allGroups.value.find(group => group.group_id === newVal)
                showTariffs.value = selectedGroup.value.tariffs
            })

            onBeforeMount(async () => {
                loadingSpinnerFlag.value = true
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data

                const currentGroupRes = await getGroupById(props.training.group_id)
                group.value = currentGroupRes.data

                const currentLocationRes = await getLocationById(props.training.location_id)
                location.value = currentLocationRes.data

                const resTrainers = await getAllTrainers()
                allTrainers.value = resTrainers.data

                const currentTrainer = allTrainers.value.find(trainer => trainer.trainer_id === props.training.trainer_id)
                trainerId.value = currentTrainer.trainer_id
                trainerLabel.value = currentTrainer.name + ' ' + currentTrainer.surname

                additionalFiles.value = props.training.additional_files ? props.training.additional_files : []

                trainingType.value = props.training.type
                description.value = props.training.description

                bufferedTraining.value = Object.assign({}, props.training)

                const groupsData = await getAllTariffsAndGroups()

                maxClientsValue.value = props.training.max_clients

                allGroups.value = groupsData.data.map(group => {
                    return {
                        group_id: group.group_id,
                        group_name: group.group_name,
                        group_max_clients: group.group_max_clients,
                        tariffs: group?.tariffs?.map(tariff => {
                            return {
                                tariff_id: tariff.tariff_id,
                                tariff_name: tariff.tariff_name,
                                tariff_training_count: tariff.tariff_training_count,
                                tariff_days: tariff.tariff_days,
                                tariff_price: tariff.tariff_price,
                                tariff_description: tariff.tariff_description,
                                tariff_color: tariff.tariff_color
                            }
                        }) ?? []
                    }
                })

                showTariffs.value = allGroups.value[0].tariffs

                selectedGroupId.value = props.training.tariff_group_id
                selectedTariffId.value = props.training.tariff_id

                selectedGroup.value = allGroups.value.find(group => group.group_id === selectedGroupId.value)
                selectedTariff.value = showTariffs.value.find(tariff => tariff.tariff_id === selectedTariffId.value)

                clientsOnTrainingList.value = (await getClientsListByTrainingId(props.training.training_id)).data
                console.log('clientsOnTrainingList.value ---------- ', clientsOnTrainingList.value)
                clientsOnTraining.value = clientsOnTrainingList.value.length

                loadingSpinnerFlag.value = false
            })

            const proccessExit = () => {
                if (bufferedTraining.group != group.value || bufferedTraining.location != location.value || bufferedTraining.trainingTime.start != trainingTime.value.start || bufferedTraining.trainingTime.end != trainingTime.value.end || bufferedTraining.changeDate != changeDate.value || bufferedTraining.trainingType != trainingType.value || bufferedTraining.description != description.value || bufferedTraining.trainerId != trainerId.value || bufferedTraining.trainerLabel != trainerLabel.value || bufferedTraining.additionalFilesLength != additionalFiles.value.length || newAdditionalFiles.value.length > 0) {
                    exitConfirmForm.value = true
                } else {
                    emit('close')
                }
            }

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeTrainer = (e) => {
                trainerId.value = e.target.value
                trainerLabel.value = e.target.options[e.target.selectedIndex].text
            }

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                location.value = locationOption
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;
            }

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: props.training.start_time,
                end: props.training.end_time
            })

            const uploadAdditionalPhoto = () => {
                fileInput.value.click()
            }

            const sendData = async () => {
                loadingSpinnerFlag.value = true
                const newAdditionalFilesArray = []

                await updateTraining(
                    {
                        training_id: props.training.training_id,
                        date: props.training.date,
                        start_time: trainingTime.value.start,
                        end_time: trainingTime.value.end,
                        location_id: location.value.location_id,
                        location_label: location.value.name,
                        status: props.training.status,
                        type: trainingType.value,
                        trainer_id: trainerId.value,
                        trainer_label: trainerLabel.value,
                        trainer_photo: selectedTrainer.value.photo,
                        tariff_group_id: selectedGroupId.value,
                        tariff_id: '',
                        tariff_name: '',
                        tariff_group_name: selectedGroup.value.group_name,
                        max_clients: maxClientsValue.value,
                    }
                )
                emit('updateTrainings')
                emit('close')
                loadingSpinnerFlag.value = false
            }            

            return {
                changeDate,
                trainingTime,
                group,
                location,
                groupOptions,
                locationOptions,
                locationOptionsShow,
                groupOptionsShow,
                groupVisibleOptions,
                locationVisibleOptions,
                groupText,
                locationText,
                changeGroupText,
                changeLocationText,
                setLocation,
                setGroup,
                sendData,
                trainingType,
                description,
                exitConfirmForm,
                loadingSpinnerFlag,
                allTrainers,
                trainerId,
                trainerLabel,
                additionalFiles,
                fileInput,
                showImageViewer,
                currentImage,
                newAdditionalFiles,
                showNewImageViewer,
                newCurrentImage,
                uploadAdditionalPhoto,
                changeTrainer,
                proccessExit,
                openUrl,
                showAttendingView,
                selectedGroupId,
                selectedTariffId,
                selectedGroup,
                selectedTariff,
                allGroups,
                showTariffs,
                selectedTrainer,
                parseTrainingType,
                clientsOnTraining,
                clientsOnTrainingList,
                changeFlag,
                addClientFlag,
                addBook,
                changeClientStatus,
                clientsOnTraining,
                deleteClientBook,
                apiURL,
                maxClientsValue
            }
        },

        components: {
            ExitConfirmForm,
            FileView,
            TrainingAttendingView,
            AddClientView
        }
    }
</script>

<style lang="scss">

/* Скрываем стандартную стрелку в поле ввода */
.custom-date-input::-webkit-calendar-picker-indicator {
    background-image: url('../../../assets/icons/white_calendar.svg');
    cursor: pointer;
}

.custom-time-input::-webkit-calendar-picker-indicator {
    background-image: url('../../../assets/icons/clock_icon.svg');
    cursor: pointer;
}


.overflow-y-auto::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
}

.overflow-y-auto::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: #525A60;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}

</style>

<template>
    <div>
        <div>
            <div class="w-full flex justify-between">
                <div class="font-semibold text-gray-emb">
                    ДОХОДИ ПО ДЖЕРЕЛАХ 
                </div>
                <div>
                    <select v-model="currentYearRef" name="years" class="outline-none border-[#A4A6B3] text-gray-emb border-[1px] bg-transparent text-[13px] flex items-center justify-start px-[5px] py-[0.6px] cursor-pointer rounded-[4px] w-[65px] text-center mr-[20px]">
                        <option v-for="year in formYears()" :value="year" class="bg-[#FFFFFF]">{{year}}</option>
                    </select>
                </div>
            </div>
            <LineChart v-if="incomesBySourcesChartFlag" class="w-max" :data="incomesBySourcesChart" :width="chartWidth" :height="chartHeight" :colors="colors"/>
            <div v-else class="placeholder-params relative">
                <div class="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <div class="text-[14px] text-gray-400">Немає даних</div>
                </div>
            </div>
            <div class="flex justify-between w-[65%] mt-[10px] mx-auto">
                <div class="flex items-center">
                    <input type="checkbox" class="mr-[5px]" v-model="allCheckbox">
                    <div class="w-5 h-2 bg-[#990D35] rounded-2xl"></div>
                    <div class="mx-2 text-[12px] text-gray-emb">загальний дохід</div>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" class="mr-[5px]" v-model="cashCheckbox">
                    <div class="w-5 h-2 bg-green-emb rounded-2xl"></div>
                    <div class="mx-2 text-[12px] text-gray-emb">готівка</div>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" class="mr-[5px]" v-model="cashlessCheckbox">
                    <div class="w-5 h-2 bg-[#525A60] rounded-2xl"></div>
                    <div class="mx-2 text-[12px] text-gray-emb">безготівка</div>
                </div>
                <div class="flex items-center">
                    <input type="checkbox" class="mr-[5px]" v-model="investCheckbox">
                    <div class="w-5 h-2 bg-[#C9ADA7] rounded-2xl"></div>
                    <div class="mx-2 text-[12px] text-gray-emb">інвестиції</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getYearIncomesBySources } from '@/services/apiRequests'
    import { ref, onMounted, watch, computed } from 'vue'
    import LineChart from '../LineChart.vue'

    export default {
        name: 'IncomesBySource',

        setup() {
            const allCheckbox = ref(true)
            const cashCheckbox = ref(true)
            const cashlessCheckbox = ref(true)
            const investCheckbox = ref(true)
            const screenWidth = ref(window.innerWidth)

            const chartWidth = ref(850)
            const chartHeight = ref(300)
            const chartWidthPx = ref(`${chartWidth.value}px`)
            const chartHeightPx = ref(`${chartHeight.value}px`)

            watch(screenWidth, () => {
                console.log('screenWidth.value ------- ', screenWidth.value)
                if (screenWidth.value < 1410) {
                    chartWidth.value = 600
                    chartHeight.value = 300
                } else {
                    chartWidth.value = 790
                    chartHeight.value = 300
                }
            })

            const colors = ref(['#990D35', '#6BA292', '#525A60', '#C9ADA7'])

            const incomesBySourcesChart = ref({
                    labels: ['Січ','Лют','Бер','Кві','Тра','Чер','Лип','Сер','Вер','Жов','Лис','Гру'],
                    datasets: [
                        {
                            label: 'all',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            label: 'cash',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            label: 'cashless',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            label: 'invest',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                    ],
                })
            const incomesBySourcesChartFlag = ref(false)
            const currentYearRef = ref(new Date().getFullYear())

            const formIncomesBySourcesChart = async (year, show = [false, false, false, false]) => {
                const data = await getYearIncomesBySources(year)
                console.log('incomes data -------- ', data)
                
                incomesBySourcesChart.value.datasets = [
                        {
                            label: 'all',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            label: 'cash',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            label: 'cashless',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            label: 'invest',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                    ]
                colors.value = ['#990D35', '#6BA292', '#525A60', '#C9ADA7']

                if(data.length == 0) {
                    return 
                }

                data.forEach((item) => {
                    const month = Number(item.date_formatted.split('.')[0])
                    const all = item.all_incomes
                    const cash = item.cash_incomes
                    const cashless = item.cashless_incomes
                    const invest = item.invest_incomes

                    incomesBySourcesChart.value.datasets[0].data[month - 1] = all
                    incomesBySourcesChart.value.datasets[1].data[month - 1] = cash
                    incomesBySourcesChart.value.datasets[2].data[month - 1] = cashless
                    incomesBySourcesChart.value.datasets[3].data[month - 1] = invest
                })

                let startLength = incomesBySourcesChart.value.datasets.length
                let startColorLength = colors.value.length
                show.forEach((item, index) => {
                    console.log('item ------ ', item)
                    console.log('index ------ ', index)
                    if (item) {
                        incomesBySourcesChart.value.datasets.splice(index - (startLength - incomesBySourcesChart.value.datasets.length), 1)
                        colors.value.splice(index - (startColorLength - colors.value.length), 1)
                    }
                })

                if (incomesBySourcesChart.value.max == 0) {
                    return
                }
                incomesBySourcesChartFlag.value = true
            }


            const formYears = () => {
                const currentYear = new Date().getFullYear()
                const years = []

                for (let i = 0; i < 5; i++) {
                    years.push(currentYear - i)
                }

                return years.reverse()
            }

            watch(currentYearRef, async (newValue) => {
                incomesBySourcesChartFlag.value = false
                await formIncomesBySourcesChart(newValue)
            })

            watch([allCheckbox, cashCheckbox, cashlessCheckbox, investCheckbox], () => {
                const show = [!allCheckbox.value, !cashCheckbox.value, !cashlessCheckbox.value, !investCheckbox.value]
                formIncomesBySourcesChart(currentYearRef.value, show)
            })

            onMounted(async () => {
                await formIncomesBySourcesChart(currentYearRef.value)

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                if (screenWidth.value < 1410) {
                    chartWidth.value = 600
                    chartHeight.value = 300
                } else {
                    chartWidth.value = 790
                    chartHeight.value = 300
                }
            })

            return {
                incomesBySourcesChart,
                incomesBySourcesChartFlag,
                formIncomesBySourcesChart,
                formYears,
                currentYearRef,
                allCheckbox,
                cashCheckbox,
                cashlessCheckbox,
                investCheckbox,
                colors,
                screenWidth,
                chartWidth,
                chartHeight,
            }
        },

        components: {
            LineChart
        }
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1410px) and (max-width: 8000px) {
        .placeholder-params {
            width: 850px;
            height: 300px;
        }
    }

    @media (min-width: 0px) and (max-width: 1410px) {
        .placeholder-params {
            width: 600px;
            height: 300px;
        }
    }
</style>
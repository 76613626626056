<template>
    <div class="bg-white2 border-rose-emb w-[400px] h-[305px] rounded-[10px] pt-[10px] mt-[15px] border-[1px]">
        <div class="bg-rose-emb text-gray-emb text-[13px] font-medium text-center rounded-[4px] mx-[14px] py-[1.2px]">
            ФІНАНСИ
        </div>

        <div class="px-[10px] mt-[16px] h-[250px] overflow-y-auto scrollbar w-[95%] mx-auto">
            <div v-for="income in allIncomes" class="flex justify-between text-gray-emb mb-[7px]">
                <div class="text-[11px] w-[18%] text-end pr-[16px]">{{ formateISOtoDateAndTime(income.date).split(' ')[0] }}</div>
                <div class="text-[11px] w-[12%] text-center pr-[16px]">{{ formateISOtoDateAndTime(income.date).split(' ')[1] }}</div>
                <div class="text-[11px] w-[15%] font-bold text-start">{{ income.tariff_name }}</div>
                <div class="text-[11px] w-[20%] text-end font-bold truncate pr-[16px]">{{ income.amount }} грн</div>
                <div class="text-[11px] w-[15%] text-start text-gray-emb">({{ parseIncomeOrigin(income.income_type) }})</div>
                <div class="text-[11px] w-[20%] text-start text-gray-emb flex justify-end">
                    <img @click="$emit('deleteIncome', income)" src="@/assets/icons/trash_icon.svg" class="cursor-pointer w-[20px]">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, onBeforeMount, watch } from 'vue'
    import { getAllTariffRolesRequest, getAllRealIncomeTariffs } from '@/services/apiRequests'
    import DeleteIncomeForm from './DeleteIncomeForm.vue'

    export default {
        name: 'new-finance-card',
        props: {
            date_active_to: {
                type: String,
                default: ''
            },
            tariff_role_id: {
                type: String,
                default: ''
            },
            studentId: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const nowDate = ref(new Date())
            const dateActiveTo = ref(new Date(props.date_active_to))

            const isEditDate = ref(false)
            const newDate = ref('')

            const allTariffRoles = ref([])

            const currentTarriffRole = ref(props.tariff_role_id)

            const allIncomes = ref([])

            const parseIncomeOrigin = (origin) => {
                if (origin == 'cash') {
                    return 'готівка'
                } else if (origin == 'terminal') {
                    return 'термінал'
                } else {
                    return 'ipay'
                }
            }

            const formateISOtoDateAndTime = (date) => {
                const dateObj = new Date(date)
                const year = dateObj.getFullYear()
                const month = dateObj.getMonth() + 1 <= 9 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1
                const day = dateObj.getDate() <= 9 ? `0${dateObj.getDate()}` : dateObj.getDate()
                const hours = dateObj.getHours() <= 9 ? `0${dateObj.getHours()}` : dateObj.getHours()
                const minutes = dateObj.getMinutes() <= 9 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes()
                const seconds = dateObj.getSeconds()
                return `${day}.${month}.${year} ${hours}:${minutes}`
            }

            const changeTariffRole = (e) => {
                currentTarriffRole.value = e.target.value
                emit('change-tariff-role', currentTarriffRole.value)
            }

            const changeActiveToDate = () => {
                dateActiveTo.value = new Date(newDate.value)
                emit('change-active-to-date', new Date(newDate.value).toISOString())
                isEditDate.value = false
            }

            onBeforeMount(async () => {

                const allIncomesRes = await getAllRealIncomeTariffs(props.studentId)
                console.log('allIncomesRes ----- ', allIncomesRes)
                allIncomes.value = allIncomesRes.transactions
            })

            return {
                nowDate,
                dateActiveTo,
                allTariffRoles,
                isEditDate,
                newDate,
                changeActiveToDate,
                currentTarriffRole,
                changeTariffRole,
                allIncomes,
                formateISOtoDateAndTime,
                parseIncomeOrigin
            }
        },

        components: {
            DeleteIncomeForm
        }
    }
</script>

<style lang="scss" scoped>

/* Скрываем стандартную стрелку в поле ввода */
.custom-date-input::-webkit-calendar-picker-indicator {
    background-image: none;
    cursor: pointer;
}

.scrollbar::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #4A4E694A4E69;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}

</style>
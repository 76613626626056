<template>
    <div @click="$emit('close')" class="backdrop-blur-[7px] fixed w-full h-[100vh] z-[999]"></div>
    <div :style="['border-color:' + tariff.tariff_color, 'background-color:' + tariff.tariff_color]" class="z-[1000] fixed overflow-hidden top-[50%] left-[50%] translate-x-[-30%] translate-y-[-50%] w-[350px] h-[144px] bg-[#FBFFF1] border-[2px] rounded-[26px]">
        <div class="flex justify-between w-full relative top-[10px]">
            <div class="w-[30%]">
                <div class="rounded-full bg-black-emb w-[50px] h-[50px] text-[32px] text-center text-white font-[900] mt-[11px] ml-[11px]">{{ tariff.tariff_training_count }}</div>
            </div>
            <div class="flex flex-col justify-center items-center text-center ml-auto mr-auto">
                <div class="text-center text-black-emb text-[20px] font-[800]">{{ tariff.tarrif_group_name }}</div>
                <div class="text-center text-black-emb text-[10px] font-[800] ">{{ tariff.tariff_name }}</div>
            </div>
            <div class="text-black-emb z-[1000] relative w-[30%] top-[13px] leading-[15px] text-[14px] text-end px-[11px]">
                <span class="text-[10px]">активний до</span><br>
                <b class="text-[14px]">12.03.2024</b>
            </div>
        </div>
        <div class="">
            <div class="text-black-emb z-[1000] relative top-[25px] flex justify-between pl-[20px] pr-[10px]">
                <div class="text-[11px] w-[50%]">{{ tariff.tariff_description }}</div>
                <div class="relative bottom-[7px] font-[900] text-end text-[32px]">{{ tariff.tariff_price }} грн</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TariffCard',

        props: {
            tariff: {
                type: Object,
                default: () => {}
            }
        }
    }
</script>

<style lang="scss" scoped>
    @function trim($number) {
        @if type-of($number)=='number' and not unitless($number) {
            @return $number / ($number * 0+1);
        }
        @return $number;
    }

    @function replace($string, $search, $replace: '') {
        $index: str-index($string, $search);
        @return if($index,
        str-slice($string, 1, $index - 1) + $replace + replace(str-slice($string, $index + str-length($search)), $search, $replace),
        $string);
    }

    @mixin super-rectangle ($width, $height, $color){
        $width: trim($width);
        $height: trim($height);
        $color: replace(''+$color+'', '#', '%23');
        $halfWidth: $width / 2;
        $halfHeight: $height / 2;
        background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3Cpath fill='"+$color+"' stroke='none' d='M 0,"+$halfHeight+" C 0,0 0,0 "+$halfWidth+",0 S "+$width+",0 "+$width+","+$halfHeight+" "+$width+","+$height+" "+$halfWidth+","+$height+" 0,"+$height+" 0,"+$halfHeight+"'%3E%3C/path%3E%3C/svg%3E");
        background-size: #{$width + 'px'} #{$height + 'px'};
        background-repeat: no-repeat;
    }
    //don't work in Firefox and Edge
    @mixin super-rectangle-gradient ($width, $height, $color1, $color2){
        $width: trim($width);
        $height: trim($height);
        $color1: replace(''+$color1+'', '#', '%23');
        $color2: replace(''+$color2+'', '#', '%23');
        $halfWidth: $width / 2;
        $halfHeight: $height / 2;
        background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3ClinearGradient gradientTransform='rotate(45)' id='linear-gradient'%3E%3Cstop offset='50%' stop-color='"+$color1+"' /%3E%3Cstop offset='100%' stop-color='"+$color2+"' /%3E%3C/linearGradient%3E%3Cpath fill='url(#linear-gradient)' stroke='none' d='M 0,"+$halfHeight+" C 0,0 0,0 "+$halfWidth+",0 S "+$width+",0 "+$width+","+$halfHeight+" "+$width+","+$height+" "+$halfWidth+","+$height+" 0,"+$height+" 0,"+$halfHeight+"'%3E%3C/path%3E%3C/svg%3E");
        background-size: #{$width + 'px'} #{$height + 'px'};
    background-repeat: no-repeat;
    }

    @mixin super-circle ($width, $height, $color){
        $width: trim($width);
        $height: trim($height);
        $color: replace(''+$color+'', '#', '%23');
        $halfWidth: $width / 2;
        $halfHeight: $height / 2;
        background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='shape-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3Cpath fill='"+$color+"' stroke='none' d='M 0,"+$halfHeight+" Q 0,0 "+$halfWidth+",0 T "+$width+","+$halfHeight+" "+$halfWidth+","+$height+" 0,"+$halfHeight+" '%3E%3C/path%3E%3C/svg%3E");
        background-size: #{$width + 'px'} #{$height + 'px'};
        background-repeat: no-repeat;
    }

    //clip-path dont'work in Chrome
    @mixin path ($width, $height, $color){
        background: $color;
        $width: trim($width);
        $height: trim($height);
        $color: replace(''+$color+'', '#', '%23');
        $halfWidth: $width / 2;
        $halfHeight: $height / 2;   
        clip-path: url("data:image/svg+xml;charset=US-ASCII,%3Csvg version='1.1' id='rectangle' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='"+$width+"' height='"+$height+"' viewBox='0 0 "+$width+" "+$height+"' enable-background='new 0 0 "+$width+" "+$height+"' xml:space='preserve'%3E%3Cdefs%3E%3CclipPath id='path'%3E%3Cpath  stroke='none' d='M 0,"+$halfHeight+" C 0,0 0,0 "+$halfWidth+",0 S "+$width+",0 "+$width+","+$halfHeight+" "+$width+","+$height+" "+$halfWidth+","+$height+" 0,"+$height+" 0,"+$halfHeight+"'%3E%3C/path%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E#path");
    }

    //don't work in Firefox and Edge
    @mixin shadow-gradient( $w, $h, $c){
    position: relative;
    &:before{
        @include super-rectangle-gradient( $w, $h, black, lightgray);
        position: absolute;
        width: $w;
        height: $h;
        z-index: -1;
        content: "";
        left: 5px;
        top: 5px;
        opacity: 0.2;
    }
    }
    @mixin shadow-plain( $w, $h, $c){
    position: relative;
    &:before{
        @include super-rectangle( $w, $h, black);
        position: absolute;
        width: $w;
        height: $h;
        z-index: -1;
        content: "";
        left: 5px;
        top: 5px;
        opacity: 0.2;
    }
    }



    .rectangle{
        $w: 350px;
        $h: 144px;
        $c: #FE5000;
        width: $w;
        height: $h;
        @include super-rectangle( $w, $h, $c);
    }
    .rectangle-shadow-plain{
        $w: 400px;
        $h: 400px;
        $c: #ED2F5B;
        width: $w;
        height: $h;
        @include super-rectangle( $w, $h, $c);
        @include shadow-plain( $w, $h, black);
    }

    .rectangle-shadow-gradient{
        $w: 400px;
        $h: 400px;
        $c: #ED2F5B;
        width: $w;
        height: $h;
        @include super-rectangle( $w, $h, $c);
        @include shadow-gradient( $w, $h, black);
    }

    .circle{
        $w: 400px;
        $h: 400px;
        $c: #ED2F5B;
        width: $w;
        height: $h;
        @include super-circle( $w, $h, $c);
    }
    .rectangle-gradient{
        $w: 400px;
        $h: 400px;
        $c1: #ED2F5B;
        $c2: #FE5000;
        width: $w;
        height: $h;
        @include super-rectangle-gradient( $w, $h, $c1, $c2);
    }

    .path{
        $w: 400px;
        $h: 400px;
        $c: #ED2F5B;
        width: $w;
        height: $h;
        @include path( $w, $h, $c);
    }

    .box{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .box div{
        margin: 0 0 40px;
    }
</style>
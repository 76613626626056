<!-- <MinLineChart class="mx-auto relative right-[12px]" :data="completed_trainings_count"/> -->
<template>
    <tr class="w-full text-gray-emb">
        <td class="flex justify-center items-center">
            <div class="w-[50px] h-[50px] mt-[10px] flex items-center rounded-full overflow-hidden border-gray-emb border-[1px]">
                <img v-if="trainer.photo" :src="apiURL + 'static/' + trainer.photo">
                <img v-else src="@/assets/student-photo-placeholder-tpbg.png" class="w-[37px] relative top-[2px] mx-auto">
            </div>
        </td>
        <td class="text-start pl-[5px] align-middle w-[15%] text-[15px]">{{ trainer.trainer_name }}</td>
        <td class="w-[30%]">
            <MinLineChart class="mx-auto relative right-[12px]" :data="trainer.completed_trainings"/>
        </td>
        <td class="w-[30%]">
            <MinBarChart class="mx-auto relative right-[12px]" :data="trainer.earned_money"/>
        </td>
    </tr>
</template>

<script>
    import { ref, computed, watch } from 'vue'
    import MinLineChart from '../MinLineChart.vue'
    import MinBarChart from '../MinBarChart.vue'

    export default {
        name: 'TrainerRow',

        props: {
            trainer: {
                type: Object,
                required: true
            }
        },

        setup(props) {
            const apiURL = ref(process.env.VUE_APP_API_URL)

            return {
                apiURL
            }
        },

        components: {
            MinLineChart,
            MinBarChart
        },
    }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="chart">
    <canvas ref="canvas" class="relative left-[10px]" :height="300" :width="850"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

export default {
  name: 'TwoLinesBarChart',
  props: {
      height: {
          type: Number,
          default: 200
      },
      width: {
          type: Number,
          default: 850
      },
      chartData: {
          type: Object
      }
  },
  setup(props) {
      const canvas = ref(null);
      const ctx = ref(null);
      const chartData = ref(null);

      watch([props.width, props.height], () => {
          updateChart()
      })

      const updateChart = () => {
          const monthLabels = ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру']
          const data = props.chartData
          // chartData
          if (!ctx.value) {
              return;
          }

          ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);

          const margin = 30;
          const chartWidth = canvas.value.width + 30;
          const chartHeight = canvas.value.height - margin;
          
          const incomesChartHeight = chartHeight * 0.15;
          const outlaysChartHeight = chartHeight * 0.15;
          const earningsChartHeight = chartHeight * 0.7;

          //bar chart
          const maxEarings = Math.max(...data.earnings);
          const barMaxHeight = earningsChartHeight - incomesChartHeight - margin;
          const barWidth = 36 //chartWidth / (data.earnings.length * 2);
          const spacing = 4;
          ctx.value.beginPath();
          data.earnings.forEach((bar, i) => {
              const buff = bar;

              const currentBarPercentFromFull = (100 * bar) / maxEarings;

              const currentBlockFullHeight = bar > 0 ? (barMaxHeight * currentBarPercentFromFull) / 100 : 1;

              ctx.value.fillStyle = '#525A60';

              ctx.value.fillRect(
                  (i * barWidth * 1.99) + spacing, 
                  (earningsChartHeight + incomesChartHeight - currentBlockFullHeight - margin) * 0.9,
                  barWidth,
                  currentBlockFullHeight * 0.9
              );

              ctx.value.fillStyle = '#525A60';
              ctx.value.font = '12px Arial';
              ctx.value.textAlign = 'center';
              let labelX = ((i * barWidth * 1.99) + spacing) + (barWidth / 2);
              let labelY = earningsChartHeight + incomesChartHeight - 18;
              let labelText = monthLabels[i];
              ctx.value.fillText(labelText, labelX, labelY - margin / 2);

              ctx.value.fillStyle = '#525A60';
              ctx.value.font = 'bold 12px Arial';
              ctx.value.textAlign = 'center';
              labelX = ((i * barWidth * 1.99) + spacing) + (barWidth / 2);
              labelY = ((earningsChartHeight + incomesChartHeight - currentBlockFullHeight - margin) * 0.9) - 5;
              labelText = buff.toLocaleString();
              ctx.value.fillText(labelText, labelX, labelY);
              
          });
          ctx.value.stroke();

          // incomes chart
          console.log(data.incomes)
          const maxIncome = Math.max(...data.incomes) * 1.3;
          ctx.value.strokeStyle = '#6BA292';
          ctx.value.beginPath();
          for (let j = 0; j < data.incomes.length; j++) {
              console.log(j)
              const dataPoint = data.incomes[j];
              const x = ((j * barWidth * 1.99) + spacing) + (barWidth / 2);
              const y = (margin + incomesChartHeight - (dataPoint / maxIncome) * incomesChartHeight) - 20;
              ctx.value.lineWidth = 4;
              if (j === 0) {
                  ctx.value.moveTo(x, y);
              } else {
                  ctx.value.lineTo(x, y);
              }
          }
          ctx.value.stroke();

          ctx.value.beginPath();
          for (let j = 0; j < data.incomes.length; j++) {
              const dataPoint = data.incomes[j];
              const x = ((j * barWidth * 1.99) + spacing) + (barWidth / 2);
              const y = (margin + incomesChartHeight - (dataPoint / maxIncome) * incomesChartHeight) - 20;
              ctx.value.moveTo(x, y);
              ctx.value.arc(x, y, 3, 0, 2 * Math.PI);
              // Add colored border
              ctx.value.strokeStyle = '#6BA292';
              ctx.value.lineWidth = 4;
              ctx.value.stroke();
              // Add white fill
              ctx.value.fillStyle = '#142552';
              ctx.value.fill();
              // Restore original stroke style
              ctx.value.strokeStyle = '#6BA292';
              ctx.value.lineWidth = 1;

              ctx.value.fillStyle = '#6BA292';
              ctx.value.font = 'bold 12px Arial';
              const labelX = x - (0.8 * dataPoint.toString().length);
              const labelY = y - 10;
              const labelText = dataPoint.toLocaleString();
              ctx.value.fillText(labelText, labelX, labelY);
          }

          // outlays chart
          console.log(data.outlays)
          const maxOutlay = Math.max(...data.outlays);
          ctx.value.strokeStyle = '#990D35';
          ctx.value.beginPath();
          for (let j = 0; j < data.outlays.length; j++) {
              console.log(j)
              const dataPoint = data.outlays[j];
              const x = ((j * barWidth * 1.99) + spacing) + (barWidth / 2);
              const y = (chartHeight - outlaysChartHeight + (dataPoint / maxOutlay) * outlaysChartHeight) + 20;
              ctx.value.lineWidth = 4;
              if (j === 0) {
                  ctx.value.moveTo(x, y);
              } else {
                  ctx.value.lineTo(x, y);
              }
          }
          ctx.value.stroke();

          ctx.value.beginPath();
          for (let j = 0; j < data.outlays.length; j++) {
              const dataPoint = data.outlays[j];
              const x = ((j * barWidth * 2) + spacing) + (barWidth / 2);
              const y = (chartHeight - outlaysChartHeight + (dataPoint / maxOutlay) * outlaysChartHeight) + 20;
              ctx.value.moveTo(x, y);
              ctx.value.arc(x, y, 3, 0, 2 * Math.PI);
              // Add colored border
              ctx.value.strokeStyle = '#990D35';
              ctx.value.lineWidth = 4;
              ctx.value.stroke();
              // Add white fill
              ctx.value.fillStyle = '#142552';
              ctx.value.fill();
              // Restore original stroke style
              ctx.value.strokeStyle = '#990D35';
              ctx.value.lineWidth = 1;

              ctx.value.fillStyle = '#990D35';
              ctx.value.font = 'bold 12px Arial';
              const labelX = x - (0.8 * dataPoint.toString().length);
              const labelY = y - 10;
              const labelText = dataPoint > 0 ? '-' + dataPoint.toLocaleString() : '' + dataPoint.toLocaleString();
              ctx.value.fillText(labelText, labelX, labelY);
          }

      };

      onMounted(() => {
          ctx.value = canvas.value.getContext('2d');
          updateChart();
      })


      return {
          canvas,
      };
  
  },
};
</script>


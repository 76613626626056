<template>
    <!-- <div @click="$emit('close')" style="background-color: rgba(152, 152, 152, .35)" class="backdrop-blur-[7px] fixed w-full h-[130%] top-[-70px]"></div>
    <div class="bg-[#FFFFFF] w-[300px] rounded-[10px] p-[14px] z-20 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] border-[1px] border-[#47484966]">
        <div class="text-[#525A60] font-medium text-[14px]">
            назва локації
        </div>
        <div class="text-[#525A60] mb-[14px] rounded-[6px] w-full py-[5px]">
            <input v-model="locationMV.name" type="text" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
        </div>
        <div class="text-[#525A60] font-medium text-[14px]">
            скорочена назва локації
        </div>
        <div class="text-[#525A60] mb-[14px] rounded-[6px] w-full py-[5px]">
            <input v-model="locationMV.small_name" type="text" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
        </div>
        <div class="text-[#525A60] font-medium text-[14px]">
            адреса локації
        </div>
        <div class="text-[#525A60] mb-[14px] rounded-[6px] w-full py-[5px]">
            <input v-model="locationMV.address" type="text" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
        </div>
        <div class="text-[#525A60] font-medium mb-[4px] text-[14px]">
            посилання на карту
        </div>
        <div class="mb-[14px]">
            <url-component v-model="locationMV.geo_url" placeholder="Гео-точка" />
        </div>
        <div class="text-[#525A60] font-medium text-[14px]">
            опис локації
        </div>
        <div class="text-[#525A60] rounded-[6px] w-full py-[5px]">
            <textarea v-model="locationMV.description" type="text" class="scrolltab w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" rows="5"></textarea>
        </div>
        <div class="text-[#525A60] text-center font-medium text-[14px]">
            фото локації
        </div>
        <div class="w-full mb-[14px] bg-[#989898] relative rounded-[10px] h-[120px]">
            <div class="absolute opacity-0 hover:opacity-100 top-0 right-0">
                <div class="flex bg-white p-2 rounded-bl-[10px] rounded-tr-[10px]">
                    <img @click="selectLocationPhoto" class="mr-[7px] w-[15px] cursor-pointer" src="@/assets/icons/upload_icon.svg">
                    <input class="hidden" type="file">
                    <img @click="deleteLocationPhoto" class="w-[15px] cursor-pointer" src="@/assets/icons/trash_icon.svg">
                </div>
            </div>
            <img v-if="locationPhoto" :src="locationPhotoDataUrl" class="w-full h-full object-cover rounded-[10px]">
        </div>
        <div>
            <button @click="updateLocationVM" class="bg-green-emb text-[#1D1D1D] text-center w-full rounded-md py-1 hover:bg-opacity-90">Змінити</button>
        </div>
    </div> -->
    <div @click="$emit('close')" class="backdrop-blur-[7px] fixed w-full h-[130%] top-[-70px]"></div>
    <div class="absolute w-[470px] h-[473px] bg-white2 rounded-[22px] top-[50%] px-[44px] translate-y-[-50%] left-[50%] translate-x-[-30%] z-[999] ml-[20px]">
        <div class="mt-[41px] bg-violet-emb rounded-[20px] bg-opacity-30 border-violet-emb border-[2px] flex justify-between w-[382px] h-[76px] items-center px-[23px]">
            <div class="flex items-center">
                <div class="mr-[12px]">
                    <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1555 28.1137C14.5774 28.6822 13.8045 29 13.0003 29C12.196 29 11.4232 28.6822 10.845 28.1137C5.55023 22.8764 -1.54539 17.0257 1.91493 8.53153C3.78589 3.93882 8.27705 1 13.0003 1C17.7235 1 22.2146 3.93882 24.0856 8.53153C27.5415 17.015 20.4634 22.8944 15.1555 28.1137Z" stroke="#22223B"/>
                        <path d="M18.333 13.1501C18.333 15.9116 16.0944 18.1501 13.333 18.1501C10.5716 18.1501 8.33301 15.9116 8.33301 13.1501C8.33301 10.3887 10.5716 8.15015 13.333 8.15015C16.0944 8.15015 18.333 10.3887 18.333 13.1501Z" stroke="#22223B"/>
                    </svg>
                </div>
                <div class="text-[14px] font-bold">
                    <div>{{locationMV.name ? locationMV.name : 'Не задано' }}</div>
                </div>
            </div>
            <div class="text-[12px]">{{locationMV.address ? locationMV.address : 'Не задано'}}</div>
        </div>

        <div class="mt-[36px]">
            <div class="mb-[17px]">
                <input v-model="locationMV.name" class="w-full h-[34px] bg-[#9A8C98] border-[#9A8C98] bg-opacity-30 border-[1px] rounded-[10px] px-[14px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="Вкажіть назву залу" />
            </div>
            <div class="mb-[17px]">
                <input v-model="locationMV.small_name" class="w-full h-[34px] bg-[#9A8C98] border-[#9A8C98] bg-opacity-30 border-[1px] rounded-[10px] px-[14px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="Скорочена назва (для календаря)" />
            </div>
            <div class="mb-[17px]">
                <input v-model="locationMV.address" class="w-full h-[34px] bg-[#9A8C98] border-[#9A8C98] bg-opacity-30 border-[1px] rounded-[10px] px-[14px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="Адреса локації" />
            </div>
            <div>
                <input v-model="locationMV.geo_url" class="w-full h-[34px] bg-[#9A8C98] border-[#9A8C98] bg-opacity-30 border-[1px] rounded-[10px] px-[14px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="Додати посилання на google-карту" />
            </div>
        </div>
        
        <button @click="updateLocationVM" class="w-full bg-green-emb text-gray-emb h-[44px] rounded-[9px] mt-[36px] font-bold">
            Зберегти
        </button>

    </div>
</template>

<script>
    import { ref } from 'vue'
    import UrlComponent from './UrlComponent.vue'
    import { updateLocation } from '@/services/apiRequests'

    export default {
        props: {
            location: {
                type: Object,
                default: () => ({})
            }
        },

        emits: ['close', 'updateLocations'],
        
        setup(props, { emit }) {
            const locationMV = ref(Object.assign({}, props.location))
            const locationPhoto = ref(props.location.photo)
            const locationPhotoDataUrl = ref(process.env.VUE_APP_API_URL + 'static/' + props.location.photo)

            const sendToMap = () => {
                const mapQuery = locationMV.address.split(' ').join('%20')
                window.open(`https://www.openstreetmap.org/search?query=${mapQuery}`, '_blank')
            }

            const selectLocationPhoto = () => {
                const input = document.querySelector('input[type="file"]')
                input.click()
                input.addEventListener('change', () => {
                    locationPhoto.value = input.files[0]
                    locationPhotoDataUrl.value = URL.createObjectURL(input.files[0])
                })
            }

            const deleteLocationPhoto = () => {
                locationPhoto.value = null
                locationPhotoDataUrl.value = null
            }

            const updateLocationVM = async () => {
                await updateLocation(
                    locationMV.value.location_id,
                    locationMV.value.name,
                    locationMV.value.small_name,
                    locationMV.value.address,
                    locationMV.value.description,
                    locationMV.value.geo_url,
                    typeof locationPhoto.value == 'string' ? 'selected' : locationPhoto.value 
                )
                emit('close')
                emit('updateLocations')
                console.log('update')
            }

            return {
                locationPhoto,
                locationPhotoDataUrl,
                sendToMap,
                selectLocationPhoto,
                deleteLocationPhoto,
                locationMV,
                updateLocationVM
            }
        },

        components: {
            UrlComponent
        }
    }
</script>

<style>

    .scrolltab::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: #ededed;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #a6a4a4;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }

</style>


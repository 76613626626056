<template>
    <div>
        <delete-trainer-form 
            v-if="deleteTrainerFormFlag"
            :trainer="deleteTrainerFormData"
            @close="deleteTrainerFormFlag = false"
            @acceptDeleteTrainer="deleteTrainerVM($event)" 
        />
        <new-trainer-form 
            v-if="newFormShow"
            @close="newFormShow = false"
            @updateTrainersList="updateTrainers"
        />
        <trainer-card 
            v-if="showTrainerCardFlag"
            :trainer="currentTrainer"
            @close="showTrainerCardFlag = false"
            @updateTrainers="updateTrainers"
        />

        <div class="w-[1285px] mx-auto">
            <menu-bar 
                @addTrainer="newFormShow = true"
                @search="searchInput = $event"
            />
            <div class="border-b-[1px] border-[#525A60] w-[90%] mx-auto flex justify-end mt-[16px] mb-[60px]">
                <div class="text-[#525A60]">
                    <!-- <span class="text-[28px] font-semibold">{{ allUsersCount }}</span> учнів -->
                </div>
                <div class="text-[#525A60] ml-[20px]">
                    <span class="text-[28px] font-semibold">{{ visibleTrainers.length }}</span> тренерів
                </div>
            </div>
            
            <div class="w-[90%] mx-auto mt-3 rounded-[10px] overflow-hidden">
                <table class="w-full">
                    <thead class="">
                        <tr class="text-[#525A60] border-b-[1px] border-[#E1DAD8]">
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]"></th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[15%]">Ім'я</th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[15%]">Телефон</th>
                            <th class="px-3 py-2 text-end font-medium text-sm w-[15%]"></th>
                            <th class="px-3 py-2 text-end font-medium text-sm w-[10%]"></th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[25%]">Статус</th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[15%]"></th>
                        </tr>
                    </thead>
                    <tbody v-if="visibleTrainers.length > 0">
                        <trainer-row
                            v-for="trainer in visibleTrainers" 
                            @openTrainerCard="chooseTrainer(trainer)"
                            @deleteTrainer="trainer => {deleteTrainerFormFlag = true; deleteTrainerFormData = trainer}"
                            @addCountStudents="addTrainerCount($event)"
                            :key="trainer.trainer_id" 
                            :trainer="trainer"                         
                        />
                    </tbody>
                </table>
            </div>
            <div class="w-full mt-[14px] flex items-center justify-center" v-if="visibleTrainers.length == 0">
                <p class="text-[#525A60] text-[16px]">Тренерів не знайдено</p>
            </div>  
        </div>

    </div>
</template>

<script>
    import { ref, onBeforeMount, watch } from 'vue'
    import { getAllTrainers, deleteTrainerRequest } from '@/services/apiRequests'

    import MenuBar from './components/MenuBar.vue'
    import NewTrainerForm from './components/NewTrainerForm.vue'
    import TrainerCard from './components/TrainerCard.vue'
    import TrainerRow from './components/TrainerRow.vue'
    import DeleteTrainerForm from './components/DeleteTrainerForm.vue'

    export default {
        name: 'trainers',

        setup() {
            const newFormShow = ref(false)
            const allTrainers = ref([])
            const visibleTrainers = ref([])
            const apiURL = ref(process.env.VUE_APP_API_URL)

            const showTrainerCardFlag  = ref(false)
            const currentTrainer = ref({})
            
            const deleteTrainerFormFlag = ref(false)
            const deleteTrainerFormData = ref({})

            const searchInput = ref('')
            const allUsersCount = ref(0)

            watch(searchInput, (val) => {
                if (val === '') {
                    visibleTrainers.value = allTrainers.value
                } else {
                    visibleTrainers.value = allTrainers.value.filter(trainer => {
                        return trainer.name.toLowerCase().includes(val.toLowerCase()) || trainer.surname.toLowerCase().includes(val.toLowerCase())
                    })
                }
            })

            const updateTrainers = () => {
                getAllTrainers().then(res => {
                    allTrainers.value = res.data
                    visibleTrainers.value = res.data
                })
            }

            const addTrainerCount = (students_count) => {
                allUsersCount.value += students_count
            }

            const deleteTrainerVM = (trainer) => {
                deleteTrainerFormFlag.value = false
                deleteTrainerFormData.value = {}
                deleteTrainerRequest(trainer.trainer_id).then(res => {
                    updateTrainers()
                })
            }

            onBeforeMount(() => {
                updateTrainers()
            })

            const chooseTrainer = (trainer) => {
                showTrainerCardFlag.value = true
                currentTrainer.value = trainer
                
            }

            return {
                newFormShow,
                allTrainers, 
                apiURL,
                showTrainerCardFlag,
                currentTrainer,
                chooseTrainer,
                updateTrainers,
                deleteTrainerFormFlag,
                deleteTrainerFormData,
                deleteTrainerVM,
                searchInput,
                visibleTrainers,
                addTrainerCount,
                allUsersCount
            }
        },

        components: {
            MenuBar,
            NewTrainerForm,
            TrainerCard,
            TrainerRow,
            DeleteTrainerForm
        }
    }
</script>
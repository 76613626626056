<template>
    <div v-if="show">
        <div @click="showExitConfirmForm = true" class="backdrop-blur-[7px] fixed w-full h-full"></div>
        <exit-confirm-form
            v-if="showExitConfirmForm"
            :alertText="'Бажаєте зберегти картку учня ' + new_student.name + ' ' + new_student.surname + '?'"
            class="absolute top-[50%] ml-[110px] w-[260px] left-[50%] translate-x-[-50%] translate-y-[-50%]"

            @doSave="sendNewStudentData"
            @dontSave="$emit('close'); showExitConfirmForm = false"
            @returnToCurrent="showExitConfirmForm = false"
        />
        <div v-if="!showExitConfirmForm" class="student-card absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] ml-[110px] flex">
            <image-cropper 
                v-if="showImageCropper"
                :image="imageToCrop"
                @processedImage="processImage"
            />
            <new-info-card 
                @loadPhoto="loadImage"
                :studentPhoto = "studentPhoto"
                v-model:name="new_student.name"
                v-model:surname="new_student.surname"
                v-model:patronymic="new_student.patronymic"
                v-model:birthDate="new_student.birthday"
                v-model:phoneNumber="new_student.phone"
                v-model:email="new_student.email"
                v-model:group="new_student.group_name"
                v-model:nationality="new_student.nationality"
                v-model:password="new_student.password"
                :studentNumber="new_student.player_number"
                :studentAmplua="new_student.amplua"
                class="border-[1px] border-[#252526]"
            />

        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { useRouter } from 'vue-router'

    import { addNewStudent } from '@/services/apiRequests'

    import InfoCard from './StudentCard/card-components/InfoCard.vue'
    import DocumentaryCard from './StudentCard/card-components/DocumentaryCard.vue'
    import ParametersCard from './StudentCard/card-components/ParametersCard.vue'

    import NewInfoCard from "./StudentCard/card-components/NewInfoCard.vue"
    import NewParametersCard from "./StudentCard/card-components/NewParametersCard.vue"
    import NewDocumentsCard from "./StudentCard/card-components/NewDocumentsCard.vue"
    import ExitConfirmForm from './StudentCard/ui/ExitConfirmForm.vue'
    import ImageCropper from './StudentCard/ui/ImageCropper.vue'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'new-student-form',

        props: {
            show: {
                type: Boolean,
                required: true
            }
        },

        setup(props, {emit}) {
            const new_student = ref({
                name: '',
                surname: '',
                patronymic: '',
                name_eng: '',
                surname_eng: '',
                passport: '',
                passport_end_date: '',
                id_number: '',
                id_photos: [],
                birthday: '',
                height: 0,
                weight: 0,
                foot_size: 0,
                leading_foot: '',
                breast_volume: 0,
                start_training: '',
                group_id: '',
                group_name: '',
                amplua: '',
                player_number: 0,
                password: '',
                login: '',
                email: '',
                phone: '',
                nationality: 'UKR',
                status: ['active'],
                passport_photos: [],
                attorney_files: [],
                photo_for_passport: [],
                patrons: [
                    {
                        name: '',
                        phone_number: ''
                    },
                    {
                        name: '',
                        phone_number: ''
                    }
                ],
                date_active_to: new Date().toISOString(),
                last_tariff_id: '',
                tariff_role_id: '',
                prepaid_balance: 0
            }) 
            const showImageCropper = ref(false)
            const imageToCrop = ref({
                src: '',
                type: ''
            })
            const studentPhoto = ref(null)
            const showExitConfirmForm = ref(false)

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            const addContact = () => {
                new_student.value.patrons.push({
                    name: '',
                    phone_number: ''
                })
            }

            const deletePhoto = (currentPhoto, currentPhotoType) => {
                console.log(currentPhoto, currentPhotoType)
                if (currentPhotoType === 'passport') {
                    new_student.value.passport_photos = new_student.value.passport_photos.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'idNumber') {
                    new_student.value.id_photos = new_student.value.id_photos.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'attorney') {
                    new_student.value.attorney_files = new_student.value.attorney_files.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'photo_for_passport') {
                    new_student.value.photo_for_passport = new_student.value.photo_for_passport.filter((photo) => photo !== currentPhoto)
                }
            }

            const processImage = (image) => {
                studentPhoto.value = image
                showImageCropper.value = false
            }

            const sendNewStudentData = () => {
                new_student.value.login = new_student.value.phone
                addNewStudent(new_student.value, studentPhoto.value)
                    .then((response) => {
                        emit('close')
                        emit('updateStudents')
                        showExitConfirmForm.value = false
                        new_student.value = {
                            name: '',
                            surname: '',
                            patronymic: '',
                            name_eng: '',
                            surname_eng: '',
                            passport: '',
                            passport_end_date: '',
                            id_number: '',
                            id_photos: [],
                            birthday: '',
                            height: 0,
                            weight: 0,
                            foot_size: 0,
                            leading_foot: '',
                            breast_volume: 0,
                            start_training: '',
                            group_id: '',
                            group_name: '',
                            amplua: '',
                            player_number: 0,
                            password: '',
                            login: '',
                            email: '',
                            phone: '',
                            nationality: 'UKR',
                            status: ['active'],
                            passport_photos: [],
                            attorney_files: [],
                            photo_for_passport: [],
                            patrons: [
                                {
                                    name: '',
                                    phone_number: ''
                                },
                                {
                                    name: '',
                                    phone_number: ''
                                }
                            ],
                            date_active_to: new Date().toISOString(),
                            last_tariff_id: '',
                            tariff_role_id: '',
                            prepaid_balance: 0
                        }
                        studentPhoto.value = null
                    })
            }

            return {
                new_student,
                studentPhoto,
                addContact,
                loadImage,
                showImageCropper,
                imageToCrop,
                processImage,
                deletePhoto,
                sendNewStudentData,
                showExitConfirmForm
            }
        },

        components: {
            InfoCard,
            DocumentaryCard,
            ParametersCard,
            ImageCropper,
            NewInfoCard,
            NewParametersCard,
            NewDocumentsCard,
            ExitConfirmForm
        }
    }
</script>

<style lang="scss" scoped>

</style>
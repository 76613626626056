<template>
    <div class="border-[1px] border-[#2B2A29] w-full px-[21px] py-[17px] rounded-[10px] mt-[27px]">
        <div class="uppercase bg-[#525A60] text-white text-[13px] py-[4px] font-bold text-center rounded-[4px]">
            банківські реквізити
        </div>

        <div class="flex w-full justify-between">
            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#525A60] font-medium text-[14px]">
                        Назва банку
                    </div>
                    <div class="mt-[3px]">
                        <input 
                            :value="bankName"
                            @change="$emit('update:bankName', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]">
                    <div class="text-[#525A60] font-medium text-[14px]">
                        Код ЄДРПОУ
                    </div>
                    <div class="mt-[3px]">
                        <input
                            :value="EDRPOUcode"
                            @change="$emit('update:EDRPOUcode', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
            </div>

            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#525A60] font-medium text-[14px]">
                        Код отримувача
                    </div>
                    <div class="mt-[3px]">
                        <input 
                            :value="receiverCode"
                            @change="$emit('update:receiverCode', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]"></div>
            </div>

            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#525A60] font-medium text-[14px]">
                        Рахунок отримувача
                    </div>
                    <div class="mt-[3px]">
                        <input 
                            :value="receiverAccount"
                            @change="$emit('update:receiverAccount', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]">
                    <div class="text-[#525A60] font-medium text-[14px]">
                        Інеграція WayForPay
                    </div>
                    <div class="mt-[3px]">
                        <input
                            :value="wayforpayKey"
                            @change="$emit('update:wayforpayKey', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#525A60] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BankDetails',

        props: {
            bankName: {
                type: String,
                default: ''
            },
            EDRPOUcode: {
                type: String,
                default: ''
            },
            receiverCode: {
                type: String,
                default: ''
            },
            receiverAccount: {
                type: String,
                default: ''
            },
            wayforpayKey: {
                type: String,
                default: ''
            }
        },

        setup() {

        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div class="bg-white2 border-rose-emb relative w-[400px] h-[500px] rounded-[10px]  pt-[10px] border-[1px] ">
        <div class="bg-rose-emb text-gray-emb text-[13px] uppercase font-medium text-center rounded-[4px] mx-[14px] py-[1.2px]">
            статистика
        </div>
        

        <TrainingCalendar
            class="scale-[0.9] origin-top select-none"
            :planedTrainings="planedTrainings"
            :bookedTrainings="clientBookedTrainings"
            :missedTrainings="missedTrainings"
            :attendedTrainings="attendedTrainings"
            @planedTraining="t => showPlanedTrainings(t)"
            @emitAttendedTraining="t => showAttendedTrainings(t)"
            @emitMissedTraining="t => showMissedTrainings(t)"
        />

        <div class="flex absolute w-full bottom-[16px] justify-around pl-[28px] pr-[10px]">
            <div class="flex mx-auto w-full justify-between">
                <div class="h-[110px] w-[110px] relative">
                    <div class="absolute w-max">
                        <circle-progress :fillColor="'#C3DDDC'" :emptyColor="'#E6DDF0'" :size="110" :percent="percentTrainings" :viewport="false" :borderWidth="16" :borderBgWidth="16" />
                    </div>
                    <div class="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
                        <div class="text-gray-emb text-2xl font-bold"> {{percentTrainings}}%</div>
                    </div>
                </div>
                <div class="w-[150px] flex flex-col justify-end items-end mx-5 mt-[10px]">
                    <div class="flex items-center w-full justify-between">
                        <div class="text-gray-emb">тренувань</div>
                        <div class="bg-rose-emb text-gray-emb p-2 min-w-[5ch] text-center rounded-lg font-bold">{{ attendedTrainings.length + missedTrainings.length }}</div>
                    </div>
                    <div class="flex items-center w-full justify-between mt-3">
                        <div class="text-gray-emb mr-2">відвідувань</div>
                        <div class="bg-green-emb text-gray-emb p-2 min-w-[5ch] text-center rounded-lg font-bold">{{ attendedTrainings.length }}</div>
                    </div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    import { ref, computed, onMounted } from 'vue'

    import { getAllRealIncomeTariffs, getClientsTrainingByClientId, getAllClientTariffsByClientId, getTrainingsByTariffGroupId } from '@/services/apiRequests'

    import DataInput from '../ui/DataInput.vue'
    import ImageViewer from '../ui/ImageViewer.vue'
    import TrainingCalendar from './TrainingCalendar/TrainingCalendar.vue'
    import CircleProgress from "vue3-circle-progress"
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'documentary-card',

        props: {
            nameEng: {
                type: String,
                default: ''
            },
            surnameEng: {
                type: String,
                default: ''
            },
            passport: {
                type: String,
                default: ''
            },
            passportPhotoArray: {
                type: Array,
                default: []
            },
            idNumber: {
                type: String,
                default: ''
            },
            idNumberPhotoArray: {
                type: Array,
                default: []
            },
            passportEndDate: {
                type: String,
                default: ''
            },
            patronFirstName: {
                type: String,
                default: ''
            },
            patronFirstPhone: {
                type: String,
                default: ''
            },
            patronSecondName: {
                type: String,
                default: ''
            },
            patronSecondPhone: {
                type: String,
                default: ''
            },
            attorneyFileArray: {
                type: Array,
                default: []
            },
            photoForPassportArray: {
                type: Array,
                default: []
            },
            clientId: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const passportFilesInput = ref(null)
            const idNumberFilesInput = ref(null)
            const attorneyFilesInput = ref(null)
            const photoForPassportInput = ref(null)
            const currentImage = ref(null)
            const showImageViewer = ref(false)
            const currentPhotoType = ref(null)

            const allIncomes = ref([])

            const missedTrainings = ref([])
            const attendedTrainings = ref([])
            const planedTrainings = ref([])
            const clientBookedTrainings = ref([])

            const clientTariffs = ref([])

            const percentTrainings = computed(() => {
                const allTrainings = attendedTrainings.value.length + missedTrainings.value.length
                return allTrainings ? Math.round((attendedTrainings.value.length / allTrainings) * 100) : 0
            })

            const showPlanedTrainings = (trainings) => {
                console.log('planedTrainings', trainings)
            }

            const showAttendedTrainings = (trainings) => {
                console.log('attendedTrainings', trainings)
            }

            const showMissedTrainings = (trainings) => {
                console.log('missedTrainings', trainings)
            }

            onMounted(async () => {

                clientTariffs.value = (await getAllClientTariffsByClientId(props.clientId)).data

                const clientTariffsGroupIds = clientTariffs.value.map(tariff => tariff.tarrif_group_id)

                for (let i = 0; i < clientTariffsGroupIds.length; i++) {
                    const trainings = (await getTrainingsByTariffGroupId(clientTariffsGroupIds[i])).data
                    const trainingsWithClientsCount = trainings.map(async training => {
                        // training.clientsCount = (await countClientsTrainingById(training.training_id)).data
                        return training
                    })
                    planedTrainings.value.push(...trainings)
                }

                const allClientsBooks = (await getClientsTrainingByClientId(props.clientId)).data
                
                missedTrainings.value = allClientsBooks.filter(book => book.status === 'missing')
                attendedTrainings.value = allClientsBooks.filter(book => book.status === 'present')
                clientBookedTrainings.value = allClientsBooks.filter(book => book.status === 'planed')

                console.log('allClientsBooks', allClientsBooks)

                console.log('missedTrainings', missedTrainings.value)
                console.log('attendedTrainings', attendedTrainings.value)
                console.log('planedTrainings', planedTrainings.value)

            })

            const endPasspotDate = computed({
                get() {
                    if (!props.passportEndDate.includes('T'))
                        return props.passportEndDate
                    return formateDate(props.passportEndDate)
                },
                set(value) {
                    emit('update:passportEndDate', value)
                }
            })

            const uploadPassportPhoto = () => {
                passportFilesInput.value.click()
            }

            const uploadIdNumberPhoto = () => {
                idNumberFilesInput.value.click()
            }

            const uploadAttorneyPhoto = () => {
                attorneyFilesInput.value.click()
            }

            const uploadPhotoForPassport = () => {
                photoForPassportInput.value.click()
            }


            const setImageToView = (image, type) => {
                currentImage.value = image
                currentPhotoType.value = type
                showImageViewer.value = true
                console.log(currentPhotoType.value)
            }

            return {
                uploadPassportPhoto,
                passportFilesInput,
                currentImage,
                showImageViewer,
                setImageToView,
                uploadIdNumberPhoto,
                idNumberFilesInput,
                currentPhotoType,
                attorneyFilesInput,
                uploadAttorneyPhoto,
                endPasspotDate,
                photoForPassportInput,
                uploadPhotoForPassport,
                missedTrainings,
                attendedTrainings,
                planedTrainings,
                clientBookedTrainings,
                clientTariffs,
                percentTrainings,
                showPlanedTrainings,
                showAttendedTrainings,
                showMissedTrainings
            }
        },

        components: {
            DataInput,
            ImageViewer,
            TrainingCalendar,
            CircleProgress
        }
    }
</script>


<style scoped>


    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%234A4E69" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

</style>
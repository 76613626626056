<template>
    <div>
        <div @click="closeWindows" class="backdrop-blur-[7px] fixed w-full h-[130%] top-[-70px]"></div>
        <ExitConfirmForm 
            class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
            v-if="exitFlag"
            :alertText="'Зберегти зміни для ' + editedAdmin.admin_name + ' ' + editedAdmin.admin_surname + '?'"
            @dontSave="() => $emit('close')"
            @doSave="saveAdmin"
            @returnToCurrent="exitFlag = false"
        />
        <div v-if="!exitFlag" class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px]">
            <image-cropper 
                v-if="showImageCropper"
                :image="imageToCrop"
                @processedImage="processImage"
            />
            <AdminCard 
                @loadPhoto="loadImage"
                :adminPhoto = "adminPhoto"
                v-model:name="editedAdmin.admin_name"
                v-model:surname="editedAdmin.admin_surname"
                v-model:cashBalance="cashBalanceState"
                v-model:investBalance="investBalanceState"
                v-model:birthDate="editedAdmin.birthday"
                v-model:email="editedAdmin.email"
                v-model:phone="editedAdmin.phone"
                v-model:login="editedAdmin.admin_login"
                v-model:password="editedAdmin.admin_password"
                v-model:role="editedAdmin.role"
            />
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getBalanceState, updateAdminCard } from '@/services/apiRequests'
    import AdminCard from './AdminCard.vue'
    import ExitConfirmForm from './ui/ExitConfirmForm.vue'
    import ImageCropper from './ui/ImageCropper.vue'

    export default {
        name: 'admin-card',

        props: {
            admin: {
                type: Object,
                default: () => {}
            }
        },

        setup(props, { emit }) {
            const cashBalanceState = ref(0)
            const investBalanceState = ref(0)
            const bufferedAdmin = ref(Object.assign({}, props.admin))
            const editedAdmin = ref(Object.assign({}, props.admin))
            const exitFlag = ref(false)

            const showImageCropper = ref(false)
            const imageToCrop = ref({
                src: '',
                type: ''
            })
            const adminPhoto = ref(props.admin.photo)
            const edited = ref(false)

            const getMimeType = (file, fallback = null) => {
                const byteArray = (new Uint8Array(file)).subarray(0, 4);
                let header = '';
                for (let i = 0; i < byteArray.length; i++) {
                    header += byteArray[i].toString(16);
                }
                switch (header) {
                    case "89504e47":
                        return "image/png";
                    case "47494638":
                        return "image/gif";
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                        return "image/jpeg";
                    default:
                        return fallback;
                }
            }

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            const processImage = (image) => {
                edited.value = true
                adminPhoto.value = image
                showImageCropper.value = false
            }

            onMounted(async () => {
                // editedAdmin.value.birthday = editedAdmin.value.birthday.split('T')[0]
                // bufferedAdmin.value.birthday = bufferedAdmin.value.birthday.split('T')[0]
                const cashBalanceRes = await getBalanceState(props.admin.cash_balance_id)
                console.log(cashBalanceRes)
                cashBalanceState.value = cashBalanceRes
                investBalanceState.value = (await getBalanceState(props.admin.invest_balance_id))
            })

            const saveAdmin = async () => {
                editedAdmin.value.birthday = new Date(editedAdmin.value.birthday)
                await updateAdminCard(editedAdmin.value)
                exitFlag.value = false
                emit('close')
                emit('update-admins')
            }

            const closeWindows = () => {
                if (JSON.stringify(bufferedAdmin.value) !== JSON.stringify(editedAdmin.value)) {
                    exitFlag.value = true
                } else {
                    emit('close')
                }
            }
          
            return {
                cashBalanceState,
                investBalanceState,
                editedAdmin,
                closeWindows,
                exitFlag,
                saveAdmin,
                loadImage,
                showImageCropper,
                imageToCrop,
                adminPhoto,
                processImage
            }
        },

        components: {
            AdminCard,
            ExitConfirmForm,
            ImageCropper
        }
    }
</script>

<style lang="scss" scoped>

</style>
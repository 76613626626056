<template>
    <div @click="$emit('close')" style="background-color: rgba(29, 29, 29, .35)" class="backdrop-blur-[7px] fixed w-full z-40 h-full -mt-2"></div>
    <div v-if="loadingSpinnerFlag" class="z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#990D35] border-t-transparent absolute top-[50%] left-[50%] ml-[-180px] mt-[-50px]"></div>
    </div>
    <ImageViewer v-if="viewImageFlag" @close="viewImageFlag = false" @delete="deleteImage" :imageUrl="viewImageURL" altText="dddd" class="z-50"/>
    <div v-if="!viewImageFlag && !loadingSpinnerFlag" class="border-[1px] border-[#47484966] bg-[#2B2A29] rounded-[10px] z-50 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px]">
        <div class="px-[10px] py-[10px] w-[350px]">
            <div class="flex justify-center"><img src="../../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center text-[16px] text-white mb-[25px] mt-[10px]">
                Прийняти платіж
            </div>
            <div class="w-full">    
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    занести на сервісний рахунок
                </div>
                <select v-model="serviceBalanceLabel" class="mt-[3px] w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" name="to_balance">
                    <option value="Cashbox" class="bg-[#1D1D1D] text-[#fff]">Каса ({{ admin.admin_name + ' ' + admin.admin_surname }})</option>
                    <option value="Bank" class="bg-[#1D1D1D] text-[#fff]">Банк</option>
                </select>
            </div>

            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    виберіть учня
                </div>
                <input @focus="showStudentsFlag = true" v-model="studentName" class="mt-[3px] w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" type="text" name="student_balance">
                <div v-if="showStudentsFlag" class="scrollbar absolute h-[150px] overflow-y-auto">
                    <div v-for="student in queryStudents" @click="chooseStudent(student)" class="bg-[#1D1D1D] py-[2px] w-[330px] text-[#FFF] px-2 cursor-pointer">
                        {{ student.name + ' ' + student.surname }}
                    </div>
                </div>
            </div>

            <div v-if="currentStudent.name">
                <div class="text-white">активний до: <span :class="{'text-[#990D35]' : studentActiveToDate <= nowDate, 'text-green-emb' : studentActiveToDate > nowDate}">{{ studentActiveToDateText }}</span></div>
                <div class="text-white">аванс: <span :class="{'text-[#DFF726]' : currentStudent.prepaid_balance > 0, 'text-[#575555]' : currentStudent.prepaid_balance == 0}">{{ currentStudent.prepaid_balance.toFixed(2) }}</span></div>
                <!-- <div class="flex items-center">
                    <div class="text-white">врахувати аванс:</div>
                    <input v-model="prepaidUse" type="checkbox" class="ml-[5px] cursor-pointer">
                </div> -->
            </div>

            <div class="w-full mt-[20px]">    
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    тариф
                </div>
                <select :key="tariffKey" :value="currentTariffId" @change="e => chooseTariff(e)" class="mt-[3px] w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" name="to_balance">
                    <option v-for="tariff in allTariffs" :value="tariff.id" class="bg-[#1D1D1D] text-[#fff]">{{ tariff.name }}</option>
                </select>
            </div>

            <div v-if="currentTariff.name">
                <div class="text-white">вартість тарифу: <span class="text-green-emb">{{ currentTariff.price }}</span><span v-if="currentStudent.prepaid_balance > 0" class="text-[#990D35]"> (-{{ currentStudent.prepaid_balance.toFixed(2) }})</span><span class="text-green-emb" v-if="currentStudent.prepaid_balance > 0"> = <b>{{ currentTariff.price - currentStudent.prepaid_balance }}</b></span></div>
            </div>

            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    сума транзакції
                </div>
                <div class="flex justify-between mt-[3px]">
                    <input v-model="amount" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" type="number" name="amount">
                </div>
            </div>

            <div class="mt-[10px]">
                <div v-if="isLessMoney" class="text-[12px] text-[#990D35] text-center">
                    Не вистачає коштів для оплати тарифу ({{ amount - currentTariff.price + currentStudent.prepaid_balance }})
                </div>
                <div v-if="isFormedPrepaid" class="text-[12px] text-green-emb text-center">
                    Гравцю буде сформовано аванс ({{ amount - currentTariff.price + currentStudent.prepaid_balance }})
                </div>
            </div>

            <div class="w-full mt-[20px]">
                <div class="flex items-center justify-between text-[#fff]">
                    <div>
                        Додаткові файли:
                    </div>
                    <div class="flex justify-end">
                        <img v-for="image in allImages" @click="viewPhoto(image)" src="@/assets/icons/picture_icon.svg" class="mx-[2px] cursor-pointer">
                        <img src="@/assets/icons/upload_icon.svg" class="ml-2 cursor-pointer" @click="uploadPhoto">
                    </div>
                </div>
                <input ref="imagesInput" @change="selectPhoto($event)" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none hidden" type="file" name="additional_files">
            </div>
            <button @click="sendTransaction" class="w-full rounded-[6px] bg-green-emb text-white py-[7px] mt-[20px]">
                Прийняти
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getAllStudents, addRealIncomeRequest, addVirtualIncomeRequest, getServiceBalanceObjectByLabel, getAdmin, getTariffsByRoleId, addTariffIncome } from '@/services/apiRequests'
    import ImageViewer from '../ui/ImageViewer.vue'
    import { uuid } from 'vue3-uuid'

    async function readFileObjectAsDataURL (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                resolve(event.target.result)
            }
            reader.onerror = (error) => {
                reject(error)
            }
            reader.readAsDataURL(file)
        })
    }

    export default {
        name: 'get-payment-popup',

        emits: ['close', 'updateTransactions'],

        setup(props, { emit }) {
            const imagesInput = ref(null)
            const allImages = ref([])
            const admin = ref({})

            const viewImageObject = ref({})
            const viewImageURL = ref('')
            const viewImageFlag = ref(false)

            const amount = ref(null)
            const dateTx = ref(null)
            const serviceBalanceLabel = ref('Cashbox')
            const serviceBalanceObject = ref({})
            const studentBalanceState = ref(undefined)

            const loadingSpinnerFlag = ref(true)

            const showStudentsFlag = ref(false)
            const studentName = ref('')
            const queryStudents = ref([])
            
            const studentActiveToDate = ref(null)
            const studentActiveToDateText = ref('')
            const studentPrepaidState = ref(null)

            const allTariffs = ref([])
            const tariffKey = ref(uuid.v4())

            const currentTariff = ref({})
            const currentTariffId = ref('')

            const nowDate = ref(new Date())

            const isFormedPrepaid = ref(false)
            const isLessMoney = ref(false) 

            const prepaidUse = ref(false)

            watch(studentName, async (value) => {
                console.log(value)
                if (studentName.value.length > 3) {
                    queryStudents.value = (await getAllStudents(100, 0, null, value)).data
                    console.log(queryStudents.value)
                } else {
                    queryStudents.value = []
                }
            })

            const chooseTariff = (e) => {
                currentTariffId.value = e.target.value
                currentTariff.value = allTariffs.value.find((tariff) => {
                    return tariff.id == e.target.value
                })
            }

            const uploadPhoto = () => {
                imagesInput.value.click()
            }

            const selectPhoto = (event) => {
                allImages.value.push(event.target.files[0])
            }

            const viewPhoto = async (image) => {
                viewImageURL.value = await readFileObjectAsDataURL(image)
                viewImageObject.value = image
                viewImageFlag.value = true
            }

            const deleteImage = () => {
                allImages.value = allImages.value.filter((img) => {
                    return img !== viewImageObject.value
                })
                viewImageFlag.value = false
            }


            const currentStudent = ref({})

            const chooseStudent = async (student) => {
                studentName.value = student.name + ' ' + student.surname
                currentStudent.value = student

                studentActiveToDate.value = new Date(student.date_active_to)
                studentActiveToDateText.value = student.date_active_to.split('T')[0].split('-').reverse().join('.')
                studentPrepaidState.value = student.prepaid_balance

                const responseTariffs = await getTariffsByRoleId(student.tariff_role_id)
                allTariffs.value = responseTariffs
                tariffKey.value = uuid.v4()

                showStudentsFlag.value = false
            }

            watch(amount, (value) => {
                if (value - currentTariff.value.price + currentStudent.value.prepaid_balance < 0) {
                    isLessMoney.value = true
                } else {
                    isLessMoney.value = false
                }

                if (value - currentTariff.value.price + currentStudent.value.prepaid_balance > 0 ) {
                    isFormedPrepaid.value = true
                } else {
                    isFormedPrepaid.value = false
                }
            })

            const sendTransaction = async () => {
                if (!isLessMoney.value) {
                    loadingSpinnerFlag.value = true

                    if (serviceBalanceLabel.value == 'Cashbox') {
                        console.log('cashbox ------- ', {
                            amount: amount.value,
                            label: 'Поповнення балансу учня через касу ' + admin.value.admin_name + ' ' + admin.value.admin_surname,
                            receiver_id: admin.value.cash_balance_id,
                            student_id: currentStudent.value.student_id,
                            student_name: currentStudent.value.name + ' ' + currentStudent.value.surname,
                            new_prepaid_balance: currentStudent.value.prepaid_balance + amount.value - currentTariff.value.price,
                            new_prepaid: amount.value - currentTariff.value.price,
                            tariff_id: currentTariff.value.id,
                            tariff_name: currentTariff.value.name,
                            all_documents: allImages.value
                        })
                        console.log('prepaidUse ------- ', prepaidUse.value)
    
                        
    
                        const resTariffTx = await addTariffIncome(
                            amount.value,
                            'Поповнення балансу учня через касу ' + admin.value.admin_name + ' ' + admin.value.admin_surname,
                            admin.value.cash_balance_id,
                            currentStudent.value.student_id,
                            currentStudent.value.name + ' ' + currentStudent.value.surname,
                            currentStudent.value.prepaid_balance + amount.value - currentTariff.value.price,
                            amount.value - currentTariff.value.price,
                            currentTariff.value.id,
                            currentTariff.value.name,
                            allImages.value
                        )
                    } else {
                        const cashboxBalanceObject = (await getServiceBalanceObjectByLabel(serviceBalanceLabel.value)).balance
                        const resTariffTx = await addTariffIncome(
                            amount.value,
                            'Поповнення балансу учня через банк ' + admin.value.admin_name + ' ' + admin.value.admin_surname,
                            cashboxBalanceObject.balance_id,
                            currentStudent.value.student_id,
                            currentStudent.value.name + ' ' + currentStudent.value.surname,
                            currentStudent.value.prepaid_balance + amount.value - currentTariff.value.price,
                            amount.value - currentTariff.value.price,
                            currentTariff.value.id,
                            currentTariff.value.name,
                            allImages.value
                        )
                        // const resVirtualTx = await addVirtualIncomeRequest(studentBalanceId.value, amount.value, 'Поповнення балансу учня через ' + serviceBalanceLabel.value)
                        // const resRealTx = await addRealIncomeRequest(cashboxBalanceObject.balance_id, amount.value, 'Поповнення балансу учня через ' + serviceBalanceLabel.value, resVirtualTx.data.tx_id, allImages.value)
                    }
                    loadingSpinnerFlag.value = false
                    emit('close')
                    emit('updateTransactions')
                }
            } 

            onMounted(async () => {
                // const response = await getAllVirtualBalances()
                // allVirtualBalances.value = response.balances
                // showVirtualBalances.value = response.balances

                admin.value = (await getAdmin()).data
                loadingSpinnerFlag.value = false
            })

            return {
                loadingSpinnerFlag,
                viewImageFlag,
                viewImageURL,
                viewImageObject,
                deleteImage,
                viewPhoto,
                uploadPhoto,
                selectPhoto,
                imagesInput,
                allImages,
                amount,
                dateTx,
                serviceBalanceLabel,
                serviceBalanceObject,
                studentBalanceState,
                showStudentsFlag,
                studentName,
                queryStudents,
                chooseStudent,
                sendTransaction,
                currentStudent,
                admin,
                studentActiveToDate,
                studentPrepaidState,
                studentActiveToDateText,
                nowDate,
                allTariffs,
                tariffKey,
                chooseTariff,
                currentTariff,
                currentTariffId,
                isFormedPrepaid,
                isLessMoney,
                prepaidUse
            }
        },

        components: {
            ImageViewer
        }
    }
</script>

<style lang="scss" scoped>

.scrollbar::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
}

.scrollbar::-webkit-scrollbar-track {
    background: #ededed;        /* цвет зоны отслеживания */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #a6a4a4;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


</style>